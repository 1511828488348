import { map } from "lodash";
import { useCallback } from "react";

import { HasRefs, ID, Page, RichText } from "@api";

import { useEditInAppCommands } from "@state/app";
import {
  useLazyEntities,
  useLazyEntity,
  useQueueUpdates,
  useUpdateEntity,
} from "@state/generic";
import { useLazyPropertyDef } from "@state/properties";

import { ensureArray } from "@utils/array";
// import { useAutoLinkRelated } from "@state/pages/effects";
import { useShowMore } from "@utils/hooks";
import { Maybe, safeAs, when } from "@utils/maybe";
import { useGoTo, usePushTo } from "@utils/navigation";
import {
  asAppendMutation,
  asMutation,
  asUpdate,
} from "@utils/property-mutations";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { Centered, Container } from "@ui/container";
import { EditableHeading } from "@ui/editable-heading";
import { EntityHeaderBar } from "@ui/entity-header-bar";
import { SlidePaneManager } from "@ui/entity-pane-manager";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  ArrowUpRight,
  Emoji,
  Icon,
  iconFromString,
  RelationIcon,
} from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { AddMenuItem, ShowMoreMenuItem } from "@ui/menu-item";
import { OnHover } from "@ui/on-hover";
import { PageBody } from "@ui/page-body";
import { Main, PageLayout } from "@ui/page-layout";
import { PaneContainer, PaneHeader } from "@ui/pane-header";
import { PeopleStack } from "@ui/people-stack";
import { LabelledValue } from "@ui/property-value";
import { PropertyValueButton } from "@ui/property-value-button";
import { ResourceExtractor } from "@ui/resource-extractor";
import { GlobalEntitySelect, GlobalMultiEntitySelect } from "@ui/select";
import { EmojiSelect } from "@ui/select/emoji";
import { Sheet } from "@ui/sheet-layout";
import { TemplateBanner } from "@ui/template-banner";
import { WorkflowActions } from "@ui/workflow-action-button";

import { render, toEngine } from "..";
import { PaneOpts } from "../types";

import styles from "./styles.module.css";

export const PagePane = ({
  id,
  item: page,
  size,
  className,
}: PaneOpts<Page>) => {
  const mutate = useUpdateEntity(page.id, page.source.scope);
  const editInAppCommands = useEditInAppCommands();
  const ownerProp = useLazyPropertyDef(page.source, {
    field: "owner",
    type: "relation",
  });

  const handleBodyChanged = useCallback(
    (n: Maybe<RichText>) =>
      mutate(asMutation({ field: "body", type: "rich_text" }, n)),
    [mutate]
  );

  return (
    <Sheet size={size || "full"} className={className}>
      {!!page.template && <TemplateBanner />}

      <PageLayout>
        <Main>
          <VStack gap={0} fit="container">
            <Container fit="container">
              <EntityHeaderBar entity={page} />
            </Container>

            <OnHover.Trigger group="header">
              <Centered padding="horizontal">
                <VStack gap={20}>
                  <SpaceBetween direction="horizontal" align="stretch" gap={12}>
                    <Container
                      gap={6}
                      padding="none"
                      inset="bottom"
                      stack="vertical"
                    >
                      <Container
                        gap={0}
                        stack="horizontal"
                        inset="left"
                        padding="none"
                      >
                        {!page.icon && (
                          <OnHover.Target group="header">
                            <div>
                              <PropertyValueButton
                                entity={page}
                                field="icon"
                                type="text"
                              />
                            </div>
                          </OnHover.Target>
                        )}
                        <OnHover.Target group="header" show={!!page.status}>
                          <div>
                            <PropertyValueButton
                              entity={page}
                              field="status"
                              type="status"
                            />
                          </div>
                        </OnHover.Target>
                        {!page.owner && (
                          <OnHover.Target group="header">
                            <div>
                              <PropertyValueButton
                                entity={page}
                                field="owner"
                                type="relation"
                              />
                            </div>
                          </OnHover.Target>
                        )}
                      </Container>

                      <HStack align="baseline" gap={0}>
                        {!!page.icon && (
                          <EmojiSelect
                            emoji={page.icon || ""}
                            onChange={(i) =>
                              mutate(
                                asMutation({ field: "icon", type: "text" }, i)
                              )
                            }
                          >
                            <Button inset size="small" subtle>
                              <Icon
                                size="large"
                                icon={when(page.icon, iconFromString) || Emoji}
                              />
                            </Button>
                          </EmojiSelect>
                        )}
                        <EditableHeading
                          key={page.id}
                          text={page.title || ""}
                          placeholder="Untitled"
                          onChange={(text) => {
                            when(text, (i) =>
                              mutate(
                                asMutation({ field: "title", type: "text" }, i)
                              )
                            );
                          }}
                        />
                      </HStack>
                    </Container>

                    {page.owner && (
                      <HStack>
                        <LabelledValue label="Owner">
                          <Container
                            size="half"
                            padding="vertical"
                            onClick={() => editInAppCommands(ownerProp, page)}
                          >
                            <PeopleStack
                              people={when(page.owner, ensureArray) || []}
                              size="xlarge"
                            />
                          </Container>
                        </LabelledValue>
                      </HStack>
                    )}
                  </SpaceBetween>
                </VStack>
              </Centered>
            </OnHover.Trigger>

            <Centered
              key={page.id}
              stack="vertical"
              gap={30}
              className={styles.noPadTop}
            >
              <PageBody
                entity={page}
                body={page.body}
                onBodyChanged={handleBodyChanged}
                className={styles.editor}
                newLineSpace="xlarge"
              />
            </Centered>
          </VStack>
        </Main>
        <SlidePaneManager id={id} entity={page} />
      </PageLayout>
    </Sheet>
  );
};

export const PageLinksPane = ({ page }: { page: Page }) => {
  const pageId = usePageId();
  const goTo = useGoTo();
  const related = useLazyEntities(page.refs?.related || []);
  const mutate = useQueueUpdates(pageId);
  const relatedDef = useLazyPropertyDef(page.source, {
    field: "refs.related",
    type: "relations",
  });
  const { visible, showMore, hasMore, moreCount } = useShowMore(
    related || [],
    5
  );

  // TODO: Extract out related like from meetings to other entities
  // Move into generic effects
  // Automatically link work that is mentioned in the agendas....
  // useAutoLinkRelated(page, pageId);

  return (
    <Sheet size="secondary">
      <PaneHeader title="Related work" />

      <ResourceExtractor entityId={page.id} />

      <PaneContainer>
        <Menu>
          <MenuGroup>
            {map(visible, (item) =>
              render(toEngine(item)?.asMenuItem, {
                key: item.id,
                item: item,
                onChange: (cs) => mutate(asUpdate(item, cs)),
                onOpen: goTo,
                iconRight: ArrowUpRight,
              })
            )}

            {hasMore && (
              <ShowMoreMenuItem count={moreCount} onClick={showMore} />
            )}

            <GlobalMultiEntitySelect
              value={page.refs?.related}
              scope={page.source.scope}
              allowed={relatedDef?.options?.references}
              onChange={(r) =>
                mutate(
                  asUpdate(
                    page,
                    asMutation({ field: "refs.related", type: "relations" }, r)
                  )
                )
              }
              closeOnSelect={true}
              placeholder="Link work"
            >
              <AddMenuItem
                icon={RelationIcon}
                title="Link related work"
                subtle
              />
            </GlobalMultiEntitySelect>
          </MenuGroup>
        </Menu>
      </PaneContainer>
    </Sheet>
  );
};

export const RelatedPagesPane = ({ entityId }: { entityId: ID }) => {
  const pageId = usePageId();
  const pushTo = usePushTo();
  const entity = useLazyEntity(entityId);
  const pages = useLazyEntities(safeAs<HasRefs>(entity)?.refs?.pages || []);
  const mutate = useQueueUpdates(pageId);

  if (!pages?.length) {
    return <></>;
  }

  return (
    <Sheet size="secondary">
      <PaneHeader title="Pages" />

      <PaneContainer>
        <Menu>
          <MenuGroup>
            {map(pages, (item) =>
              render(toEngine(item)?.asMenuItem, {
                key: item.id,
                item: item,
                onChange: (cs) => mutate(asUpdate(item, cs)),
                onOpen: pushTo,
                iconRight: ArrowUpRight,
              })
            )}

            <GlobalEntitySelect
              value={undefined}
              scope={entity?.source.scope}
              allowed={["page"]}
              onChange={(r) =>
                entity &&
                !!r &&
                mutate(
                  asUpdate(
                    entity,
                    asAppendMutation(
                      { field: "refs.pages", type: "relations" },
                      [r]
                    )
                  )
                )
              }
              closeOnSelect={true}
              placeholder="Link page"
            >
              <AddMenuItem
                icon={RelationIcon}
                title="Link existing page"
                subtle
              />
            </GlobalEntitySelect>
          </MenuGroup>
        </Menu>
      </PaneContainer>
    </Sheet>
  );
};
