import { Schedule } from "@api";

import { useOpenAppCommands } from "@state/app";
import {
  useLazyEntity,
  useNestedSource,
  useUpdateEntity,
} from "@state/generic";
import { toSentence } from "@state/schedule";

import { when } from "@utils/maybe";
import { asMutation } from "@utils/property-mutations";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { CollapsibleSection } from "@ui/collapsible-section";
import { Centered, Container } from "@ui/container";
import { EditableHeading } from "@ui/editable-heading";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { KeySlash } from "@ui/icon";
import { LocationBreadcrumb } from "@ui/location-button";
import { PackageTag } from "@ui/package-label";
import { PropertyValueTile } from "@ui/property-value-tile";
import { Sheet } from "@ui/sheet-layout";
import { TemplateBanner } from "@ui/template-banner";
import { TextMedium } from "@ui/text";
import { WorkflowActions } from "@ui/workflow-action-button";

import { PaneOpts } from "../types";
import { ScheduleEditor } from "./editor";

import styles from "./styles.module.css";

export const SchedulePane = ({ id, item }: PaneOpts<Schedule>) => {
  const mutate = useUpdateEntity(id);
  const template = useLazyEntity(item.useTemplate?.id);

  const itemSource = useNestedSource(item, item.entity);

  return (
    <Sheet size="primary-thicc">
      {!!item.template && <TemplateBanner />}

      <Header item={item} />

      <Centered stack="vertical" gap={30}>
        <CollapsibleSection>
          <ScheduleEditor
            schedule={item}
            source={item.source}
            templateId={template?.id}
            showNext={false}
            showTemplate={true}
          />
        </CollapsibleSection>
      </Centered>
    </Sheet>
  );
};

interface HeaderProps {
  item: Schedule;
}

const Header = ({ item }: HeaderProps) => {
  const pageId = usePageId();
  const mutate = useUpdateEntity(item.id, pageId);

  const openCmdK = useOpenAppCommands(item);

  if (!item) {
    return <h1>Not found.</h1>;
  }

  return (
    <>
      <Centered className={styles.container}>
        <VStack gap={20}>
          <Container gap={20} padding="none" inset="bottom" stack="vertical">
            <Container
              padding="none"
              inset="left"
              stack="horizontal"
              fit="content"
            >
              <SpaceBetween>
                <LocationBreadcrumb location={item.location} />

                <HStack gap={2}>
                  <PackageTag type="schedule" scope={item.source.scope} />
                  <Button
                    size="small"
                    icon={KeySlash}
                    subtle
                    onClick={openCmdK}
                  >
                    Modify
                  </Button>
                </HStack>
              </SpaceBetween>
            </Container>

            <VStack gap={0}>
              <Container padding="none" inset="left">
                <PropertyValueTile
                  entity={item}
                  label={false}
                  field={"status"}
                  onChange={mutate}
                />
              </Container>
              <SpaceBetween>
                <VStack gap={0}>
                  <TextMedium subtle={!!item.name} bold>
                    {toSentence(item)}
                  </TextMedium>
                  <EditableHeading
                    key={`${item.id}-heading`}
                    size="h3"
                    text={item.name || ""}
                    placeholder="Untitled schedule"
                    autoFocus={false}
                    onChange={(text) => {
                      when(text, (i) => {
                        mutate(asMutation({ field: "name", type: "text" }, i));
                      });
                    }}
                  />
                </VStack>

                <WorkflowActions entity={item} />
              </SpaceBetween>
            </VStack>
          </Container>
        </VStack>
      </Centered>
    </>
  );
};
