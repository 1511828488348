import React, { CSSProperties, useMemo } from "react";

import { Status } from "@api";

import { cx } from "@utils/class-names";
import { toBaseColor } from "@utils/color";
import { fallback, Fn } from "@utils/fn";
import { switchEnum } from "@utils/logic";
import { Maybe, when } from "@utils/maybe";

import { CSSIcon } from "@ui/icon";

import styles from "./status-indicator.module.css";

interface Props {
  status: Maybe<Status>;
  blocked?: boolean;
  disabled?: boolean;
  className?: string;
  styles?: CSSProperties;
  onClick?: Fn<React.MouseEvent, void>;
}

export const StatusIndicator = ({
  status,
  blocked,
  styles: _styles,
  disabled = false,
  className,
  onClick,
}: Props) => {
  const modifier = useMemo(
    () =>
      fallback(
        () => (status?.blocked || blocked ? styles.error : undefined),
        () =>
          switchEnum(when(status?.color, toBaseColor) || "", {
            green: () => styles.success,
            red: () => styles.error,
            orange: () => styles.warning,
            yellow: () => styles.warning,
            blue: () => styles.info,
            gray: () => styles.info,
            else: () => undefined,
          }),
        () =>
          switchEnum(status?.group || "", {
            planning: () => styles.offline,
            done: () => styles.success,
            "in-progress": () => styles.info,
            "not-started": () => styles.info,
            else: () => undefined,
          })
      ),
    [status?.blocked, status?.color, status?.id, blocked]
  );
  return (
    <CSSIcon
      onClick={onClick}
      className={cx(
        className,
        styles.statusDot,
        modifier,
        disabled && styles.disabled
      )}
    />
  );
};
