import { isArray, isString, omit } from "lodash";

import { Entity, Json, JsonObject, Ref } from "@api";

import { asJSON } from "@utils/json";
import { newID } from "@utils/id";

// Extracts JSON from a markdown code block
const extractJsonMarkdown = (markdown: string): string => {
  const match = markdown.match(/```json\n([\s\S]+?)\n```/);
  return match ? match[1] : markdown;
};

export const parseJsonResponse = (response: string | Json): Json => {
  if (isString(response)) {
    return asJSON(extractJsonMarkdown(response));
  }
  return response;
};

export const asJsonObject = (json: Json): JsonObject => {
  if (isArray(json)) {
    return json[0] as JsonObject;
  }
  return json;
};

export const toLightModel = (work: Entity | Ref) =>
  omit(
    work,
    "__typename",
    "location",
    "workspace",
    "workspaceId",
    "refs",
    "teams",
    "subTeams",
    "inputs",
    "options",
    "stamps",
    "settings",
    "fetchedAt",
    "createdAt",
    "createdBy",
    "archivedAt",
    "archivedBy",
    "deletedAt",
    "deletedBy"
  );

// When AI is responding to us with JSON for our state, it uses local ids in the format "[t_0s2k0s20]" or "[fm_0s2k0s20]" where the first part of the id can be 1-3 of any char  and the second aprt can be 4 to 30 chars but sometimes ai will return words like [t_next_task]
// This function ensures that all local ids are unique by adding a suffix to them
export const ensureUniqueTempIDs = <T extends string | Json>(
  json: T,
  suffix: string = newID()
): T => {
  const asString = isString(json) ? json : JSON.stringify(json);
  const regex = /\[(\w{1,3})_([a-zA-Z0-9\_\-]{3,30})\]/g;

  const replaced = asString.replaceAll(
    regex,
    (match: string, p1: string, p2: string) => `[${p1}_${p2}_${suffix}]`
  );

  return (isString(json) ? replaced : JSON.parse(replaced)) as T;
};
