import { filter, find, map, reduce, some, uniqBy } from "lodash";

import { CreateOrUpdate, HasRefs, Ref, Task, Update } from "@api";

import { findAvailableStatuses } from "@state/properties";
import { getItem, isCreateOrUpdate } from "@state/store";
import { toTaskUpdate } from "@state/tasks";
import {
  WorkflowContext,
  WorkflowDefinition,
  WorkflowDefinitionConfig,
  WorkflowSuggestion,
} from "@state/workflows/types";

import { omitEmpty } from "@utils/array";
import { daysAgo } from "@utils/date";
import { now, usePointDate } from "@utils/date-fp";
import { ifDo } from "@utils/logic";
import { ensure, Maybe, safeAs, when } from "@utils/maybe";
import { mapAll } from "@utils/promise";
import { asMutation, asUpdate, toMutation } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { isCompleteStatus } from "@utils/status";

import { SlackColor } from "@ui/icon";
import { SlackCreateDialog } from "@ui/slack-create-dialog";

// Mark parent task as in progress when child task completed
export const updateParentTaskInProgress: WorkflowDefinition<Task> = {
  id: "updateParentTaskInProgress",
  trigger: "WILL_UPDATE",
  type: "task",
  allowed: ({ entity: task, update }, { stores }) => {
    const parent = when(task?.refs?.parent?.[0]?.id, (id) =>
      getItem(stores.task, id)
    );
    return (
      !!parent &&
      parent?.status?.group === "not-started" &&
      update.method === "update" &&
      !!find(
        update.changes,
        (c) => c.field === "status" && c.value.status?.group !== "not-started"
      )
    );
  },
  execute: ({ entity: task }, { stores, props }) => {
    const parent = ensure(
      when(task.refs?.parent?.[0]?.id, (id) =>
        getItem(stores.task, id)
      ) as Maybe<Task>,
      "Workflow executed with invalid condition."
    );

    const statuses = findAvailableStatuses(props, task.source);
    const inProgress = find(statuses, (s) => s.group === "in-progress");

    if (!inProgress) {
      return undefined;
    }

    return asUpdate(
      parent,
      omitEmpty([
        toMutation(task, { field: "status", type: "status" }, inProgress),
        ifDo(!parent.start, () =>
          toMutation(task, { field: "start", type: "date" }, now())
        ),
      ])
    );
  },
};

export const assignNonDraftWork: WorkflowSuggestion<Task> = {
  id: "assignNonDraftWork",
  trigger: "SUGGEST",
  type: "task",
  allowed: ({ entity: task, update }, { stores }) =>
    // When chganging status to a non-draft value
    isCreateOrUpdate(update) &&
    // Not for templates
    !task.template &&
    // Not for work being bulk created
    !update.transaction &&
    !task?.assigned &&
    some(
      update.changes,
      (c) => c.field === "status" && c.value.status?.group === "not-started"
    ),
  suggestion: {
    id: "unassigned-task",
    text: "This work is not assigned to anyone. Do you want to assign it now?",
    options: [
      { title: "Dismiss", id: "dismiss" },
      { title: "Assign", id: "assign" },
    ],
  },
  title: "Assign work",
  description: "Choose someone to assign this work to.",
  collect: [{ field: "assigned", type: "relation" }],
  execute: ({ entity: task, collected }, { stores }) => {
    return asUpdate(
      task,
      map(collected, (v) => asMutation(v, v.value[v.type]))
    );
  },
};

// A fucntion that returns whether an item is blocked based on it's blockedBy relations
// Call this function whenever:
// - An item's status changes
// - An item's blockedBy changes
// - An item's blocks changes

// Blocked workflow triggers
// - When an item's status changes, check if it blocks another item and update it's blocked field based on all of it's blockedBy items
// - When an item's blockedBy changes, check if it's blocked field should be updated
// - When an item's blocks changes, update all items that are blocked by this item looking at their blockedBy field

const isBlocked = (
  thing: Ref | Task,
  context: WorkflowContext<Task>,
  blockedBy?: (Ref | Task)[]
) => {
  const entity = (thing as Task)?.source
    ? (thing as Task)
    : getItem(context.stores.task, thing.id);

  if (!entity) {
    return false;
  }

  const statuses = findAvailableStatuses(context.props, entity.source);

  return reduce(
    blockedBy || entity.refs?.blockedBy || [],
    (blocked, t) =>
      blocked ||
      !when(
        find(statuses, {
          id:
            safeAs<Task>(t)?.status?.id ||
            getItem(context.stores.task, t.id)?.status?.id ||
            "never",
        }),
        isCompleteStatus
      ),
    false
  );
};

// When status changes, update blocked property of all upstream blocked items
export const updateRelatedBlockedOnStatusChanged: WorkflowDefinition<Task> = {
  id: "updateRelatedBlockedOnStatusChanged",
  trigger: "DID_UPDATE",
  type: "task",
  // When something is blocking other things and the status is changing
  allowed: ({ entity, update }) =>
    !!entity?.refs?.blocks?.length &&
    update.method === "update" &&
    some(update.changes, { field: "status" }),

  // Go through all the blocked items and update their blocked field
  execute: async ({ entity, update }, context) => {
    const blocks = await context.fetchItems(entity.refs?.blocks);

    const updates = await mapAll(blocks, async (blockedItem) => {
      if (!blockedItem) {
        return undefined;
      }

      // Since the store value of this item is not yet updated, we need to pass in the inflated
      // object into the isBlocked function to get the correct result
      const blockedBy = await context.fetchItems(
        safeAs<HasRefs>(blockedItem)?.refs?.blockedBy
      );

      const blocked = isBlocked(blockedItem, context, omitEmpty(blockedBy));

      return asUpdate<Task>(blockedItem, [
        asMutation({ field: "blocked", type: "boolean" }, blocked),
      ]);
    });

    return omitEmpty(updates);
  },
};

// When an item's blockedBy field changes, update it's blocked field
export const updateBlockedOnBlockedByChange: WorkflowDefinition<Task> = {
  id: "updateBlockedOnBlockedByChange",
  trigger: "DID_UPDATE",
  type: "task",
  allowed: ({ update }) =>
    update.method === "update" &&
    some(update.changes, { field: "refs.blockedBy" }),

  execute: async ({ entity, update }, context) => {
    const blockedBy = await context.fetchItems(
      find((update as CreateOrUpdate<Task>).changes, {
        field: "refs.blockedBy",
      })?.value.relations
    );
    const blocked = isBlocked(entity, context, omitEmpty(blockedBy));

    // Blocked hasn't changed, so don't update
    if (blocked === entity.blocked) {
      return undefined;
    }

    return asUpdate(entity, [
      asMutation({ field: "blocked", type: "boolean" }, blocked),
    ]);
  },
};

// When an item's blocks field changes, update all items that are blocked by this item
export const updateRelatedBlockedOnBlocksChanged: WorkflowDefinition<Task> = {
  id: "updateRelatedBlockedOnBlocksChanged",
  trigger: "DID_UPDATE",
  type: "task",
  allowed: ({ update }) =>
    update.method === "update" &&
    some(update.changes, { field: "refs.blocks" }),

  execute: async ({ entity, update }, context) => {
    const change = find((update as CreateOrUpdate<Task>).changes, {
      field: "refs.blocks",
    });
    const allAffected = uniqBy(
      [...(change?.value?.relations || []), ...(change?.prev?.relations || [])],
      (r) => r.id
    );

    const toCheck = await context.fetchItems(allAffected);

    const updates = await mapAll(toCheck, async (blockedItem) => {
      if (!blockedItem) {
        // Not in store, so we can't accurately determine blocked
        return undefined;
      }

      const isRemoving =
        // It's a remove op and the item is in the blockedBy list
        (change?.op === "remove" &&
          some(change?.value?.relations, { id: blockedItem.id })) ||
        // It's a set op and the item is not in the blockedBy list
        ((change?.op ?? "set") === "set" &&
          !some(change?.value?.relations, { id: blockedItem.id }));

      const newBlockedBy = isRemoving
        ? filter(
            safeAs<HasRefs>(blockedItem)?.refs?.blockedBy,
            (b) => b.id !== entity.id
          )
        : [
            ...(safeAs<HasRefs>(blockedItem)?.refs?.blockedBy || []),
            toRef(entity),
          ];

      const blockedBy = await context.fetchItems(newBlockedBy);

      const blocked = isBlocked(blockedItem, context, omitEmpty(blockedBy));

      // Nothing changed, don't update.
      if (blocked === safeAs<Task>(blockedItem)?.blocked) {
        return undefined;
      }

      return asUpdate(blockedItem, [
        asMutation({ field: "blocked", type: "boolean" }, blocked),
      ]) as Update<Task>;
    });

    return omitEmpty(updates);
  },
};

// Assign task to somewone
export const assignTask: WorkflowDefinition<Task> = {
  id: "assign-task",
  trigger: "ACTION",
  type: "task",
  icon: undefined,
  title: "Assign owner",

  allowed: ({ entity: task }, _context) => !task?.assigned,
  collect: [{ field: "assigned", type: "relation" }],
  execute: ({ entity: task, collected }, {}) =>
    when(task, (t) => [
      toTaskUpdate(
        t,
        map(collected, (c) => asMutation(c, c.value[c.type]))
      ),
    ]),
};

// Close task
export const closeTask: WorkflowDefinition<Task> = {
  id: "closeTask",
  trigger: "ACTION",
  type: "task",
  icon: undefined,
  title: "Close task",

  allowed: ({ entity: task }, _context) =>
    !!task?.createdAt && usePointDate(task?.updatedAt, (d) => daysAgo(d) > 30),
  execute: ({ entity: task }, { props }) => {
    const statuses = findAvailableStatuses(props, task.source);
    const closed =
      find(statuses, (s) => s.name === "Closed") ||
      find(statuses, (s) => s.group === "done");

    return [
      toTaskUpdate(
        task,
        omitEmpty([asMutation({ field: "status", type: "status" }, closed)])
      ),
    ];
  },
};

// Not currently used – nov 20
export const startDiscussion: WorkflowDefinition<Task> = {
  id: "startDiscussion",
  trigger: "ACTION",
  type: "task",
  icon: SlackColor,
  title: "Start a discussion",

  allowed: ({ entity: task }, _context) =>
    task?.status?.group === "in-progress" &&
    !find(task?.links, (l) => l.url?.includes("slack")),
  collect: ({ data: { entity: task }, onCollected, onCancelled }) =>
    task && (
      <SlackCreateDialog
        entity={task}
        onCancel={onCancelled}
        onSaved={(link) => onCollected?.([])}
      />
    ),
  execute: ({ entity: task, collected }, {}) =>
    when(task, (t) => [
      toTaskUpdate(
        t,
        map(collected, (c) => asMutation(c, c.value[c.type]))
      ),
    ]),
};

export const definitions: WorkflowDefinitionConfig<Task> = {
  triggers: [
    updateParentTaskInProgress,

    // Blocked workflow logic
    updateRelatedBlockedOnStatusChanged,
    updateBlockedOnBlockedByChange,
    updateRelatedBlockedOnBlocksChanged,
  ],
  suggestions: [assignNonDraftWork],
  actions: [assignTask, closeTask],
};

export default definitions;
