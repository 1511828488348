import { Entity, hasRefs, ID } from "@api";

import { useNestedSource } from "@state/generic";
import { SystemPackages, useHasPackages } from "@state/packages";
import { TemplateInstancesPane } from "@state/templates";

import { when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";

import { TemplateFormsPane } from "@ui/engine/form";
import { RelatedProcessesPane } from "@ui/engine/process";
import {
  ScheduleInstancesPane,
  TemplateSchedulesPane,
} from "@ui/engine/schedule";
import { ClockHistory, EmojiIcon, Process, Search } from "@ui/icon";
import { PaneItem, PaneManager } from "@ui/pane-manager";
import { ResourcesPane } from "@ui/resources-pane";
import { SearchPane } from "@ui/search-pane";
import ShadowPadding from "@ui/shadow-padding";

export const TemplatePaneManager = ({
  id,
  entity,
}: {
  id: ID;
  entity: Entity;
}) => {
  const pushTo = usePushTo();
  const childSource = useNestedSource(entity);

  const installed = useHasPackages(id, [
    SystemPackages.Processes,
    SystemPackages.Meetings,
  ]);

  return (
    <PaneManager size="secondary">
      <PaneItem id="search" title="Search" icon={Search}>
        <SearchPane parentId={id} onOpen={pushTo} />
      </PaneItem>

      {childSource && (
        <PaneItem
          id="resources"
          title="Attachments"
          icon={<EmojiIcon emoji="🔗" />}
        >
          <ResourcesPane location={childSource.scope} />
        </PaneItem>
      )}

      {installed[SystemPackages.Processes] && (
        <PaneItem id="processes" title="Processes" icon={Process}>
          <RelatedProcessesPane entityId={id} />
        </PaneItem>
      )}

      {hasRefs(entity) && (
        <PaneItem id="schedule" title="Schedule" icon={ClockHistory}>
          <TemplateSchedulesPane template={entity} />
        </PaneItem>
      )}

      {hasRefs(entity) && (
        <PaneItem id="schedule" title="Schedule" icon={ClockHistory}>
          <TemplateInstancesPane template={entity} instanceId={id} />
        </PaneItem>
      )}

      {/* {hasRefs(entity) &&
        when(entity?.refs?.schedules?.[0], (ref) => (
          <PaneItem id="schedule" title="Schedule" icon={ClockHistory}>
            <ScheduleInstancesPane schedule={ref} instanceId={id} />
          </PaneItem>
        ))} */}

      {hasRefs(entity) && (
        <PaneItem id="forms" title="Forms" icon={ClockHistory}>
          <TemplateFormsPane template={entity} />
        </PaneItem>
      )}
    </PaneManager>
  );
};
