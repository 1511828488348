import { first, map } from "lodash";
import { FC, memo, ReactNode, useCallback, useEffect, useState } from "react";

import {
  DatabaseID,
  Entity,
  EntityType,
  HasBlocked,
  HasTemplate,
  PropertyRef,
  PropertyType,
  PropertyValue as PropertyValueType,
  PropertyValueRef,
  Roadmap,
  View,
} from "@api";

import { useQueueUpdates } from "@state/generic";
import {
  useGetAllPropertyValues,
  useInflatedPropertyValue,
} from "@state/properties";

import { ensureArray, ensureMany } from "@utils/array";
import { cx } from "@utils/class-names";
import { fromISO, toCalDate, toPointDate } from "@utils/date-fp";
import { debug } from "@utils/debug";
import { withHardHandle } from "@utils/event";
import { composel, Fn } from "@utils/fn";
import { toPlainText } from "@utils/html";
import { Maybe, safeAs, when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { asMutation, asUpdate } from "@utils/property-mutations";
import {
  asValue,
  isEmptyValue,
  isReferencing,
  toLabel,
  toPlaceholder,
} from "@utils/property-refs";
import { toHtml } from "@utils/rich-text";
import { plural } from "@utils/string";
import { isStar } from "@utils/wildcards";

import { usePageId } from "@ui/app-page";
import { Button, Props as ButtonProps } from "@ui/button";
import { Container } from "@ui/container";
import { DateInputPicker } from "@ui/date-picker";
import { ScheduleButton, ScheduleEditorPopover } from "@ui/engine/schedule";
import { SpaceBetween, VStack } from "@ui/flex";
import { ArrowUpRight, CheckIcon, Icon } from "@ui/icon";
import { TextInput } from "@ui/input";
import { Label } from "@ui/label";
import { CheckMenuItem } from "@ui/menu-item";
import { OnHover } from "@ui/on-hover";
import { PropertyRefLabel } from "@ui/property-def-label";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { RelationIcon, RelationLabel } from "@ui/relation-label";
import { DocumentEditor } from "@ui/rich-text";
import {
  GlobalEntitySelect,
  GlobalMultiEntitySelect,
  LocationSelect,
  Option,
  PersonMultiSelect,
  PersonSelect,
  SelectProps,
  StatusSelect,
  TagMultiSelect,
  TagSelect,
} from "@ui/select";
import { EmojiSelect } from "@ui/select/emoji";
import { ManageValuesFooter } from "@ui/select/footers";
import { ScopedPropertiesSelect } from "@ui/select/properties";
import { ScopedPropertySelect } from "@ui/select/property";
import { StatusIcon } from "@ui/status-button";
import { StatusTag, Tag, Tags } from "@ui/tag";
import { Text, TextSmall } from "@ui/text";
import { Tooltip } from "@ui/tooltip";

import { LocationButton } from "./location-button";
import { LocationDialog } from "./location-dialog";
import { TeamButton, TeamSelect } from "./team-select";

import styles from "./property-value.module.css";

export type PropertyValueVariant =
  | "icon-only"
  | "no-icon"
  | "unlabelled"
  | "labelled"
  | "inline"; // Don't show too much information, e.g. table cell

export interface Props {
  valueRef: PropertyValueRef<Entity>;
  source: DatabaseID;
  parent?: Entity;
  children?: ReactNode;
  size?: ButtonProps["size"];
  fit?: ButtonProps["fit"];
  position?: SelectProps<Option>["position"];
  onChange?: Fn<PropertyValueRef<Entity>["value"], void>;
  variant?: PropertyValueVariant;
  propTypeIcon?: boolean;
  inset?: boolean;
  className?: string;
  editing?: boolean;
  editable?: boolean;
  onClick?: Fn<React.MouseEvent, void>;
}

const isSortableProp = <T extends Entity>(p: Maybe<PropertyValueRef<T>>) =>
  p?.field === "sort" || p?.field === "fields";

const isTeamRelation = <T extends Entity>(p: Maybe<PropertyValueRef<T>>) =>
  p?.def?.options?.references === "team" ||
  ["team"].includes(p?.field as string) ||
  (p?.value.relation?.id || p?.value.relations?.[0]?.id)?.startsWith?.("tm_");

const isPersonRelation = <T extends Entity>(p: Maybe<PropertyValueRef<T>>) =>
  isReferencing(p?.def || p || {}, "person") ||
  ["person", "assigned", "owner"].includes(p?.field as string) ||
  (p?.value.relation?.id || p?.value.relations?.[0]?.id)?.startsWith?.("u_");

function ReadControl({
  valueRef,
  source,
  parent,
  variant,
  propTypeIcon,
  editable,
  size = "small",
  fit,
  inset,
  onClick,
  className,
}: Props) {
  const icon = propTypeIcon ? (
    <PropertyTypeIcon field={valueRef.field as string} type={valueRef.type} />
  ) : undefined;
  const { type, value } = valueRef;
  const goTo = useGoTo();

  // Location field is just stored as a text field but has a special picker
  if (valueRef.field === "location") {
    return (
      <LocationButton
        inset={true}
        fit={fit}
        variant={variant === "icon-only" ? "compact" : "default"}
        showCaret={false}
        location={valueRef.value.text}
        onClick={onClick}
      />
    );
  }

  const withWrapper = (
    children: ReactNode,
    overrides?: Partial<ButtonProps>
  ) => (
    <Button
      subtle
      fit={fit}
      className={cx(
        (overrides?.size || size) === "tiny" && styles.noPadding,
        !editable && styles.disabled,
        overrides?.className,
        className
      )}
      inset={overrides?.inset || inset}
      size={overrides?.size || size}
      onClick={onClick}
      icon={overrides?.icon || icon}
    >
      {children}
    </Button>
  );

  if (!isTeamRelation(valueRef) && isEmptyValue(valueRef.value[type])) {
    // Empty property value
    return withWrapper(
      <Text className={styles.placeholder}>
        {toPlaceholder(valueRef, variant === "labelled", editable)}
      </Text>
    );
  }

  if (
    valueRef?.def?.options?.references === "schedule" ||
    valueRef?.field === "refs.repeat"
  ) {
    if (!!parent) {
      return (
        <ScheduleButton
          schedule={valueRef.value?.relation}
          parent={parent}
          subtle
          className={cx(!editable && styles.disabled, className)}
          inset={inset}
          size={size}
          onClick={onClick}
          icon={icon}
        />
      );
    } else {
      debug("Missing parent for schedule property value.");
    }
  }

  switch (type) {
    case "number":
      return withWrapper(toLabel(valueRef));

    case "text":
    case "email":
    case "url":
    case "title":
    case "phone":
      return withWrapper(value[type] || "None");

    case "rich_text":
      return withWrapper(toPlainText(toHtml(value.rich_text)) || "None");

    case "date":
      return withWrapper(
        <Label
          icon={
            variant !== "labelled" && variant !== "no-icon" ? (
              <PropertyTypeIcon
                field={valueRef.field as string}
                type={valueRef.type}
              />
            ) : undefined
          }
          text={toLabel(valueRef)}
        />
      );

    case "boolean":
      return withWrapper(
        <CheckMenuItem
          checked={value.boolean || false}
          text={value.boolean ? "Yes" : "No"}
        />,
        { size: "tiny" }
      );

    case "status":
      return withWrapper(
        variant !== "icon-only" && (
          <StatusTag
            status={value?.[type]}
            blocked={(parent as HasBlocked)?.blocked}
          >
            {value?.[type]?.name}
          </StatusTag>
        ),
        {
          icon:
            variant === "icon-only" ? (
              <StatusIcon
                status={value[type]}
                blocked={(parent as HasBlocked)?.blocked}
              />
            ) : undefined,
        }
      );

    case "select":
      return withWrapper(
        <Tag color={value?.[type]?.color}>{value?.[type]?.name}</Tag>
      );

    case "multi_select":
      return withWrapper(<Tags tags={value[type] || []} />);

    // case "links":
    //   return withWrapper(<LinkStack links={value[type]} editable={false} />);

    case "relation": {
      if (isTeamRelation(valueRef)) {
        return (
          <TeamButton
            className={cx(
              styles.value,
              !editable && styles.disabled,
              className
            )}
            subtle
            inset={inset}
            size={size}
            onClick={onClick}
            variant={variant === "icon-only" ? "icon-only" : "secondary"}
            caret={variant !== "icon-only"}
            icon={icon}
            team={valueRef?.value?.relation}
          />
        );
      }
    }

    case "relations":
    case "relation": {
      const relations = ensureArray(value[type]);

      return withWrapper(
        <Container
          gap={6}
          padding="none"
          stack={variant === "unlabelled" ? "horizontal" : "vertical"}
        >
          <OnHover.Trigger>
            {variant === "inline" && (
              <Tooltip
                text={
                  <VStack>
                    {map(relations, (r) => (
                      <RelationLabel
                        key={r.id}
                        relation={r}
                        className={styles.light}
                      />
                    ))}
                  </VStack>
                }
              >
                {relations?.length === 1 ? (
                  <RelationLabel relation={relations[0]} />
                ) : (
                  <Text subtle={!relations?.length}>
                    {relations?.length}{" "}
                    {plural(
                      valueRef?.def?.label?.toLocaleLowerCase() || "item",
                      relations.length
                    )}
                  </Text>
                )}
              </Tooltip>
            )}
            {variant !== "inline" &&
              map(relations, (ref) => (
                <SpaceBetween key={ref.id}>
                  {variant === "icon-only" ||
                  (variant !== "labelled" &&
                    relations?.length > 1 &&
                    valueRef?.def?.options?.references === "person") ? (
                    <Icon icon={<RelationIcon relation={ref} />} />
                  ) : (
                    <RelationLabel relation={ref} />
                  )}
                  {variant === "labelled" && (
                    <OnHover.Target opacity="partial">
                      <Button
                        size="tiny"
                        icon={ArrowUpRight}
                        onClick={withHardHandle(() => goTo(ref))}
                      />
                    </OnHover.Target>
                  )}
                </SpaceBetween>
              ))}
          </OnHover.Trigger>
        </Container>
      );
    }

    case "property":
    case "properties": {
      const v = ensureArray(value[type]);
      const childType = ((parent as Maybe<View>)?.entity ||
        (parent as Maybe<Roadmap>)?.settings?.child_type ||
        source.type) as EntityType;
      const propSource = { type: childType, scope: source.scope };

      return withWrapper(
        <Container gap={6} padding="none" stack={"horizontal"}>
          {v.length > 1 && (
            <>
              <PropertyRefLabel prop={v[0]} source={propSource} />
              <Label>+ {v.length - 1} other fields</Label>
            </>
          )}
          {v.length === 1 && (
            <PropertyRefLabel prop={v[0]} source={propSource} />
          )}
          {!v.length && <Text subtle>No fields.</Text>}
        </Container>
      );
    }

    case "link":
    case "links": {
      const v = ensureArray(value[type]);
      return withWrapper(
        <Container gap={6} padding="none" stack={"horizontal"}>
          {v.length > 1 && (
            <>
              <Label>{v[0]?.text || v[0]?.url}</Label>
              <Label>+ {v.length - 1} other fields</Label>
            </>
          )}
          {v.length === 1 && <Label>{v[0]?.text || v[0]?.url}</Label>}
          {!v.length && <Text subtle>No links.</Text>}
        </Container>
      );
    }

    default:
      return withWrapper("System value.");
  }
}

const WriteControl = ({
  valueRef,
  parent,
  source,
  variant,
  onChange: _onChange,
  onClose,
  className,
  position,
  children,
}: Props & { onClose: Fn<void, void> }) => {
  const pageId = usePageId();
  const { values: options, fetch: fetchOptions } = useGetAllPropertyValues(
    source,
    valueRef
  );
  const queue = useQueueUpdates(pageId);
  // If no onChange is provided but it's editable, we will queue the update ourselves
  const onChange = useCallback(
    _onChange ||
      ((v: PropertyValueRef["value"]) => {
        if (!parent || !source) {
          return;
        }

        queue(
          asUpdate<Entity>(
            { id: parent.id, source: source } as Pick<Entity, "id" | "source">,
            asMutation(valueRef as PropertyRef, v[valueRef.type])
          )
        );
      }),
    [queue, parent?.id, source, _onChange]
  );

  const { type, value } = valueRef;
  const asPropValue = useCallback(
    <T extends PropertyType>(v: PropertyValueType[T]) => asValue(type, v),
    [type]
  );

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  // Location field is just stored as a text field but has a special picker
  if (valueRef.field === "location") {
    return parent ? (
      <LocationDialog
        suggested={valueRef.value.text}
        targets={[{ id: parent.id }]}
        onComplete={onClose}
        onCancel={onClose}
      />
    ) : (
      <LocationSelect
        location={valueRef.value.text}
        className={className}
        open={true}
        inset={true}
        position={position}
        variant={variant === "icon-only" ? "compact" : "default"}
        showCaret={variant !== "icon-only"}
        setOpen={(c) => c === false && onClose?.()}
        onChange={(v) => {
          onChange?.(asPropValue(v));
          onClose?.();
        }}
        children={children}
      />
    );
  }

  // Schedule picker
  if (valueRef.field === "refs.repeat" && !!parent) {
    return (
      <ScheduleEditorPopover
        schedule={valueRef.value.relation}
        parentId={parent.id}
        source={source}
        position={position}
        className={className}
        portal={true}
        open={true}
        setOpen={(c) => c === false && onClose?.()}
        onChanged={(v) => {
          onChange?.(asPropValue(v));
          onClose?.();
        }}
        children={children}
      />
    );
  }

  if (valueRef.field === "icon") {
    return (
      <EmojiSelect
        className={className}
        children={children}
        emoji={valueRef.value.text || ""}
        onChange={(v) => {
          onChange?.(asPropValue(v));
          onClose?.();
        }}
      />
    );
  }

  switch (type) {
    case "text":
    case "email":
    case "url":
    case "title":
    case "phone":
      return (
        <TextInput
          autoFocus
          value={value[type] || ""}
          className={className}
          onChange={(v) => {
            onChange?.(asPropValue(v));
            onClose?.();
          }}
        />
      );

    case "rich_text":
      // TODO: Needs cleaning up
      return (
        <DocumentEditor
          editable={true}
          className={className}
          content={value.rich_text}
          onChanged={(v) => onChange?.(asPropValue(v))}
        />
      );

    case "select":
      return (
        <TagSelect
          value={value[type]}
          portal={true}
          options={options[type] || []}
          position={position}
          className={className}
          createable={valueRef.def?.locked !== true}
          footer={
            <ManageValuesFooter {...source} prop={valueRef as PropertyRef} />
          }
          onBlur={onClose}
          open={true}
          setOpen={() => onClose()}
          onChange={(v) => {
            onChange?.(asPropValue(v));
            onClose?.();
          }}
          children={children}
        />
      );

    case "status":
      return (
        <StatusSelect
          value={value[type]}
          portal={true}
          options={options[type] || []}
          className={className}
          onChange={(v) => {
            onChange?.(asPropValue(v));
            onClose?.();
          }}
          footer={
            <ManageValuesFooter {...source} prop={valueRef as PropertyRef} />
          }
          position={position}
          onBlur={onClose}
          open={true}
          setOpen={() => onClose()}
          children={children}
        />
      );

    case "multi_select":
      return (
        <TagMultiSelect
          open={true}
          setOpen={() => onClose()}
          portal={true}
          position={position}
          className={className}
          value={value[type]}
          footer={
            <ManageValuesFooter {...source} prop={valueRef as PropertyRef} />
          }
          createable={valueRef.def?.locked !== true}
          options={options[type] || []}
          onChange={(vs) => onChange?.(asPropValue(vs))}
          onBlur={onClose}
          children={children}
        />
      );

    case "relation": {
      const v = value[type];

      if (isTeamRelation(valueRef)) {
        return (
          <TeamSelect
            open={true}
            setOpen={() => onClose()}
            portal={true}
            className={className}
            position={position}
            team={v}
            onChanged={(u) => onChange?.(asPropValue(u))}
            onBlur={onClose}
            children={children}
          />
        );
      }
      if (isPersonRelation(valueRef)) {
        return (
          <PersonSelect
            open={true}
            setOpen={() => onClose()}
            portal={true}
            position={position}
            className={className}
            value={v}
            onChange={(u) => onChange?.(asPropValue(u))}
            onBlur={onClose}
            children={children}
          />
        );
      }

      const references = ensureMany(
        when(valueRef.def?.options?.references, (r) =>
          isStar(r) ? "task" : r
        ) || "task"
      );

      return (
        <GlobalEntitySelect
          open={true}
          setOpen={(o) => !o && onClose()}
          portal={true}
          position={position}
          value={v}
          showTeam={references?.includes("team")}
          className={className}
          showOtherTeams={true}
          type={first(references)}
          scope={source.scope}
          allowed={ensureMany(references)}
          onChange={(u) => onChange?.(asPropValue(u))}
          children={children}
        />
      );
    }

    case "relations": {
      const v = ensureArray(value[type]);

      if (isPersonRelation(valueRef)) {
        return (
          <PersonMultiSelect
            open={true}
            setOpen={() => onClose()}
            portal={true}
            className={className}
            position={position}
            value={v}
            onChange={(rs) => onChange?.(asPropValue(rs))}
            children={children}
          />
        );
      }

      const references = (valueRef.def?.options?.references ||
        "task") as EntityType;

      return (
        <GlobalMultiEntitySelect
          open={true}
          setOpen={(o) => !o && onClose()}
          value={v}
          portal={true}
          position={position}
          type={references}
          className={className}
          scope={source.scope}
          // TODO: This needs to allow referencing both templates and non-templates
          // so that it works for things like ".blockedBy" as well as ".calendars" (adding template to a real calendar)
          templates={
            !!safeAs<HasTemplate>(parent)?.template &&
            references === safeAs<HasTemplate>(parent)?.source.type
          }
          allowed={[references]}
          showOtherTeams={true}
          onChange={(u) => onChange?.(asPropValue(u))}
          children={children}
        />
      );
    }

    case "properties": {
      const v = value[type];
      const childType = ((parent as Maybe<View>)?.entity ||
        (parent as Maybe<Roadmap>)?.settings?.child_type ||
        source.type) as EntityType;
      return (
        <ScopedPropertiesSelect
          open={true}
          setOpen={() => onClose()}
          value={v}
          source={{ type: childType, scope: source.scope }}
          className={className}
          portal={true}
          position={position}
          blacklist={when(valueRef.field, composel(String, ensureMany))}
          closeOnSelect={false}
          onChanged={(vs) =>
            onChange?.(
              asPropValue(
                isSortableProp(valueRef)
                  ? map(vs, (i) => ({
                      ...i,
                      direction: i.direction || "asc",
                    }))
                  : vs
              )
            )
          }
          onBlur={onClose}
          children={children}
        />
      );
    }

    case "property": {
      const v = value[type];
      const childType = ((parent as Maybe<View>)?.entity ||
        (parent as Maybe<Roadmap>)?.settings?.child_type ||
        source.type) as EntityType;

      return (
        <ScopedPropertySelect
          open={true}
          setOpen={() => onClose()}
          value={v}
          type={childType}
          scope={source.scope}
          portal={true}
          position={position}
          className={className}
          blacklist={when(valueRef.field, composel(String, ensureMany))}
          closeOnSelect={false}
          onChanged={(u) => onChange?.(asPropValue(u))}
          onBlur={onClose}
          children={children}
        />
      );
    }

    case "person": {
      // Only show/set one person for assigned
      throw new Error("Person not supported anymore.");
    }

    case "date":
      return (
        <DateInputPicker
          date={when(value[type], fromISO)}
          required={false}
          open={true}
          className={className}
          portal={true}
          onChanged={(d) =>
            onChange?.(
              !d
                ? asPropValue(undefined)
                : asPropValue(
                    valueRef.def?.options?.mode === "point"
                      ? toPointDate(d)
                      : toCalDate(d, "local")
                  )
            )
          }
          onClose={onClose}
          children={children}
        />
      );

    case "number": {
      return (
        <TextInput
          autoFocus
          value={
            String(
              valueRef.def?.format === "percent"
                ? Number(value[type] || 0) * 100
                : Number(value[type])
            ) || ""
          }
          inputType={"number"}
          className={className}
          onChange={(v) => {
            onChange?.(
              v === ""
                ? asPropValue(undefined)
                : valueRef.def?.format === "percent"
                ? asPropValue(Number(v) / 100)
                : asPropValue(Number(v))
            );
            onClose?.();
          }}
        />
      );
    }

    case "boolean": {
      return (
        <Button
          size="small"
          subtle
          icon={<CheckIcon checked={value.boolean || false} />}
          inset={true}
          onClick={() => onChange?.(asPropValue(value.boolean ? false : true))}
          className={className}
        >
          {value.boolean ? "Yes" : "No"}
        </Button>
      );
    }

    default: {
      throw new Error("Unsupported property type.");
    }
  }
};

export function _PropertyValue({
  className: _className,
  children,
  onClick,
  editing: defaultEditing,
  editable = true,
  propTypeIcon = false,
  variant = "unlabelled",
  ...props
}: Props) {
  const [editing, _setEditing] = useState(defaultEditing ?? false);

  const setEditing = useCallback(
    (e: boolean) => {
      if (e === editing) {
        return;
      }

      // Toggle booleans on edit click
      if (props.valueRef.type === "boolean") {
        props.onChange?.(asValue("boolean", !props.valueRef.value.boolean));
      }

      _setEditing(e);
    },
    [editing]
  );

  const className = cx(
    styles.value,
    !props.inset && styles.clipped,
    _className
  );

  const valueRef = useInflatedPropertyValue(props.valueRef, props.source);

  const readControl = children ? (
    <div onClick={() => setEditing(true)}>{children}</div>
  ) : (
    <ReadControl
      {...props}
      propTypeIcon={propTypeIcon}
      editable={editable}
      variant={variant}
      valueRef={valueRef}
      onClick={onClick || (() => editable && setEditing(true))}
      className={className}
    />
  );

  return (
    <div className={styles.container}>
      {!editing && readControl}
      {editing && (
        <WriteControl
          {...props}
          className={className}
          valueRef={valueRef}
          variant={variant}
          onClose={() => setEditing(false)}
          onChange={props.onChange}
          children={readControl}
        />
      )}
    </div>
  );
}
export const PropertyValue = memo(
  _PropertyValue,
  (past, next) =>
    past.valueRef === next.valueRef &&
    past.editable === next.editable &&
    past.source?.scope === next.source?.scope
) as FC<Props>;

export const LabelledValue = ({
  children,
  label,
  ...props
}: {
  children: ReactNode;
  fit?: ButtonProps["fit"];
  label: string | ReactNode;
}) => (
  <VStack gap={0} fit={props.fit}>
    {children}
    <TextSmall subtle className={styles.noBreak}>
      {label}
    </TextSmall>
  </VStack>
);

export const LabelledPropertyValue = ({
  label,
  className,
  fit,
  ...props
}: Props & { label: string }) =>
  label ? (
    <LabelledValue label={label} fit={fit}>
      <PropertyValue
        {...props}
        className={cx(className, styles.fullWidth)}
        inset={true}
        variant="labelled"
      />
    </LabelledValue>
  ) : (
    <PropertyValue
      {...props}
      fit={fit}
      className={className}
      inset={true}
      variant="unlabelled"
    />
  );
