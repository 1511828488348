import { isString } from "lodash";
import { ReactNode } from "react";

import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { isDefined } from "@utils/maybe";

import { CardHeader } from "@ui/card-header";
import { HStack, SpaceBetween, VStack } from "@ui/flex";

import { Text, TextMedium, TextSmall } from "./text";

import styles from "./pane-header.module.css";

interface Props {
  title: string | ReactNode;
  description?: string | ReactNode;
  count?: number;
  onClick?: Fn<React.MouseEvent, void>;
  actions?: ReactNode;
  className?: string;
  children?: ReactNode;
}

export function PaneHeader({
  title,
  description,
  onClick,
  className,
  count,
  actions,
  children,
}: Props) {
  return (
    <CardHeader
      className={cx(styles.header, className)}
      toEdge={false}
      border={false}
    >
      <SpaceBetween>
        <VStack gap={0} fit="content">
          <HStack gap={6} align="baseline" onClick={onClick}>
            {isString(title) ? <TextMedium bold>{title}</TextMedium> : title}
            {isDefined(count) && (
              <TextMedium bold subtle>
                {count}
              </TextMedium>
            )}
          </HStack>
          {description &&
            (isString(description) ? (
              <TextSmall subtle>{description}</TextSmall>
            ) : (
              description
            ))}
        </VStack>
        {children || actions}
      </SpaceBetween>
    </CardHeader>
  );
}

export function PaneContainer({
  children,
  inset = true,
}: {
  inset?: boolean;
  children: ReactNode;
}) {
  return (
    <div className={cx(styles.container, inset && styles.inset)}>
      {children}
    </div>
  );
}
