import { filter, map } from "lodash";
import { Fragment, useCallback, useMemo } from "react";

import { ID, Status, Workflow, WorkflowStep } from "@api";

import { useLazyEntities, useLazyEntity } from "@state/generic";
import { useLazyPropertyValue } from "@state/properties";
import { isFinished, orderedSteps } from "@state/workflow";

import { when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";

import { Button } from "@ui/button";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { GoToButton } from "@ui/go-to-button";
import { Icon, WorkflowFilled } from "@ui/icon";
import { StatusIndicator } from "@ui/icon/status-indicator";
import { Label } from "@ui/label";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import ProgressBar from "@ui/progress-bar";
import { Tooltip } from "@ui/tooltip";

import { render, useEngine } from "..";
import { WorkflowStepIcon } from "../workflow_step/icon";

import styles from "./styles.module.css";

export const WorkflowProgressBar = ({
  workflow,
  status,
  className,
}: {
  workflow: Workflow;
  status?: Status;
  className?: string;
}) => {
  const percent = useMemo(() => {
    if (status?.group === "done") {
      return 100;
    }

    const finished = workflow?.refs?.finished?.length;
    const total = workflow?.refs?.steps?.length;

    if (!total || !finished) {
      return 0;
    }

    return Math.round((finished / total) * 100);
  }, [workflow, status?.group, workflow?.refs?.finished]);

  return (
    <ProgressBar
      className={className}
      thickness={2}
      percent={percent}
      color="green"
    />
  );
};

export const WorkflowStepsBar = ({
  workflow,
  status,
}: {
  workflow: Workflow;
  status?: Status;
}) => {
  const pushTo = usePushTo();
  const finished = isFinished(workflow);
  const steps = useLazyEntities<"workflow_step">(workflow?.refs?.steps);
  const ordered = useMemo(
    () =>
      orderedSteps(
        finished ? filter(steps, (s) => s?.status?.id === "FNS") : steps || []
      ),
    [steps, isFinished]
  );
  const handleStepClicked = useCallback(
    (e: React.MouseEvent, step: WorkflowStep) =>
      when(step.refs?.created?.[0], (r) => {
        e.preventDefault();
        pushTo(r);
        return false;
      }),
    [pushTo]
  );

  return (
    <HStack fit="container" gap={4} className={styles.stepsBar}>
      {/* Make the start always a little green for the feels */}
      <div style={{ width: "40px" }}>
        <ProgressBar thickness={2} percent={100} color={"green"} />
      </div>

      {map(ordered, (step, i) => (
        <Fragment key={step.id}>
          <Tooltip text={step.name} delay={0}>
            <WorkflowStepIcon
              step={step}
              size={16}
              onClick={(e) => handleStepClicked(e, step)}
            />
          </Tooltip>
          {i !== ordered.length - 1 && (
            <ProgressBar
              thickness={2}
              percent={step.status?.id === "FNS" ? 100 : 0}
              color={"green"}
            />
          )}
        </Fragment>
      ))}
    </HStack>
  );
};

export const WorkflowDetailsPopup = ({ workflowId }: { workflowId: ID }) => {
  const pushTo = usePushTo();
  const workflow = useLazyEntity<"workflow">(workflowId);
  const finished = when(workflow, isFinished) ?? false;
  const status = useLazyPropertyValue(workflow, {
    field: "status",
    type: "status",
  });
  const steps = useLazyEntities<"workflow_step">(workflow?.refs?.steps);
  const engine = useEngine("workflow_step");
  const ordered = useMemo(
    () =>
      orderedSteps(
        finished ? filter(steps, (s) => s?.status?.id === "FNS") : steps || []
      ),
    [steps, isFinished]
  );
  const handleStepClicked = useCallback(
    (step: WorkflowStep) => {
      when(step.refs?.created?.[0], (r) => {
        pushTo(r);
      });
    },
    [pushTo]
  );

  if (!workflow) {
    return <div className={styles.stepsMenu}>Workflow not found...</div>;
  }

  return (
    <div className={styles.detailPopover}>
      <SpaceBetween>
        <Label size="medium" fit="container">
          {workflow?.name}
        </Label>
        <HStack gap={0}>
          <Icon icon={<StatusIndicator status={status.value.status} />} />
          <GoToButton item={workflow} />
        </HStack>
      </SpaceBetween>
      <WorkflowProgressBar workflow={workflow} />

      <Menu className={styles.menu}>
        <MenuGroup>
          {map(ordered, (step) =>
            render(engine.asMenuItem, {
              key: step.id,
              item: step,
              onOpen: () => handleStepClicked(step),
            })
          )}
        </MenuGroup>
      </Menu>
    </div>
  );
};
