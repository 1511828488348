import { flatMap, isEmpty, map, mapKeys, some, values } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { Form, Ref } from "@api";

import {
  FormData,
  isReadonlyField,
  toStoredField,
  useFormFields,
} from "@state/form";
import { useCreateFromTemplate } from "@state/generic";
import { useCheckAndClaimFor } from "@state/jobs";
import { useMe } from "@state/persons";

import { ensureMany } from "@utils/array";
import { explode } from "@utils/confetti";
import { usePreventClose } from "@utils/event";
import { Fn, not } from "@utils/fn";
import { newID } from "@utils/id";
import { equalsAny } from "@utils/logic";
import { Maybe, safeAs, when } from "@utils/maybe";
import { asMutation } from "@utils/property-mutations";
import { toFieldKey, toRef } from "@utils/property-refs";
import { replacePart, toChildLocation, toParentScope } from "@utils/scope";
import { toStamp } from "@utils/stamp";

import { Button } from "@ui/button";
import { Divider } from "@ui/divider";
import { HStack, SpaceBetween } from "@ui/flex";
import { useMutate } from "@ui/mutate";
import { showError, showSuccess } from "@ui/notifications";
import { RelationButton } from "@ui/relation-label";
import { Text } from "@ui/text";

import { FormFields } from "./fields";

interface Props {
  form: Form;
  data: FormData;
  onChanged: Fn<Partial<FormData>, void>;
  onSubmitted?: Fn<Maybe<Ref>, void>;
}

export const FormSubmit = ({ form, data, onChanged, onSubmitted }: Props) => {
  const me = useMe();
  const [saving, setSaving] = useState(false);
  const checkAndClaim = useCheckAndClaimFor();
  const fields = useFormFields(form);
  const mutate = useMutate();

  // Resources that are uploaded before the form is created need to be moved
  // into the new form
  const moveResourcesTo = useCallback(
    (template: Form, newForm: Ref) => {
      const resources = flatMap(fields, ([f, d]) =>
        f?.type === "relations" &&
        equalsAny(
          "resource",
          ensureMany(d?.options?.references || f.options?.references)
        )
          ? safeAs<Ref[]>(data[f.field]) || []
          : []
      );

      if (isEmpty(resources)) {
        return;
      }

      const transaction = newID();
      const newLocation = replacePart(
        toChildLocation(template.source.scope, template.id),
        form.id,
        newForm.id
      );

      // Move resources to the new location
      mutate(
        map(resources, (r) => ({
          id: r.id,
          method: "update",
          transaction,
          changes: [
            asMutation({ field: "location", type: "text" }, newLocation),
            asMutation({ field: "template", type: "text" }, undefined),
          ],
          source: {
            type: "resource",
            scope: template?.source.scope || newLocation,
          },
        }))
      );
    },
    [fields, data, mutate, form?.source.scope]
  );

  const onCreated = useCallback(
    async (ref?: Maybe<Ref>) => {
      if (ref) {
        moveResourcesTo(form, ref);
        await checkAndClaim(ref.id);
      }

      showSuccess("Form submitted!");
      explode();
      setSaving(false);
      onSubmitted?.(form);
    },
    [setSaving, form?.id, form?.location, moveResourcesTo]
  );

  // First a copy of the form is saved
  const saveFormCopy = useCreateFromTemplate(form.source, onCreated);

  const readyToSubmit = useMemo(
    () => some(fields, (f) => !isReadonlyField(f)) && !!saveFormCopy.ready,
    [fields]
  );

  const handleSubmit = useCallback(() => {
    if (saving || !fields || !form) {
      return;
    }

    if (!some(values(data), not(isEmpty))) {
      showError("Nothing to submit.");
      return;
    }

    setSaving(true);

    // Add createdBy as the person submitting the form
    const finalFormData = mapKeys(
      {
        ...data,
        createdBy: toRef(me),
      } as FormData,
      (_v, k) => toFieldKey(k)
    );

    // Save a copy of the form
    saveFormCopy.create(form, {
      overrides: {
        [form.id]: {
          // Store all the fields in the form (includes workflow fields)
          fields: map(fields, (f) => toStoredField(f, finalFormData)),
          // Store all values in the form
          custom: finalFormData,
          // Mark it for scheduling
          stamps: { submitted: toStamp(me) },
        },
      },
    });
  }, [saving, setSaving, fields, form, data, me, saveFormCopy]);

  usePreventClose(
    saving,
    () =>
      "This form is still submitting. Wait a few seconds and try again. Or reload anyway?"
  );

  if (!form) {
    return <></>;
  }

  return (
    <>
      <FormFields form={form} data={data} onChange={onChanged} />

      <Divider />

      <SpaceBetween>
        {when(toParentScope(form.inLocation), (p) => (
          <HStack gap={2}>
            <Text subtle>Creates work in </Text>
            <RelationButton size="tiny" relation={{ id: p }} />
          </HStack>
        )) || <span />}

        <Button
          variant="primary"
          onClick={handleSubmit}
          loading={saving}
          disabled={!readyToSubmit}
        >
          Submit form
        </Button>
      </SpaceBetween>
    </>
  );
};
