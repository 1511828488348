import { Job, Person } from "@api";

import { findPreferential } from "@utils/array";
import { useISODate } from "@utils/date-fp";
import { minutesAgo } from "@utils/time";

export const isTimedOut = (job: Job) =>
  useISODate(job.lockedAt, (d) => minutesAgo(d) > 5);

export const isRunning = (job: Job) => job.status === "running";

export const isLockedByMe = (job: Job, me: Person, myKey: string) =>
  !!job.lockedBy?.id && job.lockedBy?.id === me.id && job.lockKey === myKey;
