import { atomFamily, selectorFamily } from "recoil";

import {
  EntityType,
  hasLocation,
  HasTemplate,
  ID,
  Integration,
  isAssignable,
  isOwnable,
  View,
} from "@api";

import {
  FetchResultsAtom,
  removeFetchResults,
  setFetchResults,
} from "@state/fetch-results";
import { getStore } from "@state/generic";
import {
  aliasedID,
  applyMutations,
  getItem,
  indexedDBStorageForEntityStore,
  persistedID,
  setItemPure,
  StoreState,
  tempUpdatesForId,
} from "@state/store";
import { getViewTemplate } from "@state/templates";
import { activeUserId, WorkspaceWrappedAtom } from "@state/workspace";

import { typeFromId } from "@utils/id";
import { Maybe, safeAs, when } from "@utils/maybe";
import { toRef } from "@utils/property-refs";
import { isDefault } from "@utils/recoil";
import { toChildLocation, toLast } from "@utils/scope";

import { getEngine } from "@ui/engine";

import {
  addNewFilter,
  fromTemplateViewId,
  isTemplateViewId,
  newView,
} from "./utils";

export type { View };

export type ViewState = View;

export type ViewStoreState = StoreState<ViewState>;

export const ViewFetchResultsAtom = selectorFamily({
  key: "ViewFetchResultsAtom",
  get:
    (id: ID) =>
    ({ get }) => {
      const store = get(ViewStoreAtom);
      const pId = persistedID(id, store.aliases);

      const persistedResults = pId && get(FetchResultsAtom(pId));

      // When results from persisted ID exist, use them.
      if (persistedResults) {
        return persistedResults;
      }

      // If no peristedID use whatever alias we have
      const alias =
        aliasedID(id, store?.aliases) || getItem(store, id)?.alias || id;
      return get(FetchResultsAtom(alias));
    },

  set:
    (id: ID) =>
    ({ set, get }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        const store = get(ViewStoreAtom);
        const view = getItem(store, id);
        const alias = aliasedID(id, store?.aliases);
        const pId = persistedID(id, store.aliases);

        if (pId) {
          // Clear out any aliased results
          alias && set(FetchResultsAtom(alias), removeFetchResults());
          view?.alias &&
            set(FetchResultsAtom(view.alias), removeFetchResults());

          // And set the persisted results
          set(
            FetchResultsAtom(pId),
            setFetchResults(newValue.ids, newValue.fetchedAt)
          );
        } else {
          // Just set what was passed in
          set(
            FetchResultsAtom(id),
            setFetchResults(newValue.ids, newValue.fetchedAt)
          );
        }
      }
    },
});

export const lastFetchedViewResults = selectorFamily({
  key: "lastFetchedViewResults",
  get:
    (id: ID) =>
    ({ get }) => {
      const results = get(ViewFetchResultsAtom(id));
      return results?.fetchedAt;
    },
});

export const WorkspaceViewStoreAtom = atomFamily<ViewStoreState, ID>({
  key: "WorkspaceViewStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "view", unsaved: [] },
  effects: (wid) => [indexedDBStorageForEntityStore(wid, "view")],
});

export const ViewStoreAtom = WorkspaceWrappedAtom(
  "ViewStoreAtom",
  WorkspaceViewStoreAtom
);

export const ViewAtom = selectorFamily({
  key: "ViewAtom",
  get:
    (id: ID) =>
    ({ get }) => {
      const store = get(ViewStoreAtom);
      const item =
        getItem(store, id) ||
        when(aliasedID(id, store?.aliases), (alias) => getItem(store, alias));

      if (!item && isTemplateViewId(id)) {
        const { key, params } = fromTemplateViewId(id);
        const { parent: tempLocation, entity, for: dataSource } = params;
        const assignedId = params.assigned || params.owner;

        // Possibly not loaded yet
        const parent = when(toLast(tempLocation) || undefined, (id) =>
          getItem(get(getStore(typeFromId<EntityType>(id))), id)
        );
        // Possibly not loaded yet
        const target = when(
          toLast(dataSource) || toLast(tempLocation) || undefined,
          (id) => getItem(get(getStore(typeFromId<EntityType>(id))), id)
        );
        const location = hasLocation(parent)
          ? toChildLocation(parent.location, parent.id)
          : tempLocation;

        // Parent not loaded yet, we don't want to return the template yet because info wont be correct
        if ((tempLocation && !parent) || !location) {
          return undefined;
        }

        const userId = get(activeUserId);
        const template =
          getViewTemplate(key, userId) ||
          // Fallback to default list view if not found
          getViewTemplate("default-list", userId) ||
          // Else undefined
          {};

        if (!template) {
          throw new Error(`System template (${key}) not configured.`);
        }

        const type = (entity as Maybe<EntityType>) || template?.entity;

        const view = newView({
          id: id,

          ...template,

          // When using from template, we want to show subs if the parent is the same type
          settings: {
            ...template.settings,
            showSubs:
              template.settings?.showSubs ?? parent?.source.type === type,
          },

          // What data source we are looking at
          entity: type,

          // Restricts items to only show up for this scope
          for: when(target?.id || dataSource, toRef),

          // Where this lives in the hierarchy, what it's linked to
          location: location,

          // Filter setup based on input params
          filter:
            assignedId &&
            type &&
            type !== "workflow" && // Workflows use refs.followers instead
            (isAssignable(type) || isOwnable(type))
              ? addNewFilter(template.filter, {
                  field: isAssignable(type) ? "assigned" : "owner",
                  type: "relation",
                  op: "equals",
                  value: { relation: { id: assignedId } },
                })
              : template.filter,

          // Default to first item in list if not set on template
          order: template.order ?? 0,

          // Set the tmp view to be a template if the parent is a template
          template: !!(
            safeAs<HasTemplate>(parent)?.template ||
            safeAs<HasTemplate>(target)?.template
          )
            ? "nested"
            : undefined,

          ...(parent
            ? getEngine(parent.source.type)?.toViewDefaults?.(id, parent)
            : {}),

          source: {
            source: Integration.Traction,
            type: "view",
            scope: location,
          },
        });

        // If we started mutating this view and then refreshed, the changes are in the unsaved state
        // but we are creating it from scratch here...
        const tempChanges = tempUpdatesForId(id, get(ViewStoreAtom));
        if (tempChanges.length) {
          return applyMutations(view, tempChanges);
        }

        return view;
      }

      return item;
    },
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(ViewStoreAtom, setItemPure(newValue));
      }
    },
});
