import { map } from "lodash";

import { Process } from "@api";

import { useVisiblePropertyRefs } from "@state/properties";

import { cx } from "@utils/class-names";
import { withHandle } from "@utils/event";

import { Ellipsis } from "@ui/ellipsis";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { SmartLocationLabel } from "@ui/location-button";
import { MenuItem } from "@ui/menu-item";
import { PropertyValue } from "@ui/property-value";
import { ReadonlyPlainText } from "@ui/rich-text";
import { SelectableListCard, SelectableListItem } from "@ui/selectable-items";
import { Text, TextMedium } from "@ui/text";

import { ListItemOpts, UIEngine } from "../types";
import { ProcessPane } from "./pane";

import styles from "./styles.module.css";

// Export individual components

export { ProcessLinksPane, ProcessPane } from "./pane";
export { RelatedProcessesPane } from "./related-processes-pane";

export const ProcessEngine: UIEngine<Process> = {
  asMenuItem: function ({ item, onOpen, ...rest }) {
    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          className={styles.relative}
          {...rest}
          onClick={withHandle(() => onOpen?.(item))}
        >
          <SpaceBetween>
            <HStack align="stretch">
              <HStack gap={4}>
                <Text>{item.name || "Empty process"}</Text>
              </HStack>
            </HStack>
          </SpaceBetween>
        </MenuItem>
      </EntityContextMenu>
    );
  },
  asListCard: (props) => {
    const { item } = props;
    return (
      <EntityContextMenu entity={props.item}>
        <SelectableListCard
          {...props}
          className={cx(styles.card, props.className)}
        >
          <SpaceBetween direction="horizontal" gap={6} align="stretch">
            <VStack className={styles.bar} gap={0}>
              <SpaceBetween>
                <Ellipsis>
                  <HStack gap={4}>
                    <Text>{item.name || "Empty process"}</Text>
                  </HStack>
                </Ellipsis>
              </SpaceBetween>
            </VStack>
          </SpaceBetween>
        </SelectableListCard>
      </EntityContextMenu>
    );
  },
  asListItem: function EntityListItem(props: ListItemOpts<Process>) {
    const { item, showProps, variant, onChange, showLocation = true } = props;
    const { visible } = useVisiblePropertyRefs(item, showProps, {
      blacklist: ["name", "status"],
      hideEmpty: props.hideEmpty,
    });
    return (
      <EntityContextMenu
        entity={item}
        selection={props.selection}
        setSelection={props.setSelection}
      >
        <SelectableListItem
          {...props}
          className={cx(styles.relative, props.className)}
        >
          <VStack gap={6}>
            <SpaceBetween fit="container">
              <HStack gap={4}>
                <TextMedium>{item.name || "Empty process"}</TextMedium>
              </HStack>
              <HStack justify="flex-end" gap={2}>
                {map(visible, (val) => (
                  <PropertyValue
                    key={val.field}
                    valueRef={val}
                    source={item.source}
                    size="small"
                    variant={variant || "unlabelled"}
                    onChange={(c) =>
                      onChange?.({
                        field: val.field,
                        type: val.type,
                        value: c,
                        prev: val.value,
                      })
                    }
                  />
                ))}
              </HStack>
            </SpaceBetween>

            {showLocation && (
              <SmartLocationLabel
                location={item.location}
                showTeam={false}
                showIcons={false}
                size="small"
                subtle
              />
            )}

            {/* <Ellipsis> */}
            <ReadonlyPlainText text={item.summary} className={styles.subtext} />
            {/* </Ellipsis> */}
          </VStack>
        </SelectableListItem>
      </EntityContextMenu>
    );
  },
  asPrimaryPane: ProcessPane,
};
