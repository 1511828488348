import {
  Entity,
  EntityType,
  FetchOptions,
  FilterQuery,
  getOptimizedForFilter,
  ID,
} from "@api";

import { cachedFuncByWorkspace } from "@state/workspace";
import { omitEmpty } from "@utils/array";

import { append } from "@utils/filtering";
import { Fn } from "@utils/fn";
import { when } from "@utils/maybe";
import { toMilliSeconds } from "@utils/time";

export const getOptimizedTemplates = async (
  type: EntityType,
  opts?: FetchOptions,
  filter?: FilterQuery,
  cb?: Fn<{ all: ID[]; changed: Entity[] }, void>
) => {
  const results = await getOptimizedForFilter(
    { type },
    {
      and: omitEmpty([
        filter,
        {
          field: "template",
          op: "equals",
          type: "text",
          value: { text: "root" },
        },
      ]),
    },
    opts
  );
  cb?.(results);
  return results;
};

export const getOptimizedTemplatesLoader = cachedFuncByWorkspace(
  () => getOptimizedTemplates,
  toMilliSeconds("10 seconds"),
  ([t, opts, filter]) =>
    t + opts?.archived + opts?.since + when(filter, (f) => JSON.stringify(f))
);
