import { isArray, isFunction, map } from "lodash";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

import { DatabaseID, Entity, PropertyValueRef, Update } from "@api";

import {
  WorkflowAction,
  WorkflowContext,
  WorkflowData,
  WorkflowSuggestion,
} from "@state/workflows";

import { replace } from "@utils/array";
import { Fn } from "@utils/fn";
import { asValue, isVariableDef } from "@utils/property-refs";

import { Button } from "@ui/button";
import { Dialog } from "@ui/dialog";
import { FormField } from "@ui/engine/form/fields";

import { VStack } from "./flex";

type Props<
  T extends Entity,
  A extends WorkflowAction<T> | WorkflowSuggestion<T>
> = {
  action: A;
  context: WorkflowContext<T>;
  source: DatabaseID;
  onCollected: (action: A, collected: PropertyValueRef<T>[]) => void;
  onCancel: () => void;
} & (A extends WorkflowSuggestion<T>
  ? { data: WorkflowData<T> & { update: Update<T> } }
  : { data: WorkflowData<T> });

export const WorkflowCollectDialog = <
  T extends Entity,
  A extends WorkflowAction<T> | WorkflowSuggestion<T>
>({
  action,
  data,
  source,
  context,
  onCollected,
  onCancel,
}: Props<T, A>) => {
  const [values, setValues] = useState<PropertyValueRef<T>[]>([]);
  const toCollect = useMemo(
    () => (isArray(action.collect) ? action.collect : []),
    [action]
  );
  const CustomComponent = useMemo(
    () => (isFunction(action.collect) ? action.collect : undefined),
    [action]
  );

  useEffect(() => {
    if (isFunction(action.collect)) {
      return;
    }

    if (!action.collect?.length) {
      onCollected?.(action, []);
    } else {
      setValues(
        map(toCollect, (r) => ({ ...r, value: { [r.type]: undefined } }))
      );
    }
  }, [action.collect]);

  if (CustomComponent) {
    return (
      <CustomComponent
        data={data as WorkflowData<T> & { update: Update<T> }}
        context={context}
        onCollected={(vs) => onCollected(action, vs)}
        onCancelled={onCancel}
      />
    );
  }

  return (
    <CollectDialog
      title={action.title}
      description={action.description}
      collect={values}
      source={source}
      onCollected={(vs) => onCollected(action, vs)}
      onCancel={onCancel}
    />
  );
};

type CollectProps<T extends Entity> = {
  title?: string;
  description?: string;
  submitLabel?: string;
  collect: PropertyValueRef<T>[];
  source: DatabaseID;
  onCollected: (collected: PropertyValueRef<T>[]) => void;
  onCancel: () => void;
  onClose?: () => void;
};

export const CollectDialog = <T extends Entity = Entity>({
  title,
  description,
  collect,
  source,
  onCollected,
  onCancel,
  onClose = onCancel,
  submitLabel,
}: CollectProps<T>) => {
  const [submitted, setSubmitted] = useState(false);
  const [values, setValues] = useState<PropertyValueRef<T>[]>(collect);

  useEffect(() => {
    setValues(collect);
  }, [collect?.length]);

  return (
    <Dialog
      onDismiss={onClose}
      title={title || "Information needed"}
      // Prevent first field from focusing since we haven't implemented nice focus behaviour for non-inputs
      onAutoFocus={(e) => e.preventDefault()}
      description={description}
      actions={
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            variant="primary"
            onClick={() => {
              onCollected(values);
              setSubmitted(true);
            }}
            loading={submitted}
          >
            {submitLabel || "Done"}
          </Button>
        </>
      }
    >
      <CollectWorkflowInputs
        values={values}
        setValues={setValues}
        source={source}
      />
    </Dialog>
  );
};

export const CollectWorkflowInputs = <T extends Entity = Entity>({
  values,
  setValues,
  source,
}: {
  values: PropertyValueRef<T>[];
  setValues: Dispatch<SetStateAction<PropertyValueRef<T>[]>>;
  source: DatabaseID;
}) => {
  return (
    <VStack gap={20}>
      {map(values, (value, i) => (
        <FormField
          key={String(value.field)}
          def={value.def || (isVariableDef(value) ? value : undefined)}
          focus={i === 0}
          prop={value as PropertyValueRef}
          scope={source.scope}
          value={value.value || { [value.type]: undefined }}
          onChange={(change) => {
            const newValue = { ...value, value: asValue(value.type, change) };
            setValues((vs) => replace(vs, i, newValue));
          }}
        />
      ))}
    </VStack>
  );
};
