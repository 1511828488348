// Component to make room for shadows so they don't get cut off by the parent element
// Uses padding plus negative margin to create space

import { CSSProperties, ReactNode } from "react";

export default function ShadowPadding({
  children,
  padding = 16,
  style,
  className,
}: {
  children: ReactNode;
  padding?: number;
  style?: CSSProperties;
  className?: string;
}) {
  return (
    <div
      className={className}
      style={{
        padding: padding,
        margin: -padding,
        width: `calc(100% + ${padding * 2}px)`,
        boxSizing: "border-box",
        ...style,
      }}
    >
      {children}
    </div>
  );
}
