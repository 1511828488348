import Placeholder from "@tiptap/extension-placeholder";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import TextStyle from "@tiptap/extension-text-style";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect, useLayoutEffect, useMemo, useRef } from "react";

import { Entity, RichText, Update, VariableDef, Vars } from "@api";

import { useQueueUpdates } from "@state/generic";

import { OneOrMany } from "@utils/array";
import { cx } from "@utils/class-names";
import { debug } from "@utils/debug";
import { Fn } from "@utils/fn";
import { useDebouncedCallback, useSyncedRef } from "@utils/hooks";
import { Maybe } from "@utils/maybe";
import { isEmpty, toHtml } from "@utils/rich-text";

import { NoSelectable } from "@ui/selectable-items";
import { Size } from "@ui/types";

import { BoardExtension } from "./board-extension";
import { CalloutExtension } from "./callout-extension";
import { BlockEmbed, LinkEmbed } from "./embed-extension";
import { Link, useInternalNavigator } from "./link-extension";
import { MarkdownPasteHandler } from "./markdown-paste-handler";
import { GlobalSuggestion, Mentioner, MentionProps } from "./mention-extension";
import { FormattingMenu, TrailingParagraph } from "./menus";
import { PageExtension } from "./page-extension";
import { SlashExtension, suggestions } from "./slash-extension";
import { extensions as tableExtensions, TableMenuBar } from "./table-extension";
import { UploadExtension } from "./upload-extension";
import { isFocused } from "./utils";
import { VariableExtension } from "./variable-extension";

import styles from "./document-editor.module.css";

type VariableProps = {
  variables?: Vars;
  onNewVariable?: Fn<VariableDef, void>;
};

export type DocumentEditorProps = VariableProps &
  MentionProps & {
    content: Maybe<RichText>;
    scope?: string;
    placeholder?: string;
    textSize?: Size;
    editable?: boolean;
    autoFocus?: boolean;
    color?: "default" | "inverted";
    onChanged: Fn<RichText, void>;
    onBlur?: Fn<RichText, void>;
    onFocus?: Fn<RichText, void>;
    updateDelay?: number;
    newLineSpace?: "small" | "large" | "xlarge";
    className?: string;
  };

type ExtensionOpts = VariableProps &
  MentionProps & {
    scope?: string;
    placeholder?: string;
    mutate?: Fn<OneOrMany<Update<Entity>>, void>;
  };

const configureExtensions = ({
  scope,
  placeholder,
  mutate,
  onMention,
  onNewVariable,
  variables,
}: ExtensionOpts) => [
  MarkdownPasteHandler.configure({}),

  Placeholder.configure({
    emptyEditorClass: styles.placeholder,
    placeholder: placeholder,
  }),

  TextStyle.configure({}),

  PageExtension.configure({ mutate, scope }),

  UploadExtension.configure({ mutate, scope }),

  LinkEmbed.configure({}),
  BlockEmbed.configure({}),

  BoardExtension.configure({ scope }),

  CalloutExtension.configure({ defaultIcon: "⚠️" }),

  ...(variables?.length || onNewVariable
    ? [VariableExtension.configure({ scope, variables, onNewVariable })]
    : []),

  Mentioner.configure({
    suggestion: GlobalSuggestion({ scope, onMention }),
  }),

  SlashExtension.configure({
    suggestion: suggestions,
  }),

  TaskList,
  TaskItem.configure({
    nested: true,
  }),

  ...tableExtensions(),

  Link.configure({
    autolink: true,
    linkOnPaste: true,
  }),

  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
];

export const DocumentEditor = ({
  content,
  placeholder,
  onChanged,
  autoFocus,
  newLineSpace,
  textSize = "medium",
  scope,
  variables,
  color = "default",
  className,
  editable = true,
  updateDelay = 1000,
  onMention,
  onBlur,
  onFocus,
  onNewVariable,
}: DocumentEditorProps) => {
  useInternalNavigator();
  const mutate = useQueueUpdates();
  const onChangedRef = useRef(onChanged);
  const dirtyRef = useRef(false);
  const onChangeDebounced = useDebouncedCallback(
    (html: string) => onChangedRef.current?.({ html }),
    updateDelay,
    { trailing: true }
  );
  const mentionRef = useSyncedRef(onMention);
  const newVarRef = useSyncedRef(onNewVariable);
  const mutateRef = useSyncedRef(mutate);
  const extensions = useMemo(
    () =>
      configureExtensions({
        scope,
        placeholder,
        variables,
        mutate: (v) => mutateRef.current?.(v),
        onNewVariable: (v) => newVarRef.current?.(v),
        onMention: (val) => mentionRef.current?.(val),
      }),
    [placeholder, scope]
  );

  const editor = useEditor({
    extensions: extensions,
    content: toHtml(content),

    editorProps: {
      attributes: {
        class: cx(
          styles.tiptap,
          styles.document,
          styles[textSize],
          styles[`${color}Color`]
        ),
      },
    },

    // triggered on every change
    onUpdate: ({ editor }) => {
      dirtyRef.current = true;
      onChangeDebounced(editor.getHTML());
    },

    onBlur: ({ editor }) => {
      const html = editor.getHTML();
      if (dirtyRef.current) {
        onChangedRef.current?.({ html });
      }
      onBlur?.({ html });
    },

    onFocus: ({ editor }) => {
      dirtyRef.current = false;
      onFocus?.({ html: editor.getHTML() });
    },
  });

  // When content changes, check if the editor is focused and if not set the contnet
  useEffect(() => {
    if (
      !!editor &&
      (!isEmpty(content) || !editor.isEmpty) &&
      !isFocused(editor)
    ) {
      editor.commands.setContent(toHtml(content));
    } else {
      debug("Skipping update", {
        editor: editor,
        isEmpty: isEmpty(content),
        isFocused: editor && isFocused(editor),
        content: content,
      });
    }
  }, [content]);

  // Update editor editable when param changes
  useEffect(() => {
    // Important: Second param is to prevent the editor from firing the update event
    // Otherwise causes an infinite loop of updates with old values
    editor?.setEditable(editable, false);
  }, [editable]);

  // Keep onChange callback in sync
  useEffect(() => {
    onChangedRef.current = onChanged;
  }, [onChanged]);

  // Auto focus when needed
  useLayoutEffect(() => {
    if (autoFocus) {
      editor?.chain().focus().run();
    }
  }, [autoFocus, !!editor]);

  if (!editor) {
    return <></>;
  }

  return (
    <div
      className={cx(styles.wrapper, className)}
      onClick={() => editor.chain().focus().run()}
    >
      <NoSelectable>
        <EditorContent editor={editor} />
        {!!newLineSpace && (
          <TrailingParagraph size={newLineSpace} editor={editor} />
        )}
        <TableMenuBar editor={editor} />
        <FormattingMenu editor={editor} />
      </NoSelectable>
    </div>
  );
};

export const ReadonlyDocument = ({
  content,
  textSize = "medium",
  color,
  className,
}: Omit<DocumentEditorProps, "onChanged" | "newLineSpace" | "placeholder">) => {
  useInternalNavigator();

  const editor = useEditor({
    extensions: useMemo(() => configureExtensions({}), []),
    content: toHtml(content),
    editable: false,

    editorProps: {
      attributes: {
        class: cx(
          styles.tiptap,
          styles.document,
          styles[textSize],
          styles[`${color}Color`]
        ),
      },
    },
  });

  useEffect(() => {
    if (content && editor) {
      editor.commands.setContent(toHtml(content));
    }
  }, [content]);

  if (!editor) {
    return <></>;
  }

  return (
    <div
      className={cx(styles.wrapper, className)}
      onClick={() => editor.chain().focus().run()}
    >
      <EditorContent editor={editor} />
    </div>
  );
};
