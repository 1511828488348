import { filter, find, map, pick, without } from "lodash";
import {
  memo,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  DatabaseID,
  Entity,
  EntityType,
  FilterQuery,
  ID,
  isStatusable,
  JsonArray,
  PropertyDef,
  PropertyMutation,
  PropertyType,
  PropertyValue as PropertyValueType,
  PropertyValueRef,
  Ref,
  RichText,
  Update,
  VariableDef,
  Workflow,
  WorkflowAction,
  WorkflowStep,
} from "@api";

import { JsonObject } from "@prisma/client/runtime/library";

import { useAiUseCase, workflowStepCreate } from "@state/ai";
import {
  useLazyEntity,
  useLazyQuery,
  useNestedSource,
  useUpdateEntity,
} from "@state/generic";
import { useFilterableProperties, useLazyProperties } from "@state/properties";
import { useEntityLabels } from "@state/settings";
import { aiStepToUpdate, useVariables } from "@state/workflow";

import {
  ensureMany,
  justOne,
  maybeLookup,
  maybeMap,
  omitFalsey,
  OneOrMany,
  uniqBy,
} from "@utils/array";
import { cx } from "@utils/class-names";
import { logThrough } from "@utils/debug";
import { Fn } from "@utils/fn";
import { extractVarReference, toVarReference } from "@utils/formula";
import { useConst } from "@utils/hooks";
import { equalsAny } from "@utils/logic";
import { Maybe, safeAs, when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import {
  asAppendMutation,
  asFormulaMutation,
  asMutation,
} from "@utils/property-mutations";
import {
  asFormulaValue,
  asPropertyValueRef,
  getPropertyValue,
  isAnyRelation,
  toRef,
} from "@utils/property-refs";
import { isEmpty } from "@utils/rich-text";
import { toBaseScope, toLast } from "@utils/scope";
import { plural, titleCase } from "@utils/string";

import { Button } from "@ui/button";
import { ButtonGroup, SplitButton } from "@ui/button-group";
import { Card } from "@ui/card";
import { ContextItem, ContextMenu } from "@ui/context-menu";
import { Divider } from "@ui/divider";
import { EditableHeading } from "@ui/editable-heading";
import { EntityPreview } from "@ui/entity-preview";
import { Centered, FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { Icon, Magic, Play, PlusIcon, SpinnerIcon, TimesIcon } from "@ui/icon";
import { InflatedStatusTag } from "@ui/inflated-status-tag";
import { Field, TextInput } from "@ui/input";
import { ListItem } from "@ui/list-item";
import { LocationButton } from "@ui/location-button";
import { Menu } from "@ui/menu";
import { CheckMenuItem } from "@ui/menu-item";
import { showError } from "@ui/notifications";
import { NestedPropertyFilter } from "@ui/property-filter";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { PropertyValue } from "@ui/property-value";
import { RelationIcon, RelationLabel } from "@ui/relation-label";
import { DocumentEditor, TextBox } from "@ui/rich-text";
import { ColoredSection } from "@ui/section";
import { GlobalEntitySelect, LocationSelect, Select } from "@ui/select";
import { EntityTypeSelect } from "@ui/select/entity-type";
import { SlackSelect } from "@ui/select/slack";
import { WithVariableSelect } from "@ui/select/variable";
import { SquareButton } from "@ui/square-button";
import { TemplateConfigure } from "@ui/template-configure";
import { Text, TextMedium, TextSmall } from "@ui/text";
import { EditVariableModal, VariableList } from "@ui/variable-list";
import { RunWorkflowModal } from "@ui/workflow-run-modal";

import { WorkflowStepIcon } from "../workflow_step/icon";

import styles from "./editor.module.css";

const ADD_STEPS = [
  {
    action: WorkflowAction.Create,
    label: "Add Work",
  },
  {
    action: WorkflowAction.Update,
    label: "Update Work",
  },
  {
    action: WorkflowAction.Find,
    label: "Find Work",
  },
  {
    action: WorkflowAction.Wait,
    label: "Wait Time",
  },
  {
    action: WorkflowAction.Control,
    label: "Wait All",
  },
  {
    action: WorkflowAction.Condition,
    label: "Condition",
  },
  {
    action: WorkflowAction.SetVar,
    label: "Set Variable",
  },
  {
    action: WorkflowAction.AI,
    label: "AI Prompt",
  },
  {
    action: WorkflowAction.Message,
    label: "Send Message",
  },
  {
    action: WorkflowAction.RunWorkflow,
    label: "Run Workflow",
  },
  {
    action: WorkflowAction.Exit,
    label: "End Workflow",
  },
];

export const AddStepOptions = memo(
  ({ onAdd }: { onAdd: Fn<WorkflowAction, void> }) => {
    return (
      <HStack wrap gap={0}>
        {map(ADD_STEPS, ({ label, action }) => (
          <SquareButton
            key={action}
            className={styles.squareButton}
            icon={<WorkflowStepIcon size={"medium"} action={action} />}
            iconSize="xlarge"
            text={label}
            onClick={() => onAdd?.(action)}
          />
        ))}
      </HStack>
    );
  },
  (prev, next) => prev.onAdd === next.onAdd
);

AddStepOptions.displayName = "AddStepOptions";

export const WorkflowStepEditor = ({
  id,
  workflow,
  steps,
}: {
  id: Maybe<ID>;
  workflow: Workflow;
  steps: Maybe<WorkflowStep[]>;
}) => {
  const step = useLazyEntity<"workflow_step">(id);
  const mutate = useUpdateEntity(id || "");

  if (!step) {
    return (
      <Centered fit="container" direction="horizontal">
        <Text subtle>Nothing selected.</Text>
      </Centered>
    );
  }

  return (
    <VStack fit="container">
      <Field>
        <TextInput
          icon={<WorkflowStepIcon action={step.action} />}
          value={step.name || ""}
          placeholder="Untitled step"
          autoFocus={false}
          onChange={(n) =>
            mutate(asMutation({ field: "name", type: "text" }, n))
          }
        />
      </Field>

      <Divider />

      {step.action === "wait" && (
        <WorkflowStepEditorWait
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "ai" && (
        <WorkflowStepEditorAI
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "message" && (
        <WorkflowStepEditorMessage
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "set_var" && (
        <WorkflowStepEditorSetVar
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "control" && (
        <WorkflowStepEditorControl
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "find" && (
        <WorkflowStepEditorFind
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "condition" && (
        <WorkflowStepEditorCondition
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "create" && (
        <WorkflowStepEditorCreate
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
      {step.action === "update" && (
        <WorkflowStepEditorUpdate
          step={step}
          workflow={workflow}
          allSteps={steps}
        />
      )}
    </VStack>
  );
};

const WorkflowRunHistory = ({
  id,
  workflow,
}: {
  id: Maybe<ID>;
  workflow: Workflow;
}) => {
  const pushTo = usePushTo();
  const parent = useLazyEntity(toLast(workflow.location));
  const [starting, setStarting] = useState<Workflow>();
  const runs = useLazyQuery(
    "workflow",
    useMemo(
      () => ({
        field: "refs.fromTemplate",
        type: "relation",
        op: "equals",
        // TODO: getting replaced by createFromTemplate
        value: { relation: { id: workflow.id } },
      }),
      [workflow.id]
    )
  );

  return (
    <VStack>
      <TextMedium bold>Run History</TextMedium>

      {starting && (
        <RunWorkflowModal
          entity={parent}
          workflow={starting}
          autoStart={true}
          onClose={() => setStarting(undefined)}
        />
      )}

      <Field>
        <VStack>
          {map(runs, (r) => (
            <ListItem key={r.id} onClick={() => pushTo(r)}>
              <SpaceBetween>
                <Text>{r.name}</Text>
                <InflatedStatusTag status={r.status} source={r.source} />
              </SpaceBetween>
            </ListItem>
          ))}
        </VStack>
      </Field>

      <Divider />

      <HStack fit="container" justify="flex-end">
        <Button
          variant="primary"
          size="small"
          loading={!!starting}
          onClick={() => setStarting?.(workflow)}
          icon={Play}
        >
          Test Workflow
        </Button>
      </HStack>
    </VStack>
  );
};

const WorkflowStepListItem = ({
  step: s,
  onClick,
}: {
  step: WorkflowStep;
  onClick: Fn<MouseEvent, void>;
}) => {
  const pushTo = usePushTo();
  return (
    <ListItem key={s.id} onClick={onClick}>
      <SpaceBetween>
        <HStack>
          <WorkflowStepIcon step={s} size="small" />
          <Text>{s.name}</Text>
          <TextSmall subtle>{s.id}</TextSmall>
        </HStack>

        {map(s.refs?.created, (r) => (
          <Icon
            key={r.id}
            onClick={() => pushTo(r)}
            icon={<RelationIcon relation={r} />}
          />
        ))}
      </SpaceBetween>
    </ListItem>
  );
};

/* Step Editors */

type StepEditorProps = {
  step: WorkflowStep;
  workflow: Workflow;
  allSteps: Maybe<WorkflowStep[]>;
};

const WorkflowStepEditorCreate = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const pushTo = usePushTo();
  const mutate = useUpdateEntity(step.id);
  const mutateWorkflow = useUpdateEntity(workflow.id);
  const itemSource = useMemo(
    () => ({
      scope: step.source.scope,
      type: safeAs<EntityType>(step.options?.entity) || "task",
    }),
    [step.source.scope, step.options?.entity]
  );
  const properties = useLazyProperties(itemSource);
  const variables = useVariables(workflow, allSteps);
  const toEntityLabel = useEntityLabels(workflow.source.scope, {
    case: "title",
  });

  const titleProp = useMemo(
    () =>
      find(step.overrides, (o) => equalsAny(o.field, ["title", "name"])) ||
      when(
        find(properties, (p) => equalsAny(p.field, ["title", "name"])),
        (p) => asPropertyValueRef(p, { text: "" })
      ),
    [step.overrides]
  );
  const [title, titleVariable] = useReferencedVariable(
    titleProp?.value.text || titleProp?.value.formula,
    variables,
    "text"
  );

  const bodyProp = useMemo(
    () => find(properties, { field: "body" }),
    [properties]
  );

  const body = useMemo(
    () =>
      find(step.overrides, { field: "body" })?.value.rich_text || {
        html: "",
      },
    [step.overrides]
  );

  const outputVariable = useMemo(() => step.outputs?.[0], [step.outputs]);
  const handleToggleOutputVariable = useCallback(
    (output: boolean) => {
      mutate(
        asMutation(
          { field: "outputs", type: "json" },
          output
            ? [
                {
                  field: step.options?.entity || "default",
                  type: "relation",
                  options: { references: step.options?.entity },
                },
              ]
            : []
        )
      );
    },
    [step.outputs, step.options?.entity]
  );

  const mutateOverride = useMutateOverride(step, mutate);

  if (step.refs?.created?.length) {
    return (
      <CreatedWorkPreview
        work={step.refs.created[0]}
        onOpen={(r) => !!r && pushTo(r)}
      />
    );
  }

  return (
    <>
      <Field padded>
        <SpaceBetween>
          <EntityTypeSelect
            value={step.options?.entity as Maybe<EntityType>}
            scope={step.source.scope}
            plural={false}
            onChange={(v) =>
              mutate([
                // Change the type
                asMutation({ field: "options.entity", type: "text" }, v),
                // Blank out the outputs since it stores the type
                asMutation({ field: "outputs", type: "json" }, []),
                // Change the title
                asMutation(
                  { field: "name", type: "text" },
                  `Create ${toEntityLabel(v)}`
                ),
              ])
            }
          />

          <LocationSelect
            variant="full"
            location={
              safeAs<string>(step.options?.inLocation) || itemSource.scope
            }
            clearable={true}
            createable={false}
            showOpen={false}
            onChange={(v) =>
              mutate(
                asMutation({ field: "options.inLocation", type: "text" }, v)
              )
            }
          >
            {when(
              safeAs<string>(step.options?.inLocation) || undefined,
              (loc) => (
                <LocationButton variant="full" size="small" location={loc} />
              )
            ) || (
              <Button size="small" subtle>
                Default location
              </Button>
            )}
          </LocationSelect>
        </SpaceBetween>
      </Field>

      {titleProp && (
        <Field padded>
          <WithVariableSelect
            value={titleVariable}
            options={variables}
            allowed={["text"]}
            onChange={(v) =>
              mutateOverride(
                asFormulaMutation(titleProp, when(v, toVarReference))
              )
            }
          >
            <EditableHeading
              key={step.id}
              size="h3"
              text={title?.text || ""}
              autoFocus={!title.text}
              placeholder="Untitled"
              onChange={(t) => {
                mutateOverride(asMutation(titleProp, t));

                if (title.text === step.name || step.name === "Create Task") {
                  // Also set the name of the step
                  mutate(asMutation({ field: "name", type: "text" }, t));
                }
              }}
            />
          </WithVariableSelect>
        </Field>
      )}

      <Field>
        <TemplateConfigure
          template={when(safeAs<string>(step.options?.useTemplate), toRef)}
          overrides={safeAs<PropertyValueRef[]>(step.overrides)}
          field="overrides"
          variables={variables}
          blacklist={["name", "title", "body"]}
          source={itemSource}
          onChange={(c) => {
            mutate(c);

            // When setting the owner/assigned on the task, also set it on the step
            const overrides = find(
              ensureMany(c),
              (c) => c.field === "overrides"
            );
            const owner = find(
              safeAs<PropertyMutation[]>(overrides?.value.json),
              (p) =>
                p.type === "relation" &&
                equalsAny(p.field, ["owner", "assigned"])
            )?.value.relation;

            if (owner && step.owner?.id !== owner.id) {
              mutate(
                asMutation({ field: "owner", type: "relation" }, toRef(owner))
              );
            }
          }}
        />
      </Field>

      <Field label={bodyProp?.label} padded>
        <DocumentEditor
          placeholder="What needs to get done..."
          newLineSpace="large"
          scope={itemSource.scope}
          variables={variables}
          onNewVariable={(v) =>
            mutateWorkflow(
              asMutation(
                { field: "inputs", type: "json" },
                safeAs<JsonArray>([...(workflow.inputs || []), v])
              )
            )
          }
          content={body}
          onChanged={(rt) =>
            mutateOverride(asMutation({ field: "body", type: "rich_text" }, rt))
          }
        />
      </Field>

      <Divider />

      {isStatusable(itemSource.type) && (
        <Field>
          <CheckMenuItem
            inset
            checked={safeAs<boolean>(step.options?.waitDone) ?? true}
            onChecked={(wait) =>
              mutate(
                asMutation({ field: "options.waitDone", type: "boolean" }, wait)
              )
            }
            text="Wait until complete"
          />
        </Field>
      )}

      <Field>
        <CheckMenuItem
          inset
          checked={!!safeAs<boolean>(step.outputs?.length)}
          onChecked={handleToggleOutputVariable}
          text="Save as variable"
        />

        {!!outputVariable && (
          <TextInput
            value={outputVariable.field ? `@${outputVariable.field}` : ""}
            updateOn="blur"
            placeholder="@"
            onChange={(t) =>
              mutate(
                asMutation(
                  { field: "outputs", type: "json" },
                  safeAs<JsonArray>([
                    { ...outputVariable, field: t?.replace(/^@/, "") },
                  ])
                )
              )
            }
          />
        )}
      </Field>
    </>
  );
};

export const toWaitLabel = (
  step: Partial<WorkflowStep>,
  prefix: string = "Wait"
) => {
  const waitTimes = when(
    safeAs<number | string>(step.options?.waitTimes),
    Number
  );
  const waitPeriod = when(safeAs<string>(step.options?.waitPeriod), String);

  if (!waitTimes || !waitPeriod) {
    return "Wait";
  }

  return `${prefix} ${waitTimes} ${plural(titleCase(waitPeriod), waitTimes)}`;
};

const WorkflowStepEditorWait = ({
  workflow,
  allSteps,
  step,
}: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);
  const variables = useVariables(workflow, allSteps);

  const [waitTimesValue, waitTimesVariable] = useReferencedVariable(
    safeAs<string | number>(step.options?.waitTimes),
    variables,
    "number"
  );

  const handleChange = useCallback(
    ({
      waitTimes,
      waitPeriod,
    }: Partial<{ waitTimes: number; waitPeriod: string }>) => {
      mutate(
        omitFalsey([
          !!waitTimes
            ? asMutation(
                { field: "options.waitTimes", type: "number" },
                Math.max(waitTimes, 0)
              )
            : undefined,
          waitPeriod
            ? asMutation(
                { field: "options.waitPeriod", type: "text" },
                waitPeriod
              )
            : undefined,
          asMutation(
            { field: "name", type: "text" },
            toWaitLabel({
              options: {
                waitTimes: waitTimes || step.options?.waitTimes,
                waitPeriod: waitPeriod || step.options?.waitPeriod,
              },
            })
          ),
        ])
      );
    },
    [step]
  );

  return (
    <>
      <Field label="Wait">
        <SpaceBetween gap={6}>
          <div className={styles.numberInput}>
            <WithVariableSelect
              value={waitTimesVariable}
              options={variables}
              allowed={["number"]}
              onChange={(v) =>
                mutate(
                  asFormulaMutation(
                    { field: "options.waitTimes", type: "number" },
                    when(v, toVarReference)
                  )
                )
              }
            >
              <TextInput
                value={when(waitTimesValue.number, String) || "0"}
                updateOn="change"
                onChange={(v) => handleChange({ waitTimes: parseInt(v, 10) })}
                inputType="number"
              />
            </WithVariableSelect>
          </div>
          <FillSpace direction="horizontal">
            <ButtonGroup fit="container">
              {map(["hour", "day", "week", "month"], (period) => (
                <SplitButton
                  key={period}
                  size="small"
                  fit="container"
                  selected={step.options?.waitPeriod === period}
                  onClick={() => handleChange({ waitPeriod: period })}
                >
                  {plural(
                    titleCase(period),
                    when(step.options?.waitTimes, Number) ?? 2
                  )}
                </SplitButton>
              ))}
            </ButtonGroup>
          </FillSpace>
        </SpaceBetween>
      </Field>
    </>
  );
};

const WorkflowStepEditorControl = ({ step }: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);

  return (
    <>
      <Field label="Required paths to complete">
        <FillSpace>
          <ButtonGroup fit="container">
            <SplitButton
              size="small"
              fit="container"
              selected={!!step.options?.all}
              onClick={() =>
                mutate([
                  asMutation({ field: "options.all", type: "boolean" }, true),
                  asMutation({ field: "name", type: "text" }, "Wait All"),
                ])
              }
            >
              All
            </SplitButton>

            <SplitButton
              size="small"
              fit="container"
              selected={
                !step.options?.all && (step.options?.atLeast || 1) === 1
              }
              onClick={() =>
                mutate([
                  asMutation({ field: "options.all", type: "boolean" }, false),
                  asMutation({ field: "name", type: "text" }, "Wait Any"),
                ])
              }
            >
              Any
            </SplitButton>
          </ButtonGroup>
        </FillSpace>
      </Field>
    </>
  );
};

const WorkflowStepEditorCondition = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);
  const variables = useVariables(workflow, allSteps);
  const [targetVal, targetVariable] = useReferencedVariable(
    safeAs<string>(step.options?.target),
    variables,
    "relation"
  );
  const target = useLazyEntity(targetVal.relation?.id);

  const filteringOnType = useMemo(() => {
    if (target?.source.type === "workflow_step") {
      return safeAs<WorkflowStep>(target)?.options?.entity as Maybe<EntityType>;
    }

    if (targetVariable) {
      return justOne(targetVariable?.options?.references);
    }

    return target?.source.type;
  }, [targetVal, targetVariable]);
  const filterSource = useMemo(
    () =>
      filteringOnType
        ? ({ type: filteringOnType, scope: step.source.scope } as DatabaseID)
        : undefined,
    [filteringOnType, step.source.scope]
  );
  const props = useLazyProperties(filterSource);
  const getProp = useMemo(() => maybeLookup(props, (p) => p.field), [props]);

  const filter = useMemo((): FilterQuery => {
    if (!!targetVariable && !isAnyRelation(targetVariable)) {
      return (
        safeAs<FilterQuery>(
          getPropertyValue(step, { field: "options.filter", type: "json" }).json
        ) || {
          field: targetVariable.field,
          type: targetVariable.type,
          op: "equals",
        }
      );
    }

    return (
      safeAs<FilterQuery>(
        getPropertyValue(step, { field: "options.filter", type: "json" }).json
      ) || { and: [] }
    );
  }, [step.options?.filter]);

  return (
    <>
      <Field label="Check if work">
        <WithVariableSelect
          value={targetVariable}
          options={variables}
          onChange={(v) =>
            mutate([
              // Clear out filter so default chosen above
              asMutation({ field: "options.filter", type: "json" }, undefined),
              // Set the target
              asFormulaMutation(
                { field: "options.target", type: "relation" },
                when(v, toVarReference)
              ),
            ])
          }
        >
          <GlobalEntitySelect
            value={targetVal.relation}
            type={"workflow_step"}
            className={{ trigger: styles.control }}
            allowed={["workflow", "workflow_step"]}
            templates={!!workflow.template}
            scope={workflow.source.scope}
            showOtherTeams={false}
            onChange={(v) =>
              mutate(
                asMutation(
                  { field: "options.target", type: "relation" },
                  toRef(v?.id)
                )
              )
            }
          />
        </WithVariableSelect>
      </Field>

      {!!targetVariable && !isAnyRelation(targetVariable) && (
        <Field label="Passes condition">
          <Menu className={cx(styles.control, styles.filterMenu)}>
            <NestedPropertyFilter
              filter={filter}
              definition={(_) => safeAs<PropertyDef>(targetVariable)}
              source={undefined}
              onChanged={(f) =>
                mutate(
                  asMutation(
                    { field: "options.filter", type: "json" },
                    f as Maybe<JsonObject>
                  )
                )
              }
            />
          </Menu>
        </Field>
      )}

      {filterSource && (
        <Field label="Passes condition">
          <Menu className={cx(styles.control, styles.filterMenu)}>
            <NestedPropertyFilter
              filter={filter}
              definition={getProp}
              onChanged={(f) =>
                mutate(
                  asMutation(
                    { field: "options.filter", type: "json" },
                    f as Maybe<JsonObject>
                  )
                )
              }
              source={filterSource}
            />
          </Menu>
        </Field>
      )}

      {step.options?.filter && (
        <Field>
          <CheckMenuItem
            checked={safeAs<boolean>(step.options?.keepChecking) ?? false}
            text="Keep checking"
            onChecked={(v) =>
              mutate(
                asMutation(
                  { field: "options.keepChecking", type: "boolean" },
                  v
                )
              )
            }
          />
        </Field>
      )}
    </>
  );
};

const WorkflowStepEditorUpdate = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);
  const variables = useVariables(workflow, allSteps);
  const mutateOverride = useMutateOverride(step, mutate);

  const [sourceVal, sourceVariable] = useReferencedVariable(
    safeAs<string>(step.options?.target),
    variables,
    "relation"
  );
  const target = useLazyEntity(sourceVal.relation?.id);

  const targetType = useMemo(() => {
    if (target?.source.type === "workflow_step") {
      return safeAs<WorkflowStep>(target)?.options?.entity as Maybe<EntityType>;
    }

    if (sourceVariable) {
      return justOne(sourceVariable?.options?.references);
    }

    return target?.source.type;
  }, [sourceVal, sourceVariable]);

  const targetSource = useMemo(
    () =>
      targetType
        ? ({ type: targetType, scope: step.source.scope } as DatabaseID)
        : undefined,
    [targetType, step.source.scope]
  );
  const properties = useLazyProperties(targetSource);

  const bodyProp = useMemo(
    () => find(properties, { field: "body" }),
    [properties]
  );
  const body = useMemo(
    () =>
      find(step.overrides, { field: "body" })?.value.rich_text || { html: "" },
    [step.overrides]
  );

  return (
    <>
      <Field label="Work to be updated">
        <WithVariableSelect
          value={sourceVariable}
          allowed={["relation", "relations"]}
          options={variables}
          onChange={(v) =>
            mutate(
              asFormulaMutation(
                { field: "options.target", type: "relation" },
                when(v, toVarReference)
              )
            )
          }
        >
          <GlobalEntitySelect
            value={sourceVal.relation}
            className={{ trigger: styles.control }}
            allowed={"*"}
            scope={workflow.source.scope}
            showOtherTeams={false}
            onChange={(v) =>
              mutate(
                asMutation(
                  { field: "options.target", type: "relation" },
                  toRef(v?.id)
                )
              )
            }
          />
        </WithVariableSelect>
      </Field>

      {targetSource && (
        <Field label="Fields to update">
          <TemplateConfigure
            template={undefined}
            overrides={safeAs<PropertyValueRef[]>(step.overrides)}
            field="overrides"
            blacklist={["body"]}
            showPicker={false}
            variables={variables}
            source={targetSource}
            onChange={mutate}
          />
        </Field>
      )}

      {bodyProp && targetSource && (
        <Field
          label={`Append to ${(bodyProp?.label || "body").toLowerCase()}`}
          padded
        >
          <DocumentEditor
            placeholder="Start typing..."
            newLineSpace="large"
            scope={targetSource.scope}
            variables={variables}
            content={body}
            onChanged={(rt) =>
              mutateOverride(
                asAppendMutation({ field: "body", type: "rich_text" }, rt)
              )
            }
          />
        </Field>
      )}
    </>
  );
};

const WorkflowStepEditorFind = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);
  const findingType = useMemo(
    () => safeAs<EntityType>(step.options?.entity),
    [step?.options?.entity]
  );
  const variables = useVariables(workflow, allSteps);

  const [filterWithinValue, filterVariable] = useReferencedVariable(
    safeAs<string>(step.options?.within),
    variables
  );

  const filterSource = useMemo(
    () =>
      findingType
        ? ({
            type: findingType,
            scope: filterWithinValue.text || toBaseScope(step.source.scope),
          } as DatabaseID)
        : undefined,
    [findingType, filterWithinValue, step.source.scope]
  );
  const props = useFilterableProperties(filterSource);
  const getProp = useMemo(() => maybeLookup(props, (p) => p.field), [props]);

  const filter = useMemo(
    () =>
      safeAs<FilterQuery>(
        getPropertyValue(step, { field: "options.filter", type: "json" }).json
      ) || { and: [] },
    [step.options?.filter]
  );

  const outputVariable = useMemo(() => step.outputs?.[0], [step.outputs]);
  const toOutputMutation = useCallback(
    (
      changes: Partial<{
        field: string;
        type: "relation" | "relations";
        references: EntityType;
      }>
    ) =>
      asMutation({ field: "outputs", type: "json" }, [
        {
          field:
            changes.field ||
            step.outputs?.[0]?.field ||
            step.options?.entity ||
            "default",
          type:
            changes?.type ||
            step.outputs?.[0]?.type ||
            (step.options?.limit === 1 ? "relation" : "relations"),
          options: {
            references:
              changes.references ||
              step.outputs?.[0]?.options?.references ||
              step.options?.entity,
          },
        },
      ]),
    [step.outputs, step.options?.entity]
  );

  useEffect(() => {
    if (!step.outputs?.length) {
      mutate(toOutputMutation({}));
    }
  }, []);

  return (
    <>
      <Field label="Find">
        <EntityTypeSelect
          value={step.options?.entity as Maybe<EntityType>}
          scope={step.source.scope}
          additional={useConst(["note"])}
          plural={false}
          onChange={(v) =>
            mutate(asMutation({ field: "options.entity", type: "text" }, v))
          }
        />
      </Field>

      {filterSource && (
        <>
          <Field label="Located in">
            <WithVariableSelect
              value={filterVariable}
              options={variables}
              allowed={["relation"]}
              onChange={(v) =>
                v &&
                mutate(
                  asMutation(
                    { field: "options.within", type: "text" },
                    toVarReference(v)
                  )
                )
              }
            >
              <LocationSelect
                className={{ trigger: styles.control }}
                location={filterSource?.scope}
                onChange={(v) =>
                  mutate(
                    asMutation({ field: "options.within", type: "text" }, v)
                  )
                }
              />
            </WithVariableSelect>
          </Field>

          <Field label="Matching filter">
            <Menu className={cx(styles.control, styles.filterMenu)}>
              <NestedPropertyFilter
                filter={filter}
                definition={getProp}
                onChanged={(f) =>
                  mutate(
                    asMutation(
                      { field: "options.filter", type: "json" },
                      f as Maybe<JsonObject>
                    )
                  )
                }
                source={filterSource}
              />
            </Menu>
          </Field>
        </>
      )}

      <Field label="Limit to">
        {/* Field > * css causing some issues with button immediately beneath */}
        <div>
          <ButtonGroup fit="container">
            <SplitButton
              selected={step.options?.limit === 1}
              onClick={() =>
                mutate([
                  asMutation({ field: "options.limit", type: "number" }, 1),
                  toOutputMutation({ type: "relation" }),
                ])
              }
            >
              One
            </SplitButton>
            <SplitButton
              selected={step.options?.limit !== 1}
              onClick={() =>
                mutate([
                  asMutation({ field: "options.limit", type: "number" }, 1000),
                  toOutputMutation({ type: "relation" }),
                ])
              }
            >
              Multiple
            </SplitButton>
          </ButtonGroup>
        </div>
      </Field>

      {step.options?.limit === 1 && (
        <Field label="Sort by">
          {/* TODO: Refactor SortByOptionsMenu from view-options-menu so that it can be reused here...  */}
          <Button size="small">...</Button>
        </Field>
      )}

      <Field label={"Save as variable"}>
        {!!outputVariable && (
          <TextInput
            value={outputVariable.field ? `@${outputVariable.field}` : ""}
            updateOn="blur"
            placeholder="@"
            onChange={(t) =>
              mutate(toOutputMutation({ field: t?.replace(/^@/, "") }))
            }
          />
        )}
      </Field>
    </>
  );
};

const WorkflowStepEditorAI = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);
  const mutateWorkflow = useUpdateEntity(workflow.id);
  const variables = useVariables(workflow, allSteps);

  return (
    <VStack gap={20}>
      <Field label="Prompt">
        <ColoredSection>
          <DocumentEditor
            newLineSpace="large"
            autoFocus={!step?.options?.prompt}
            placeholder="Write instructions for AI to follow..."
            scope={step.source.scope}
            content={
              getPropertyValue(step, {
                field: "options.prompt",
                type: "rich_text",
              })?.rich_text
            }
            onChanged={(c) =>
              mutate(
                asMutation({ field: "options.prompt", type: "rich_text" }, c)
              )
            }
            variables={variables}
            onNewVariable={(v) =>
              mutateWorkflow(
                asMutation(
                  { field: "inputs", type: "json" },
                  safeAs<JsonArray>([...(workflow.inputs || []), v])
                )
              )
            }
          />
        </ColoredSection>
      </Field>

      <Field label="Outputs" help="Variables you want AI to fill out.">
        <VariableList
          variables={step.outputs}
          scope={step.source.scope}
          onChanged={(vars) =>
            mutate(
              asMutation(
                { field: "outputs", type: "json" },
                safeAs<JsonArray>(vars)
              )
            )
          }
        />
      </Field>
    </VStack>
  );
};

const WorkflowStepEditorSetVar = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const [adding, setAdding] = useState(false);
  const mutate = useUpdateEntity(step.id);
  const mutateWorkflow = useUpdateEntity(workflow.id);
  const variables = useVariables(workflow, allSteps);
  const isExisting = useMemo(() => {
    const lookup = maybeLookup(step.outputs || [], (v) => v.field);
    return (field: string) => !!lookup(field);
  }, [step.outputs]);
  const variableOptions = useMemo(
    () =>
      maybeMap(variables, (v) =>
        v.system || isExisting(v.field)
          ? undefined
          : {
              id: v.field,
              name: v.field,
              var: v,
              icon: <PropertyTypeIcon {...v} />,
            }
      ),
    [variables, isExisting]
  );

  const handleAddVariable = useCallback(
    (v: Maybe<VariableDef>) => {
      mutate(
        asMutation({ field: "outputs", type: "json" }, [
          ...(step.outputs || []),
          v
            ? { ...v, value: {} }
            : { field: "", type: "text", value: { text: "" } },
        ])
      );
    },
    [step.outputs]
  );

  const handleRemoveVariable = useCallback(
    (v: VariableDef) => {
      mutate(
        asMutation({ field: "outputs", type: "json" }, [
          ...filter(step.outputs || [], (s) => s.field !== v.field),
        ])
      );
    },
    [step.outputs]
  );

  const handleSetValue = useCallback(
    (v: VariableDef, value: PropertyValueType) => {
      mutate(
        asMutation({ field: "outputs", type: "json" }, [
          ...map(step.outputs || [], (s) =>
            s.field === v.field ? { ...s, value } : s
          ),
        ])
      );
    },
    [step.outputs]
  );

  return (
    <VStack gap={20}>
      {adding && (
        <EditVariableModal
          defaults={{ type: "text" }}
          scope={step.source.scope}
          onClose={() => setAdding(false)}
          onSave={(v) => handleAddVariable(v)}
        />
      )}

      <Field padded={true} label="Variables" help="Set one or more variables.">
        <VStack>
          {map(step.outputs, (v, i) => (
            <ContextMenu
              key={v.field}
              actions={
                <ContextItem
                  icon={TimesIcon}
                  text="Remove"
                  onClick={() => handleRemoveVariable(v)}
                />
              }
            >
              <Card>
                <SpaceBetween className={styles.variableRow}>
                  <Text>{v.field}</Text>
                  <span>=</span>
                  <div>
                    <PropertyValue
                      fit="container"
                      valueRef={logThrough(
                        asPropertyValueRef(v, v.value),
                        "value ref"
                      )}
                      source={step.source}
                      onChange={(val) => handleSetValue(v, val)}
                    />
                  </div>
                </SpaceBetween>
              </Card>
            </ContextMenu>
          ))}

          <HStack>
            <Select
              placeholder="Choose option"
              options={variableOptions}
              value={undefined}
              onChange={(v) => handleAddVariable(v?.var)}
            >
              <Button subtle size="small" inset={true} icon={PlusIcon}>
                <Text subtle>Set existing</Text>
              </Button>
            </Select>

            <Button
              subtle
              size="small"
              inset={true}
              icon={PlusIcon}
              onClick={() => setAdding(true)}
            >
              <Text subtle>New variable</Text>
            </Button>
          </HStack>
        </VStack>
      </Field>
    </VStack>
  );
};

const WorkflowStepEditorMessage = ({
  step,
  workflow,
  allSteps,
}: StepEditorProps) => {
  const mutate = useUpdateEntity(step.id);
  const variables = useVariables(workflow, without(allSteps, step));

  const outputVariable = useMemo(() => step.outputs?.[0], [step.outputs]);
  const [threadValue, threadVariable] = useReferencedVariable(
    safeAs<string>(step.options?.thread),
    variables
  );
  const [fromValue, fromVariable] = useReferencedVariable(
    safeAs<string>(step.options?.from),
    variables,
    "relation"
  );
  const handleToggleOutputVariable = useCallback(
    (output: boolean) => {
      mutate(
        asMutation(
          { field: "outputs", type: "json" },
          output
            ? [
                {
                  field: "message",
                  type: "relation",
                  options: { references: "note" },
                },
              ]
            : []
        )
      );
    },
    [step.outputs, step.options?.entity]
  );

  const [channel, setChannel] = useState(safeAs<string>(step.options?.channel));
  const [thread, setThread] = useState<Maybe<string>>(
    threadValue?.text || undefined
  );

  const setChannelOptions = useCallback(
    ({ channel, thread }: { channel?: string; thread?: string }) => {
      setChannel(channel);
      setThread(thread);

      mutate(
        omitFalsey([
          channel
            ? asMutation({ field: "options.channel", type: "text" }, channel)
            : undefined,
          thread
            ? asMutation({ field: "options.thread", type: "text" }, thread)
            : undefined,
        ])
      );
    },
    [channel, thread]
  );

  return (
    <VStack gap={20}>
      <Field label="Message">
        <ColoredSection>
          <DocumentEditor
            variables={variables}
            newLineSpace="small"
            autoFocus={!step?.options?.prompt}
            placeholder="Write your message..."
            content={
              getPropertyValue(step, {
                field: "options.message",
                type: "rich_text",
              })?.rich_text
            }
            onChanged={(c) =>
              mutate(
                asMutation({ field: "options.message", type: "rich_text" }, c)
              )
            }
          />
        </ColoredSection>
      </Field>

      <Field label="From">
        <WithVariableSelect
          value={fromVariable}
          allowed={["relation"]}
          options={variables}
          onChange={(v) =>
            mutate(
              asFormulaMutation(
                { field: "options.from", type: "relation" },
                when(v, toVarReference)
              )
            )
          }
        >
          <GlobalEntitySelect
            value={fromValue.relation}
            className={styles.control}
            placeholder="System"
            type="person"
            allowed={["person"]}
            onChange={(v) =>
              mutate(
                asMutation(
                  { field: "options.from", type: "relation" },
                  toRef(v)
                )
              )
            }
          />
        </WithVariableSelect>
      </Field>

      <Field label="Send via">
        <HStack>
          <ButtonGroup fit="container">
            <SplitButton
              fit="container"
              size="small"
              selected={step.options?.via === "email"}
              onClick={
                () => showError("Coming soon...")
                // mutate(
                //   asMutation({ field: "options.via", type: "text" }, "email")
                // )
              }
            >
              Email
            </SplitButton>
            <SplitButton
              fit="container"
              size="small"
              selected={step.options?.via === "slack"}
              onClick={() =>
                mutate(
                  asMutation({ field: "options.via", type: "text" }, "slack")
                )
              }
            >
              Slack
            </SplitButton>
            <SplitButton
              fit="container"
              size="small"
              selected={step.options?.via === "sms"}
              onClick={
                () => showError("Coming soon...")
                // mutate(
                //   asMutation({ field: "options.via", type: "text" }, "sms")
                // )
              }
            >
              SMS
            </SplitButton>
          </ButtonGroup>
        </HStack>
      </Field>

      {step.options?.via === "slack" && (
        <Field label="Channel">
          <WithVariableSelect
            value={threadVariable}
            allowed={["relation"]}
            options={variables}
            onChange={(v) =>
              mutate(
                asFormulaMutation(
                  { field: "options.thread", type: "text" },
                  when(v, toVarReference)
                )
              )
            }
          >
            <SlackSelect
              className={styles.control}
              position="bottom-right"
              channel={channel}
              thread={thread}
              mode="thread"
              placeholder="Select a channel..."
              onChange={(c, t) => setChannelOptions({ channel: c, thread: t })}
            />
          </WithVariableSelect>
        </Field>
      )}

      <Field>
        <CheckMenuItem
          inset
          checked={!!safeAs<boolean>(step.outputs?.length)}
          onChecked={handleToggleOutputVariable}
          text="Save as variable"
        />

        {!!outputVariable && (
          <TextInput
            value={outputVariable.field}
            updateOn="blur"
            placeholder="Variable to save to..."
            onChange={(t) =>
              mutate(
                asMutation(
                  { field: "outputs", type: "json" },
                  safeAs<JsonArray>([{ ...outputVariable, field: t }])
                )
              )
            }
          />
        )}
      </Field>
    </VStack>
  );
};

const CreatedWorkPreview = ({
  work,
  onOpen,
}: {
  work: Ref;
  onOpen: Fn<Maybe<Ref>, void>;
}) => {
  const entity = useLazyEntity(work.id);
  const blacklist = useConst(["refs.workflows", "refs.fromWorkflow"]);

  if (entity) {
    return (
      <EntityPreview
        entity={entity}
        onOpen={onOpen}
        propBlacklist={blacklist}
      />
    );
  }

  return <RelationLabel relation={work} onClick={() => onOpen(work)} />;
};

const useReferencedVariable = (
  value: Maybe<string | number>,
  variables: VariableDef[],
  type?: PropertyType
) => {
  const valueRef = useMemo(() => asFormulaValue(value, type), [value]);
  const variable = useMemo(
    () =>
      when(
        valueRef?.formula && extractVarReference(valueRef.formula),
        (field) => find(variables, { field })
      ),
    [variables, valueRef]
  );
  return [valueRef, variable] as const;
};

const AddNextStep = ({
  workflow,
  steps,
  mutate,
}: {
  workflow: Workflow;
  steps: Maybe<WorkflowStep[]>;
  mutate: Fn<Update<Entity>[], void>;
}) => {
  const [input, setInput] = useState<RichText>({ html: "" });
  const [focused, setFocused] = useState(false);
  const stepSource = useNestedSource(workflow, "workflow_step");
  const stepProps = useLazyProperties(stepSource);

  const ai = useAiUseCase(workflowStepCreate);
  const createNextStep = useCallback(
    async (p: Maybe<RichText>) => {
      if (!p || isEmpty(p)) {
        showError("Please write what you want to happen next in the workflow.");
        return;
      }

      try {
        const result = await ai.run({
          workflow,
          steps: steps || [],
          prompt: p.html || "",
        });
        mutate(
          map(result, (a) =>
            aiStepToUpdate(workflow, a, stepProps)
          ) as Update<Entity>[]
        );
      } catch (e) {
        showError("Failed to add next step.");
      }
    },
    [ai.run, workflow, steps]
  );

  return (
    <div className={cx(styles.floatingInput, focused && styles.large)}>
      <HStack gap={0}>
        <Icon icon={ai.loading ? SpinnerIcon : Magic} />

        <TextBox
          key="workflow-next-step"
          className={styles.input}
          text={input}
          onChanged={setInput}
          placeholder="What should happen next?"
          submitOnEnter
          blurOnEnter
          onEnter={createNextStep}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </HStack>
    </div>
  );
};

const useMutateOverride = (
  step: WorkflowStep,
  mutate: Fn<OneOrMany<PropertyMutation>, void>
) => {
  return useCallback(
    (change: OneOrMany<PropertyMutation>) => {
      mutate(
        omitFalsey([
          asMutation(
            { field: "overrides", type: "json" },
            safeAs<JsonArray>(
              uniqBy(
                [
                  ...(step.overrides || []),
                  ...map(ensureMany(change), (c) =>
                    pick(c, "field", "value", "type")
                  ),
                ],
                (c) => c.field,
                "last"
              )
            )
          ),
        ])
      );
    },
    [step.overrides, step.id]
  );
};
