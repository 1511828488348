import { View } from "@api";

import { useGetPackage, usePackageIcon } from "@state/packages";
import { toShortTitle, useToViewTitle } from "@state/views";

import { withHandle } from "@utils/event";

import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Icon, ViewIcon } from "@ui/icon";
import { Label } from "@ui/label";
import { MenuItem } from "@ui/menu-item";
import { SelectableListCard, SelectableListItem } from "@ui/selectable-items";

import { UIEngine } from "../types";
import { ViewCreateDialog } from ".";

import styles from "./styles.module.css";

export { ViewCreateDialog } from "./create-dialog";

export const ViewsEngine: UIEngine<View> = {
  asMenuItem: function ({ item, icon, onOpen, ...rest }) {
    const getPackage = useGetPackage();
    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          {...rest}
          onClick={withHandle(() => onOpen?.(item))}
          text={item.name || toShortTitle(item)}
          icon={
            getPackage(item.entity)?.icon || <ViewIcon layout={item.layout} />
          }
        />
      </EntityContextMenu>
    );
  },
  asListCard: function EntityListCard(props) {
    const { item, selection, setSelection } = props;
    const toShort = useToViewTitle(item);
    return (
      <EntityContextMenu
        entity={item}
        selection={selection}
        setSelection={setSelection}
      >
        <SelectableListCard {...props}>
          <VStack className={styles.bar}>
            <HStack>
              <Icon icon={<ViewIcon layout={item.layout} />} />
              <Label className={styles.title}>{toShort(item)}</Label>
            </HStack>
          </VStack>
        </SelectableListCard>
      </EntityContextMenu>
    );
  },
  asListItem: function EntityListItem(props) {
    const { item } = props;
    const toShort = useToViewTitle(item);
    return (
      <EntityContextMenu
        entity={item}
        selection={props.selection}
        setSelection={props.setSelection}
      >
        <SelectableListItem {...props}>
          <SpaceBetween className={styles.upper}>
            <HStack className={styles.middle} gap={4}>
              <Icon icon={<ViewIcon layout={item.layout} />} />
              <Label className={styles.title}>{toShort(item)}</Label>
            </HStack>
          </SpaceBetween>
        </SelectableListItem>
      </EntityContextMenu>
    );
  },
  asCreateDialog: ViewCreateDialog,
};
