import { EntityType } from "@api";

import { equalsAny } from "@utils/logic";

import { Package } from "./definitions";

export const isPackageId = (id: string) => id.startsWith("pkg_");

export const toPackageName = (type: EntityType) => `pkg_${type}`;

export const toPackageGroup = (pkg: Package) => {
  if (!pkg.entity) {
    return "other";
  }

  if (equalsAny(pkg.entity, ["task", "page", "meeting"])) {
    return "core";
  }

  if (pkg.tags?.includes("organise")) {
    return "organise";
  }

  return "data";
};
