import { subMinutes } from "date-fns";
import {
  filter,
  find,
  flatMap,
  isEqual,
  map,
  omit,
  orderBy,
  reduce,
  some,
  without,
} from "lodash";
import { pick } from "lodash/fp";
import { useCallback, useMemo, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getRecoil, setRecoil } from "recoil-nexus";

import {
  createPropertyDef,
  DatabaseID,
  deletePropertyDef,
  DisplayAs,
  Entity,
  EntityType,
  getPropertyDefinition,
  HasSettings,
  ID,
  PropertyDef,
  PropertyDefChanges,
  PropertyRef,
  PropertyType,
  PropertyValueRef,
  PropertyVisibility,
  updatePropertyDef,
} from "@api";
import { EntityForType } from "@api/mappings";

import {
  FetchResultsAtom,
  FetchResultsStoreAtom,
  GlobalFetchOptionsAtom,
  setFetchResults,
  toFetchResultKey,
} from "@state/fetch-results";
import {
  GenericItem,
  toNestedTypes,
  useGetItemFromAnyStore,
} from "@state/generic";
import { useInstalledEntities } from "@state/packages";
import { useEntitySettings } from "@state/settings";
import { modifySimpleStoreState } from "@state/store";
import { useActiveWorkspaceId } from "@state/workspace";

import { ensureMany, pushDirty, uniqBy } from "@utils/array";
import { toPointDate, usePointDate } from "@utils/date-fp";
import { addInfo, log } from "@utils/debug";
import { useAsyncEffect } from "@utils/effects";
import { composel, isFunc, Pred } from "@utils/fn";
import { isLocalID } from "@utils/id";
import { Maybe, maybeMap, safeAs, when } from "@utils/maybe";
import { now } from "@utils/now";
import { mapAll, mapMost } from "@utils/promise";
import {
  asPropertyValueRef,
  hasValue,
  inflateValue,
  isAnyRelation,
  isEditableProp,
  isEmptyRef,
  isPropertyDef,
  isVariableDef,
  toPropertyValueRef,
} from "@utils/property-refs";
import { useArrayKey } from "@utils/react";
import { fromScope, toBaseScope } from "@utils/scope";
import { hashable } from "@utils/serializable";

import {
  addPropertyDefinition,
  addPropertyValues,
  removePropertyDefinition,
  replacePropertyDefinition,
  setPropertyDefinition,
  setPropertyDefinitions,
} from "./actions";
import { PropertyDefAtom, PropertyDefStoreAtom } from "./atoms";
import {
  getPropertyDefinitionLoader,
  getPropertyDefinitionsLoader,
  getPropertyValuesLoader,
} from "./queries";
import { allPropertiesForSource } from "./selectors";
import { getPropertyDef, toFetchKey, toPropertyDefID } from "./utils";

// Should be used sparingly, only needed in settings packages when you NEED to have every single relationship fetched
export function useFetchAllEntityProperties(scope: ID) {
  const setProps = useSetRecoilState(PropertyDefStoreAtom);
  const [resultsStore, setResultsStore] = useRecoilState(FetchResultsStoreAtom);
  const baseScope = useMemo(() => toBaseScope(scope), [scope]);
  const installed = useInstalledEntities(baseScope);
  const { archived } = useRecoilValue(GlobalFetchOptionsAtom);

  // Fetch prop defs from the api that were updated
  useAsyncEffect(async () => {
    await mapMost(installed, async (type) => {
      const source = { type, scope: baseScope };
      const key = toFetchKey(source);
      const results = resultsStore.lookup[key];
      const lastFetched = results?.fetchedAt;

      // Can reduce fetching here by not re-fetching for a longer period of time using above results.fetchedAt
      // however will then need some sort of cache invalidation strategy
      if (
        source ||
        (!!lastFetched &&
          usePointDate(lastFetched, (lf) => lf < subMinutes(now(), 1)))
      ) {
        await getPropertyDefinitionsLoader(source, lastFetched, (defs) => {
          if (defs?.length) {
            setProps(setPropertyDefinitions(defs));
          }

          setResultsStore(
            modifySimpleStoreState(
              toFetchResultKey(key, archived),
              setFetchResults(maybeMap(defs, (d) => toPropertyDefID(d)))
            )
          );
        });
      }
    });
  }, [installed, scope]);
}

export function useGetAnyProperty() {
  const wID = useActiveWorkspaceId();
  const propStore = useRecoilValue(PropertyDefStoreAtom);

  return useCallback(
    (prop: PropertyRef, source: DatabaseID) => {
      return (
        getPropertyDef(propStore, source, prop) ||
        getPropertyDef(propStore, { type: source.type, scope: wID }, prop)
      );
    },
    [propStore]
  );
}

export function useInflateStatus() {
  const getProp = useGetAnyProperty();
  return useCallback(
    (status: Maybe<ID>, source: DatabaseID) => {
      return find(
        getProp({ type: "status", field: "status" }, source)?.values?.status,
        (s) => s.id === status
      );
    },
    [getProp]
  );
}

export function useLazyProperties<
  T extends EntityType,
  E extends Entity = EntityForType<T>
>(source: Maybe<DatabaseID<T>>, fetch: boolean = true) {
  const setProps = useSetRecoilState(PropertyDefStoreAtom);
  const baseScope = useMemo(
    () => when(source?.scope, toBaseScope),
    [source?.scope]
  );
  const key = useMemo(() => (source ? toFetchKey(source) : ""), [source]);
  const [results, setResults] = useRecoilState(FetchResultsAtom(key));
  const defs = useRecoilValue(
    allPropertiesForSource(
      when(source, composel(pick(["type", "scope"]), hashable))
    )
  ) as PropertyDef<E>[];

  // Fetch prop defs from the api that were updated
  useAsyncEffect(async () => {
    // Can reduce fetching here by not re-fetching for a longer period of time using above results.fetchedAt
    // however will then need some sort of cache invalidation strategy
    if (source && baseScope && (fetch || defs?.length <= 1)) {
      await getPropertyDefinitionsLoader(
        { type: source.type, scope: baseScope },
        results?.fetchedAt,

        // Once callback
        (defs) => {
          if (defs?.length) {
            setProps(setPropertyDefinitions(defs));
          }

          const ids = map(defs, (d) => toPropertyDefID(d));

          if (!isEqual(ids, results?.ids) || !!defs?.length) {
            // IDs will not be accurate because we only fetch changed
            setResults({
              ids: ids,
              fetchedAt: toPointDate(now()),
            });
          }
        }
      );
    }
  }, [fetch, source?.type, source?.scope]);

  return source ? defs : [];
}

// Non-lazy version (always ensures has some)
export function useProperties<
  T extends EntityType,
  E extends Entity = EntityForType<T>
>(source: Maybe<DatabaseID<T>>) {
  const props = useLazyProperties<T, E>(source, true);
  return useMemo(() => ({ props, ready: props.length > 1 }), [props]);
}

// Fetched properties for all nested entity types
export function useNestedProperties<
  T extends EntityType,
  E extends Entity = EntityForType<T>
>(source: Maybe<DatabaseID<T>>) {
  const props = useLazyProperties<T, E>(source, true);
  const propsDepKey = useArrayKey(props || [], (p) => p.field + p.type);
  const childTypes = useMemo(() => toNestedTypes(props), [propsDepKey]);
  const [childPropsLoaded, setChildPropsLoaded] = useState(false);

  const ready = useMemo(
    () => !!props.length && !!childTypes?.length && !!childPropsLoaded,
    [propsDepKey, childTypes, childPropsLoaded]
  );

  // Fetch prop defs from the api that were updated
  useAsyncEffect(async () => {
    if (!source) {
      return;
    }

    if (!childTypes?.length) {
      return;
    }

    setChildPropsLoaded(false);

    try {
      await mapAll(childTypes, async (type) => {
        const childSource = { type: type, scope: source.scope };
        const fetchResultsKey = toFetchKey(childSource);
        const Atom = FetchResultsAtom(fetchResultsKey);

        await getPropertyDefinitionsLoader(
          childSource,
          getRecoil(Atom)?.fetchedAt,
          (defs) => {
            if (defs?.length) {
              setRecoil(PropertyDefStoreAtom, setPropertyDefinitions(defs));
            }

            const ids = map(defs, (d) => toPropertyDefID(d));

            if (!isEqual(ids, getRecoil(Atom)?.ids) || !!defs?.length) {
              setRecoil(Atom, {
                ids,
                fetchedAt: toPointDate(now()),
              });
            }
          }
        );
      });
      setChildPropsLoaded(true);
    } catch (e) {
      addInfo({ childTypes, source });
      log(e);
    }
  }, [source?.scope, source?.type, useArrayKey(childTypes)]);

  return useMemo(() => ({ props, ready }), [props, ready]);
}

export const useLazyPropertyDef = <E extends Entity, T extends PropertyType>(
  source: Maybe<DatabaseID>,
  prop: Maybe<PropertyRef<E, T> | PropertyValueRef<E, T>>
): Maybe<PropertyDef<E, T>> => {
  const propKey = useMemo(
    () => (source && prop ? toPropertyDefID(source, prop) : ""),
    [source?.type, source?.scope, prop?.field, prop?.type]
  );
  const storeDef = useRecoilValue(PropertyDefAtom(propKey));
  const inboundDef =
    safeAs<PropertyValueRef<E, T>>(prop)?.def ||
    (isPropertyDef(prop) ? prop : undefined);

  // Always use variable defs if passed in
  const def = isVariableDef(inboundDef) ? inboundDef : storeDef || inboundDef;

  const setProps = useSetRecoilState(PropertyDefStoreAtom);

  // Fetch when no local def
  useAsyncEffect(async () => {
    if (
      !def &&
      prop &&
      source &&
      source?.scope &&
      source?.scope !== "never" &&
      !isVariableDef(def)
    ) {
      await getPropertyDefinitionLoader(
        source,
        prop,
        composel(setPropertyDefinition, setProps)
      );
    }
  }, [source?.type, source?.scope, prop?.field, prop?.type]);

  return def as Maybe<PropertyDef<E, T>>;
};

export const useUpdatePropertyDef = <E extends Entity, T extends PropertyType>(
  dbRef: Maybe<DatabaseID>
) => {
  const setProps = useSetRecoilState(PropertyDefStoreAtom);

  return useCallback(
    async (
      ref: Maybe<PropertyRef<E, T> | PropertyDef<E, T>>,
      changes: PropertyDefChanges<E, T>
    ) => {
      if (!ref || !dbRef) {
        return;
      }

      const newDef = await updatePropertyDef<E, T>(dbRef, ref, changes);

      if (!newDef) {
        throw new Error("Failed to update property definition.");
      }

      if (isPropertyDef(ref)) {
        setProps(replacePropertyDefinition(ref, newDef));
      } else {
        setProps(setPropertyDefinition(newDef));
      }

      return newDef;
    },
    [dbRef?.type, dbRef?.scope]
  );
};

export const useCreatePropertyDef = <E extends Entity, T extends PropertyType>(
  source?: Maybe<DatabaseID>
) => {
  const setProps = useSetRecoilState(PropertyDefStoreAtom);

  return useCallback(
    async (
      ref: PropertyRef<E, T>,
      def: Partial<PropertyDef<E, T>>,
      sourceOverride?: DatabaseID
    ) => {
      const finalSource = sourceOverride || source;

      if (!def || !finalSource) {
        return;
      }

      if (some(fromScope(finalSource.scope), isLocalID)) {
        throw new Error("Cannot create schema for unpersisted entities.");
      }

      const newDef = await createPropertyDef<E, T>(
        finalSource,
        { type: ref.type, field: ref.field },
        omit(def, "scope", "field", "type", "entity", "createdAt", "updatedAt")
      );

      if (!newDef) {
        throw new Error("Failed to update property definition.");
      }

      setProps(addPropertyDefinition(newDef));

      return newDef;
    },
    [source?.scope, source?.type]
  );
};

export const useDeletePropertyDef = <E extends Entity, T extends PropertyType>(
  source?: Maybe<DatabaseID>
) => {
  const setProps = useSetRecoilState(PropertyDefStoreAtom);

  return useCallback(
    async (def: PropertyDef<E, T>, sourceOverride?: DatabaseID) => {
      const finalSource = sourceOverride || source;

      if (!finalSource) {
        throw new Error("No source provided to delete property definition.");
      }

      await deletePropertyDef<E, T>(finalSource, {
        type: def.type,
        field: def.field,
      });

      setProps(removePropertyDefinition(def));
    },
    [source]
  );
};

export function useLazyGetPropertyValueRefs(id: ID) {
  const entity = useRecoilValue(GenericItem(id));
  const props = useLazyProperties(entity?.source, true);

  return useMemo(
    (): PropertyValueRef[] =>
      entity && props
        ? map(props || [], (def) => toPropertyValueRef(entity, def))
        : [],
    [props, entity]
  );
}

export const useGetAllPropertyValues = <
  E extends Entity,
  T extends PropertyType
>(
  source: Maybe<DatabaseID>,
  prop: PropertyRef<E, T>
) => {
  const def = useLazyPropertyDef<E, T>(source, prop);
  const setProps = useSetRecoilState(PropertyDefStoreAtom);

  const fetch = useCallback(async () => {
    if (!source || !def) {
      return;
    }

    switch (prop.type) {
      case "status":
      case "select":
      case "multi_select": {
        const values = await getPropertyValuesLoader(source, prop);
        setProps(addPropertyValues(def, values));
      }

      default:
        return;
    }
  }, [def, source]);

  return useMemo(
    () => ({ def, values: def?.values || {}, fetch }),
    [fetch, def]
  );
};

export const useLazyPropertyValues = <E extends Entity, T extends PropertyType>(
  source: Maybe<DatabaseID>,
  prop: Maybe<PropertyRef<E, T>>
) => {
  const def = useLazyPropertyDef<E, T>(source, prop);
  return def?.values || {};
};

export const useInflatedPropertyValue = <
  E extends Entity,
  T extends PropertyType
>(
  value: PropertyValueRef<E, T>,
  source: DatabaseID
) => {
  const once = useRef(false);
  const setProps = useSetRecoilState(PropertyDefStoreAtom);
  const def = useLazyPropertyDef<E, T>(source, value);
  const getItem = useGetItemFromAnyStore();

  const values = useMemo(() => {
    switch (value.type) {
      // Fill values with items from store
      case "relation":
        return {
          [value.type]: maybeMap(
            when(value.value?.relation, ensureMany) || [],
            (v) => getItem(v.id)
          ),
        };
      case "relations":
        return {
          [value.type]: maybeMap(value.value?.relations, (v) => getItem(v.id)),
        };

      // Return values from definition
      default:
        return def?.values;
    }
  }, [def?.values]);

  const inflated = useMemo(() => {
    return values ? inflateValue(value, values, def) : value;
  }, [value.value?.[value.type], values]);

  useAsyncEffect(async () => {
    // Is the current value missing in the property definition
    if (
      !once.current &&
      def &&
      !hasValue(value, def.values) &&
      !isAnyRelation(value)
    ) {
      once.current = true;
      // Don't use the loader since it will be cached...
      const latest = await getPropertyDefinition(source, value);
      setProps(setPropertyDefinition(latest));
    }
  }, [def?.values, inflated?.value?.[value.type], once]);

  return inflated;
};
export const useLazyPropertyValue = <E extends Entity, T extends PropertyType>(
  thing: Maybe<E>,
  prop: PropertyRef<E, T>
) => {
  if (!thing) {
    return asPropertyValueRef(prop, { [prop.type]: undefined });
  }

  return useInflatedPropertyValue(
    toPropertyValueRef(thing, prop),
    thing.source
  );
};

export const usePropertyValueRef = <E extends Entity, T extends PropertyType>(
  thing: Maybe<E>,
  prop: PropertyRef<E, T>
) => {
  if (!thing) {
    return asPropertyValueRef(prop, { [prop.type]: undefined });
  }

  return useMemo(
    () => toPropertyValueRef(thing, prop),
    [thing, prop.field, prop.type]
  );
};

export function useGetEditableProperties(
  source: Maybe<DatabaseID>,
  additional?: Pred<PropertyDef>
) {
  const props = useLazyProperties(source);

  return useMemo(
    () =>
      filter(props, (p) => isEditableProp(p) && (!additional || additional(p))),
    [props]
  );
}

export const useVisiblePropertyRefs = <T extends Entity>(
  item: T,
  showProps: Maybe<PropertyRef<T>[] | Pred<PropertyDef<T>>>,
  {
    blacklist = [],
    whitelist = [],
    hideEmpty,
  }: {
    blacklist?: PropertyRef<T>["field"][];
    whitelist?: PropertyRef<T>["field"][];
    hideEmpty?: boolean;
  }
) => {
  const unsorted = useLazyProperties(item.source);
  const allProps = useMemo(() => orderBy(unsorted, (p) => p.order), [unsorted]);
  return useMemo(
    () =>
      reduce(
        isFunc(showProps)
          ? filter(allProps, showProps)
          : maybeMap(showProps, (p) =>
              find(allProps, (pp) => pp.field === p.field)
            ) || [],
        (curr, def) => {
          // Always hidden in the UI
          if (blacklist?.includes(def.field)) {
            return curr;
          }

          if (!def) {
            return curr;
          }

          const val = toPropertyValueRef(item, def);

          // Hide empty values
          if (
            isEmptyRef(val) &&
            hideEmpty &&
            def.visibility !== "show_always" &&
            !whitelist?.includes(val.field)
          ) {
            pushDirty(curr.hidden, val);
          } else {
            pushDirty(curr.visible, val);
          }

          return curr;
        },
        {
          visible: [] as PropertyValueRef<T>[],
          hidden: [] as PropertyValueRef<T>[],
        }
      ),
    [item, showProps, allProps, hideEmpty]
  );
};

export const useIsShowing = (props: Maybe<PropertyRef[] | PropertyDef[]>) => {
  const showing = useMemo(() => map(props, (p) => p.field), [props]);
  return useCallback((field: string) => showing?.includes(field), [showing]);
};

export const useAllowedChildren = (
  entity: Maybe<Entity>,
  blacklist?: EntityType[]
) => {
  const props = useLazyProperties(entity?.source);
  const settings = useEntitySettings(entity?.id);
  const fromSettings = useMemo(
    () =>
      safeAs<HasSettings>(entity)?.settings?.child_type as Maybe<EntityType>,
    [entity?.id]
  );
  return useMemo(
    () =>
      without(
        fromSettings ? [fromSettings] : toNestedTypes(props, settings),
        ...(blacklist || [])
      ),
    [props, blacklist?.length]
  );
};

const toStampFields = (
  field: string,
  label: string,
  source: DatabaseID
): PropertyDef[] => [
  {
    entity: [source.type],
    field: `${field}.by`,
    scope: source.scope,
    type: "relation",
    label: `${label} by`,
    options: { references: "person" },
    visibility: PropertyVisibility.HideAlways,
    displayAs: DisplayAs.Property,
    locked: false,
    readonly: true,
  },
  {
    entity: [source.type],
    field: `${field}.at`,
    scope: source.scope,
    type: "date",
    label: `${label} at`,
    visibility: PropertyVisibility.HideAlways,
    displayAs: DisplayAs.Property,
    locked: false,
    readonly: true,
  },
];

// Returns additional properties that can be inferred from the real propertries
export function useTimestampFields(
  props: PropertyDef[],
  source: Maybe<DatabaseID>
): PropertyDef[] {
  return useMemo(
    () =>
      source
        ? flatMap(props, (prop) => {
            // Add all base timestamp fields
            if (
              prop.type === "date" &&
              (prop.field?.endsWith("At") || prop.field?.endsWith("By"))
            ) {
              return [prop];
            }

            if (prop.field === "status") {
              return [
                ...toStampFields(
                  "stamps.status_in-progress",
                  "Started",
                  source
                ),
                ...toStampFields("stamps.status_done", "Completed", source),
                ...toStampFields(
                  "stamps.status",
                  "Status last changed",
                  source
                ),
              ];
            }

            if (prop.field === "assigned") {
              return toStampFields("stamps.assigned", "Assigned", source);
            }

            return [];
          })
        : [],
    [source?.type, source?.scope, props]
  );
}

export function useFilterableProperties<T extends EntityType>(
  source: Maybe<DatabaseID<T>>
) {
  const props = useLazyProperties(source);
  const timestamps = useTimestampFields(props, source);
  return useMemo(
    () => uniqBy([...props, ...timestamps], (p) => p.field, "last"),
    [props, timestamps]
  );
}
