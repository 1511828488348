import { groupBy, intersection, isString, map, orderBy, without } from "lodash";
import { useCallback, useMemo } from "react";

import { EntityType, SelectOption } from "@api";

import {
  toPackageGroup,
  useGetPackage,
  useInstalledEntities,
  usePackageIcon,
} from "@state/packages";
import { useEntityLabels } from "@state/settings";

import { Fn } from "@utils/fn";
import { Maybe, maybeMap, when } from "@utils/maybe";
import { toBaseScope } from "@utils/scope";
import { titleCase } from "@utils/string";

import { Box } from "@ui/icon";
import { MenuItem } from "@ui/menu-item";

import { MultiProps, MultiSelect } from "./multi-select";
import { Select, SelectProps } from "./single-select";

import styles from "./select.module.css";

type EntityTypeOption = SelectOption & { type: EntityType };

type EntitySelectProps = Omit<
  SelectProps<EntityTypeOption>,
  "value" | "options" | "onChange"
> & {
  value: Maybe<EntityType>;
  onChange: Fn<EntityType, void>;
  additional?: EntityType[];
  plural?: boolean;
  allowed?: EntityType[];
  exclude?: EntityType[];
  scope: string;
};

type EntityMultiSelectProps = Omit<
  MultiProps<EntityTypeOption>,
  "value" | "options" | "onChange"
> & {
  value: Maybe<EntityType[]>;
  onChange: Fn<EntityType[], void>;
  additional?: EntityType[];
  allowed?: EntityType[];
  exclude?: EntityType[];
  scope: string;
};

export const EntityTypeSelect = ({
  value,
  onChange,
  scope,
  placeholder,
  children,
  allowed,
  additional,
  exclude,
  portal = true,
  plural = true,
  ...props
}: EntitySelectProps) => {
  const entities = useInstalledEntities(
    useMemo(() => toBaseScope(scope), [scope])
  );
  const toPackage = useGetPackage();
  const toTypeLabel = useEntityLabels(scope, { plural });
  const toTypeOption = useCallback(
    (t: EntityType, selected?: EntityType) => {
      const pkg = toPackage(t);

      return {
        id: t,
        value: t,
        name: toTypeLabel(t),
        type: t,
        selected: selected === t,
        group: titleCase(toPackageGroup(pkg)),
        icon: pkg?.icon || Box,
      };
    },
    [toTypeLabel]
  );

  const allowedRelations = useMemo(
    () =>
      allowed
        ? intersection(allowed, entities)
        : ([
            ...(additional || []),
            ...without(entities || [], ...(exclude || [])),
          ] as EntityType[]),
    [entities]
  );

  const options = useMemo(() => {
    if (allowedRelations?.length < 5) {
      return orderBy(
        map(allowedRelations, (a) => toTypeOption(a, value)),
        (o) => o.name
      );
    }

    const grouped = groupBy(allowedRelations, (t) =>
      toPackageGroup(toPackage(t))
    );
    return orderBy(
      map(grouped, (types, group) => ({
        id: group,
        label: titleCase(group),
        options: orderBy(
          map(types, (a) => toTypeOption(a, value)),
          (o) => o.name
        ),
      })),
      (g) => g.label
    );
  }, [allowedRelations, toTypeOption, value]);

  return (
    <Select
      searchable={false}
      value={when(value, toTypeOption)}
      options={options}
      portal={portal}
      {...props}
      onChange={(t) => t && onChange?.(t.type)}
    >
      {children || (
        <MenuItem
          icon={value ? toPackage(value).icon : Box}
          className={styles.control}
        >
          {when(value, (t) => toTypeOption(t)?.name) ||
            placeholder ||
            "Choose relation..."}
        </MenuItem>
      )}
    </Select>
  );
};

export const EntityTypeMultiSelect = ({
  value,
  onChange,
  scope,
  children,
  additional,
  placeholder,
  portal = true,
  exclude,
  ...props
}: EntityMultiSelectProps) => {
  const entities = useInstalledEntities(
    useMemo(() => toBaseScope(scope), [scope])
  );
  const toTypeLabel = useEntityLabels(scope, { plural: true });
  const toTypeOption = useCallback(
    (t: EntityType) => ({
      id: t,
      name: toTypeLabel(t),
      type: t,
    }),
    [toTypeLabel]
  );

  const allowedRelations = useMemo(
    () =>
      orderBy(
        map(
          [
            ...(additional || []),
            ...without(entities || [], ...(exclude || [])),
          ] as EntityType[],
          toTypeOption
        ),
        (o) => o.name
      ),
    [entities]
  );

  return (
    <MultiSelect
      searchable={false}
      value={map(value, toTypeOption)}
      options={allowedRelations}
      toIcon={() => Box}
      portal={portal}
      {...props}
      onChange={(ts) => onChange?.(maybeMap(ts, (t) => t.type))}
    >
      {children || (
        <MenuItem
          icon={Box}
          className={
            isString(props?.className)
              ? props.className
              : props?.className?.trigger
          }
        >
          {((value?.length || 0) <= 3
            ? map(value, (t) => toTypeOption(t)?.name)?.join(", ")
            : `${value?.length} packages`) ||
            placeholder ||
            "Choose relation..."}
        </MenuItem>
      )}
    </MultiSelect>
  );
};
