import { formatBytes } from "@utils/file";

// Aliased
export { formatBytes };

export function estimateMemoryCost(obj: any) {
  // Convert the object to a JSON string
  const jsonString = JSON.stringify(obj);

  // Calculate the size of the string in bytes
  const sizeInBytes = new TextEncoder().encode(jsonString).length;

  return sizeInBytes;
}
