import { first, map } from "lodash";
import { useCallback, useState } from "react";

import { Process, Ref, RichText, SelectOption } from "@api";

import { useEditInAppCommands } from "@state/app";
import {
  useLazyEntities,
  useQueueUpdates,
  useUpdateEntity,
} from "@state/generic";
import { SystemPackages, useHasPackages } from "@state/packages";
import { useLazyPropertyDef } from "@state/properties";

import { ensureArray, ensureMany } from "@utils/array";
import { useShowMore } from "@utils/hooks";
import { Maybe, safeAs, when } from "@utils/maybe";
import { useGoTo, usePushTo } from "@utils/navigation";
import { asMutation, asUpdate } from "@utils/property-mutations";

import { usePageId } from "@ui/app-page";
import { ApplyTemplateButton } from "@ui/apply-template-button";
import { Button } from "@ui/button";
import { CollapsibleSection } from "@ui/collapsible-section";
import { Centered, Container } from "@ui/container";
import { EditableHeading } from "@ui/editable-heading";
import { EmptyState } from "@ui/empty-state";
import { EntityHeaderBar } from "@ui/entity-header-bar";
import { EntitySheet } from "@ui/entity-sheet";
import { AlphaFeature } from "@ui/feature-flag";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { ArrowUpRight, Play, RelationIcon } from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { AddMenuItem, ShowMoreMenuItem } from "@ui/menu-item";
import { OnHover } from "@ui/on-hover";
import { PageBody } from "@ui/page-body";
import { PaneContainer, PaneHeader } from "@ui/pane-header";
import { useSyncPaneCount } from "@ui/pane-manager";
import { PeopleStack } from "@ui/people-stack";
import { LabelledValue } from "@ui/property-value";
import { PropertyValueButton } from "@ui/property-value-button";
import { ResourceExtractor } from "@ui/resource-extractor";
import { GlobalEntitySelect, GlobalMultiEntitySelect } from "@ui/select";
import { Sheet } from "@ui/sheet-layout";
import { SummarySection } from "@ui/summary-section";
import { TemplateBanner } from "@ui/template-banner";
import { WorkflowActions } from "@ui/workflow-action-button";
import { WorkflowBuilderFlow } from "@ui/workflow-builder-flow";
import { RunWorkflowModal } from "@ui/workflow-run-modal";

import { render, toEngine } from "..";
import { PaneOpts } from "../types";

import styles from "./styles.module.css";

export const ProcessPane = ({ id, item: process }: PaneOpts<Process>) => {
  const pushTo = usePushTo();
  const mutate = useUpdateEntity(process.id, process.source.scope);
  const editInAppCommands = useEditInAppCommands();
  const [start, setStart] = useState<Ref>();
  const ownerProp = useLazyPropertyDef(process.source, {
    field: "owner",
    type: "relation",
  });
  const installed = useHasPackages(id, [SystemPackages.Workflows]);

  const handleBodyChanged = useCallback(
    (n: Maybe<RichText>) =>
      mutate(asMutation({ field: "body", type: "rich_text" }, n)),
    [mutate]
  );

  return (
    <EntitySheet size="primary" entity={process}>
      {!!process.template && <TemplateBanner />}

      <VStack gap={0}>
        <Container>
          <EntityHeaderBar entity={process} />
        </Container>

        <OnHover.Trigger>
          <Centered padding="horizontal">
            <VStack gap={20} fit="container">
              <SpaceBetween direction="horizontal" align="stretch" gap={12}>
                <VStack gap={0}>
                  <Container padding="none" inset="left">
                    <HStack gap={0}>
                      <OnHover.Target
                        show={
                          !!safeAs<SelectOption[]>(process.custom?.category)
                            ?.length
                        }
                      >
                        <PropertyValueButton
                          entity={process}
                          field="custom.category"
                          type="multi_select"
                        />
                      </OnHover.Target>

                      <OnHover.Target show={!!process.status}>
                        <PropertyValueButton
                          entity={process}
                          field="status"
                          type="status"
                        />
                      </OnHover.Target>
                      {!process.owner && (
                        <OnHover.Target>
                          <PropertyValueButton
                            entity={process}
                            field="owner"
                            type="relation"
                          />
                        </OnHover.Target>
                      )}
                    </HStack>
                  </Container>

                  <SpaceBetween align="baseline" gap={0}>
                    <EditableHeading
                      key={process.id}
                      text={process.name || ""}
                      placeholder="What is this for?"
                      onChange={(text) => {
                        when(text, (i) =>
                          mutate(asMutation({ field: "name", type: "text" }, i))
                        );
                      }}
                    />

                    <ApplyTemplateButton entity={process} />
                  </SpaceBetween>
                </VStack>

                {process.owner && (
                  <HStack>
                    <LabelledValue label="Owner">
                      <Container
                        size="half"
                        padding="vertical"
                        onClick={() => editInAppCommands(ownerProp, process)}
                      >
                        <PeopleStack
                          people={when(process.owner, ensureArray) || []}
                          size="xlarge"
                        />
                      </Container>
                    </LabelledValue>
                  </HStack>
                )}
              </SpaceBetween>

              <HStack>
                {!!process?.refs?.workflows?.length && (
                  <Button
                    icon={Play}
                    onClick={() => setStart(first(process?.refs?.workflows))}
                  >
                    Start Workflow
                  </Button>
                )}
              </HStack>
            </VStack>
          </Centered>
        </OnHover.Trigger>
      </VStack>

      {start && (
        <RunWorkflowModal
          entity={process}
          workflow={start}
          onClose={() => setStart(undefined)}
        />
      )}

      <Centered stack="vertical" gap={10}>
        {!process.template && <SummarySection entityId={process.id} />}

        <PageBody
          entity={process}
          className={styles.editor}
          body={process.body}
          onBodyChanged={handleBodyChanged}
        />

        {installed[SystemPackages.Workflows] &&
          !process?.refs?.workflows?.length && (
            <CollapsibleSection
              title="Workflow"
              actions={
                <GlobalEntitySelect
                  allowed={["workflow"]}
                  placeholder="Link existing"
                  value={undefined}
                  templates={true}
                  onChange={(wf) =>
                    mutate(
                      asMutation(
                        { field: "refs.workflows", type: "relations" },
                        when(wf, ensureMany)
                      )
                    )
                  }
                />
              }
            >
              <EmptyState>
                <AlphaFeature
                  fallback={<>No workflow defined for this process.</>}
                >
                  <WorkflowActions entity={process} />
                </AlphaFeature>
              </EmptyState>
            </CollapsibleSection>
          )}

        {installed[SystemPackages.Workflows] &&
          when(first(process?.refs?.workflows), (wf) => (
            <CollapsibleSection
              title="Workflow"
              actions={
                <Button
                  subtle
                  size="small"
                  onClick={() => pushTo(wf)}
                  iconRight={ArrowUpRight}
                >
                  Edit
                </Button>
              }
            >
              <WorkflowBuilderFlow id={wf.id} className={styles.flowPreview} />
            </CollapsibleSection>
          ))}
      </Centered>
    </EntitySheet>
  );
};

export const ProcessLinksPane = ({ page }: { page: Process }) => {
  const pageId = usePageId();
  const goTo = useGoTo();
  const related = useLazyEntities(page.refs?.related || []);
  const mutate = useQueueUpdates(pageId);
  const relatedDef = useLazyPropertyDef(page.source, {
    field: "refs.related",
    type: "relations",
  });
  const { visible, showMore, hasMore, moreCount } = useShowMore(
    related || [],
    5
  );

  // TODO: Extract out related like from meetings to other entities
  // Move into generic effects
  // Automatically link work that is mentioned in the agendas....
  // useAutoLinkRelated(page, pageId);

  // Sync the count with the pane manager
  useSyncPaneCount(related?.length);

  return (
    <Sheet size="secondary">
      <PaneHeader title="Related work" />

      <ResourceExtractor entityId={page.id} />

      <PaneContainer>
        <Menu>
          <MenuGroup>
            {map(visible, (item) =>
              render(toEngine(item)?.asMenuItem, {
                key: item.id,
                item: item,
                onChange: (cs) => mutate(asUpdate(item, cs)),
                onOpen: goTo,
                iconRight: ArrowUpRight,
              })
            )}

            {hasMore && (
              <ShowMoreMenuItem count={moreCount} onClick={showMore} />
            )}

            <GlobalMultiEntitySelect
              value={page.refs?.related}
              scope={page.source.scope}
              allowed={relatedDef?.options?.references}
              onChange={(r) =>
                mutate(
                  asUpdate(
                    page,
                    asMutation({ field: "refs.related", type: "relations" }, r)
                  )
                )
              }
              closeOnSelect={true}
              placeholder="Link work"
            >
              <AddMenuItem
                icon={RelationIcon}
                title="Link related work"
                subtle
              />
            </GlobalMultiEntitySelect>
          </MenuGroup>
        </Menu>
      </PaneContainer>
    </Sheet>
  );
};
