import { subWeeks } from "date-fns";
import { find, groupBy, isString, map } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

import {
  DatabaseID,
  Entity,
  Form,
  FormPropDef,
  HasDates,
  HasRefs,
  PropertyDef,
  PropertyRef,
  Ref,
  VariableDef,
} from "@api";

import { useLazyFetchResults } from "@state/fetch-results";
import {
  flattenFor,
  NestableOverrides,
  useCreateFromObject,
  useCreateFromTemplate,
  useLazyEntity,
  useNestedSource,
} from "@state/generic";
import { useMe } from "@state/persons";
import { PropertyDefStoreAtom, useLazyProperties } from "@state/properties";
import { useStartWorkflow } from "@state/workflow";

import { ensureMany, justOne, omitEmpty } from "@utils/array";
import { useISODate } from "@utils/date-fp";
import { log } from "@utils/debug";
import { Fn } from "@utils/fn";
import { isWorkspaceId } from "@utils/id";
import { equalsAny } from "@utils/logic";
import { Maybe, safeAs, when, whenTruthy } from "@utils/maybe";
import { merge } from "@utils/object";
import {
  asMutation,
  asUpdate,
  flattenChanges,
} from "@utils/property-mutations";
import { getPropertyValue, toFieldKey } from "@utils/property-refs";
import { isMatch, toBaseScope, toChildLocation } from "@utils/scope";

import { usePageId } from "@ui/app-page";
import { useMutate } from "@ui/mutate";

import { makeNestedOverrides, withFixedFields } from "./utils";

export const useFormFields = (
  form: Maybe<Form>
): [FormPropDef, Maybe<PropertyDef>][] => {
  const itemSource = useNestedSource(form, form?.entity);
  // TODO: Modify getPropertyDefs to allow fetching all properties of a given field (regardless of entitytype)
  // Then fetch all properties for the form's fields
  // Make sure fetched
  useLazyProperties(itemSource, false);

  const workflow = useLazyEntity<"workflow">(
    justOne(form?.refs?.runWorkflow)?.id
  );

  const store = useRecoilValue(PropertyDefStoreAtom);
  const defsByField = useMemo(
    () => groupBy(store.lookup, (v) => v?.field),
    [store.lookup]
  );

  const maybeGetDef = useCallback(
    (ref: PropertyRef) =>
      whenTruthy(!!itemSource && defsByField[ref.field], (defs) =>
        find(
          defs,
          (p) =>
            !!p &&
            (!p.entity || equalsAny(form?.entity, ensureMany(p.entity))) &&
            p.type === ref.type &&
            isMatch(p.scope, itemSource?.scope)
        )
      ),
    [itemSource?.scope, form?.entity, defsByField]
  );

  return useMemo(() => {
    // Dynamically determine all the fields that should be shown in the form
    if (form?.template) {
      return [
        ...map(workflow?.inputs || [], (p) => [p, p]),
        ...map(withFixedFields(form?.fields), (f) => [
          f,
          maybeGetDef(f as PropertyRef),
        ]),
      ] as Array<[FormPropDef, Maybe<PropertyDef>]>;
    }

    // When submitting a snapshot of the fields are stored on the form
    return map(form?.fields, (f) => [
      f,
      maybeGetDef(f as PropertyRef),
    ]) as Array<[FormPropDef, Maybe<PropertyDef>]>;
  }, [
    form?.fields,
    maybeGetDef,
    itemSource?.type,
    itemSource?.scope,
    workflow?.inputs,
  ]);
};

const isEntity = (thing: Maybe<Entity | string>): thing is Entity =>
  !!safeAs<Entity>(thing)?.id;

export const useFormsForLocation = (entityOrScope?: Maybe<Entity | string>) => {
  const scope = useMemo(() => {
    if (!entityOrScope) {
      return undefined;
    }

    return isEntity(entityOrScope)
      ? toChildLocation(entityOrScope.source.scope, entityOrScope.id)
      : entityOrScope;
  }, [
    safeAs<Entity>(entityOrScope)?.id,
    safeAs<DatabaseID>(entityOrScope)?.scope,
    isString(entityOrScope) ? entityOrScope : undefined,
  ]);

  const allForms = useLazyFetchResults(
    `forms-for-${scope || "all"}`,
    "form",
    useMemo(
      () => ({
        and: omitEmpty([
          {
            field: "template",
            type: "text",
            op: "equals",
            value: { text: "root" },
          },

          {
            field: "archivedAt",
            type: "date",
            op: "is_empty",
          },

          // Filter for forms that are in this root location
          scope && !isWorkspaceId(scope)
            ? {
                field: "location",
                type: "text",
                op: "starts_with",
                value: { text: toBaseScope(scope) },
              }
            : undefined,
        ]),
      }),
      [scope]
    ),
    { templates: true, archived: false }
  );

  return allForms;
};

export const useFormsForTemplate = (template: Ref) => {
  const allForms = useLazyFetchResults(
    `forms-for-template-${template?.id}`,
    "form",
    useMemo(
      () => ({
        and: omitEmpty([
          {
            field: "useTemplate",
            type: "relation",
            op: "equals",
            value: { relation: { id: template.id } },
          },
        ]),
      }),
      [template?.id]
    ),
    { templates: true, archived: false }
  );

  return allForms;
};

export const usePostSaveForm = (
  form: Maybe<Form>,
  onSuccess: Fn<Maybe<Ref>, void>,
  onFailed: Fn<void, void>
) => {
  const me = useMe();
  const pageId = usePageId();
  const [running, setRunning] = useState(false);
  const [saving, setSaving] = useState(false);
  const fields = useFormFields(form);
  const mutate = useMutate();

  const useTemplateId = form?.useTemplate?.id;
  const useTemplate = useLazyEntity(useTemplateId);
  const useTemplateSchedule = useLazyEntity<"schedule">(
    justOne(safeAs<HasRefs>(useTemplate)?.refs?.repeat)?.id
  );

  // Workflow template is cleared off the submitted form
  // so need to look at the workflow
  const workflowId = justOne(form?.refs?.runWorkflow)?.id;
  const workflow = useLazyEntity<"workflow">(workflowId);

  const itemSource = useMemo(
    () =>
      form?.entity && {
        type: form?.entity,
        scope:
          when(form, (i) => toChildLocation(i.location, i.id)) ||
          form?.inLocation ||
          form?.source.scope,
      },
    [form?.entity, form?.source.scope, form?.inLocation, form?.source.scope]
  );
  const create = useCreateFromObject(
    itemSource?.type || "task",
    itemSource?.scope,
    pageId
  );

  const onCreated = useCallback(
    (ref?: Maybe<Ref>) => {
      setSaving(false);
      onSuccess?.(ref);

      if (ref && !!form?.entity && form) {
        mutate(
          asUpdate(form, [
            asMutation({ field: "refs.created", type: "relations" }, [ref]),
          ])
        );
      }
    },
    [setSaving, form?.id]
  );

  // then post-save actions are run
  const createFromTemplate = useCreateFromTemplate(itemSource, onCreated);
  const runWorkflow = useStartWorkflow(form, onCreated);

  const ready =
    !!(workflowId && workflow) || !!(useTemplateId && useTemplate) || true;

  const run = useCallback(() => {
    if (!form) {
      throw new Error("Form not ready for post-save.");
    }

    if (workflow) {
      try {
        const workflowInputs = // Pass in form values as pre-collected workflow inputs
          map(
            fields,
            ([f]) =>
              ({
                ...f,
                // All form fields are stored as custom.* on the form data
                // convert to basic variables for workflow
                field: toFieldKey(f.field),
                value: getPropertyValue(form, f) || { [f.type]: undefined },
              } as VariableDef)
          );

        // Form triggers a workflow
        runWorkflow.start(workflow, workflowInputs);
      } catch (e) {
        log(e);
        onFailed?.();
      }

      return;
    }

    // Form just submits the form
    if (!form?.entity) {
      onCreated();
      return;
    }

    // Form is creating an entity

    try {
      let defaults = {};
      let overrides = merge<NestableOverrides>(
        form?.overrides?.length
          ? {
              [form?.useTemplate?.id || form?.entity || "*"]: flattenChanges(
                form?.overrides || []
              ),
            }
          : {},
        makeNestedOverrides(form, useTemplate, fields, me),
        {
          [form?.useTemplate?.id || form?.entity || "*"]: {
            title: form.name,
            refs: { fromForm: [{ id: form.id }] },
          },
        }
      );

      // If the form is setting a due date on the work, and the template's schedule
      // has a precreate(weeks) rule, set the start dates to the due date - precreate weeks
      const precreate = useTemplateSchedule?.precreate;
      const workDate = safeAs<HasDates>(
        overrides?.[form?.useTemplate?.id || "*"]
      )?.end;

      if (precreate && workDate) {
        overrides = merge(overrides, {
          task: {
            start: useISODate(workDate, (date) => subWeeks(date, precreate)),
          },
        });
      }

      if (form?.useTemplate) {
        createFromTemplate.create(form.useTemplate, {
          defaults,
          overrides,
        });
      } else {
        const [newly] =
          create?.([
            flattenFor(
              merge(defaults, overrides),
              when(form.entity, (type) => ({
                source: { type, scope: form.source.scope },
              })) || {}
            ),
          ]) || [];
        onCreated(newly);
      }
    } catch (e) {
      log(e);
      onFailed?.();
    }
  }, [form, fields, onCreated, runWorkflow, createFromTemplate]);

  useEffect(() => {
    if (running && ready) {
      try {
        run();
      } catch (err) {
        onFailed();
      }
    }
  }, [running, ready]);

  return useCallback(() => setRunning(true), [setRunning]);
};
