import { filter, find, isFunction, map } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";

import { PropertyDef, PropertyType, VariableDef, Vars } from "@api";

import { ensureMany, isEmpty, move } from "@utils/array";
import { Fn } from "@utils/fn";
import { isDefined, Maybe, when } from "@utils/maybe";
import {
  isAnyRelation,
  isAnySelect,
  toPropertyTypeLabel,
} from "@utils/property-refs";
import { withoutStar } from "@utils/wildcards";

import { Button } from "@ui/button";
import { Card } from "@ui/card";
import { CollapsibleSection } from "@ui/collapsible-section";
import { ContextItem, ContextMenu } from "@ui/context-menu";
import { Dialog } from "@ui/dialog";
import { DropHighlight } from "@ui/drop-highlight";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { useGenericDragDrop } from "@ui/generic-drag-drop";
import { TrashAlt } from "@ui/icon";
import { Field, TextInput } from "@ui/input";
import { AddMenuItem } from "@ui/menu-item";
import { showError } from "@ui/notifications";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { Select } from "@ui/select";
import { EntityTypeMultiSelect } from "@ui/select/entity-type";
import { Tag } from "@ui/tag";
import { TextMedium, TextSmall } from "@ui/text";

import { PropertyValueOptions } from "./property-edit-dialog";
import { PropertyValue } from "./property-value";

import styles from "./variable-list.module.css";

export const VariableList = ({
  variables,
  scope,
  onChanged,
}: {
  variables: Maybe<Vars>;
  scope: string;
  onChanged?: Fn<Vars, void>;
}) => {
  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState<VariableDef>();
  const editable = !!onChanged;

  const handleReorderFields = useCallback(
    (from: number, to: number) => {
      if (!editable) {
        return;
      }
      onChanged(move(variables || [], from, to));
    },
    [variables]
  );

  return (
    <VStack>
      {map(variables, (v, i) => (
        <ContextMenu
          key={v.field}
          actions={
            editable && (
              <ContextItem
                icon={TrashAlt}
                text="Delete"
                onClick={() =>
                  onChanged(filter(variables || [], (i) => i !== v))
                }
              />
            )
          }
        >
          <VariableListItem
            variable={v}
            index={i}
            onReorder={editable ? handleReorderFields : undefined}
            onClick={() => setEditing(v)}
          />
        </ContextMenu>
      ))}

      {editable && (
        <AddMenuItem
          title="Add variable"
          subtle
          onClick={() => setAdding(true)}
        />
      )}

      {adding && editable && (
        <EditVariableModal
          scope={scope}
          onSave={(v) => onChanged([...(variables || []), v])}
          onClose={() => setAdding(false)}
        />
      )}

      {editing && editable && (
        <EditVariableModal
          variable={editing}
          scope={scope}
          onSave={(v) =>
            onChanged(map(variables, (i) => (i === editing ? v : i)))
          }
          onClose={() => setEditing(undefined)}
        />
      )}
    </VStack>
  );
};

export const VariableListItem = ({
  variable: v,
  index,
  onReorder,
  onClick,
}: {
  variable: VariableDef;
  index: number;
  onReorder?: (from: number, to: number) => void;
  onClick?: (e: React.MouseEvent) => void;
}) => {
  const dragDropRef = useRef<HTMLDivElement>(null);

  const { dropping } = useGenericDragDrop({
    item: v,
    order: index,
    ref: dragDropRef,
    onReorder: (from, to) =>
      isDefined(to.order) && !!onReorder && onReorder(from.order, to.order),
  });

  return (
    <Card ref={dragDropRef} onClick={onClick}>
      {dropping && <DropHighlight offset={-5} />}
      <SpaceBetween>
        <HStack align="baseline" gap={4}>
          <TextMedium>{v.label}</TextMedium>
          <TextSmall subtle>{`{${v.field}}`}</TextSmall>
        </HStack>
        <Tag>{v.type}</Tag>
      </SpaceBetween>
      <TextSmall subtle>{v.description}</TextSmall>
    </Card>
  );
};

const VARIABLE_PROP_TYPES: PropertyType[] = [
  // "status",
  "text",
  "rich_text",
  "select",
  "multi_select",
  "link",
  "links",
  "number",
  "date",
  "boolean",
  "relation",
  "relations",
];

export const EditVariableModal = ({
  variable: _variable,
  defaults,
  scope,
  onSave,
  onClose,
}: {
  variable?: VariableDef;
  defaults?: Partial<VariableDef>;
  scope: string;
  onSave: Fn<VariableDef, void>;
  onClose: Fn<void, void>;
}) => {
  const [variable, setVariable] = useState<VariableDef>(
    () =>
      _variable || {
        field: "",
        type: "text",
        value: {},
        ...defaults,
      }
  );
  const handleChange = useCallback(
    (changes: Partial<VariableDef> | Fn<VariableDef, void>) => {
      return setVariable((p) => {
        const latest = isFunction(changes) ? changes(p) : changes;
        return { ...p, ...latest };
      });
    },
    []
  );

  const available = useMemo(
    () =>
      map(VARIABLE_PROP_TYPES, (t) => ({
        id: t,
        name: toPropertyTypeLabel(t),
      })),
    []
  );
  const selectedType = useMemo(
    () => find(available, { id: variable.type }),
    [variable.type]
  );

  const handleAdd = useCallback(() => {
    if (!variable.field || !variable.type) {
      showError("Handle and type is required.");
      return;
    }

    onSave?.(variable);
    onClose?.();
  }, [variable]);

  return (
    <Dialog
      autoPosition={true}
      title={!!_variable?.field ? "Add variable" : "Edit variable"}
      onDismiss={() => onClose?.()}
      actions={
        <>
          <Button onClick={() => onClose?.()}>Cancel</Button>
          <Button variant="primary" onClick={handleAdd}>
            Save Variable
          </Button>
        </>
      }
    >
      <VStack gap={20}>
        <SpaceBetween gap={6}>
          <Field label="Name">
            <TextInput
              value={variable.label || ""}
              onChange={(n) => {
                if (!variable.field) {
                  handleChange({
                    label: n,
                    field: n.toLowerCase().replace(/\s/g, "_"),
                  });
                } else {
                  handleChange({ label: n });
                }
              }}
              updateOn="blur"
              autoFocus
            />
          </Field>
          <Field label="Handle">
            <TextInput
              value={`@` + variable.field}
              onChange={(v) => handleChange({ field: v.replace(/^@/, "") })}
              updateOn="change"
            />
          </Field>
        </SpaceBetween>

        <Field label="Help Text">
          <TextInput
            value={variable.description || ""}
            placeholder="What this variable is for..."
            onChange={(v) => handleChange({ description: v })}
            updateOn="change"
          />
        </Field>

        <SpaceBetween gap={10} align="flex-end">
          <Field label="Data Type">
            <Select
              className={{ trigger: styles.control }}
              searchable={false}
              options={available}
              value={selectedType}
              portal={true}
              toIcon={(v) => <PropertyTypeIcon type={v.id as PropertyType} />}
              onChange={(v) => v && handleChange({ type: v.id })}
            />
          </Field>

          {isAnyRelation(variable) && (
            <Field label=" ">
              <EntityTypeMultiSelect
                className={{ trigger: styles.control }}
                value={when(variable.options?.references, (r) =>
                  ensureMany(withoutStar(r))
                )}
                additional={["person", "team", "resource"]}
                placeholder="Any type of work..."
                scope={scope}
                onChange={(v) =>
                  handleChange({
                    options: { references: !v || isEmpty(v) ? "*" : v },
                  })
                }
              />
            </Field>
          )}
        </SpaceBetween>

        {isAnySelect(variable) && (
          <Field label="Options" help="The values allowed to be selected from.">
            <PropertyValueOptions def={variable} onChange={handleChange} />
          </Field>
        )}

        <CollapsibleSection inset={false} title="Advanced" defaultOpen={false}>
          <VStack fit="container" gap={10}>
            {variable.type !== "rich_text" && (
              <Field label="Default Value">
                <PropertyValue
                  className={styles.control}
                  valueRef={variable}
                  onChange={(v) => handleChange({ value: v })}
                  // TODO PropertyValue should just take a scope not a source, doesnt make sense.
                  source={{ type: "workflow", scope: scope }}
                />
              </Field>
            )}
          </VStack>
        </CollapsibleSection>
      </VStack>
    </Dialog>
  );
};
