import { map } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getEngine, render, useEngine } from "ui/engine";

import { Entity, EntityType, HasLocation, Ref, toTitleOrName } from "@api";

import { useSyncPageEntity } from "@state/app";
import {
  useCreateFromObject,
  useEntitySource,
  useLazyEntities,
  useLazyEntity,
} from "@state/generic";
import { useEntityLabels } from "@state/settings";
import { ID } from "@state/types";
import {
  toTemplateViewId,
  useAddToView,
  useDefaultsForView,
  useLazyGetView,
  useLazyItemsForView,
} from "@state/views";

import { cx } from "@utils/class-names";
import { logThrough } from "@utils/debug";
import { Fn } from "@utils/fn";
import { typeFromId } from "@utils/id";
import { maybeMap, when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { toRef } from "@utils/property-refs";
import { containsRef } from "@utils/relation-ref";
import { joinLocation, toChildLocation, toLast } from "@utils/scope";
import {
  SelectionState,
  SetSelectionState,
  usePageSelection,
} from "@utils/selectable";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { EmptyState } from "@ui/empty-state";
import { EntityList } from "@ui/entity-list";
import { SpaceBetween, VStack } from "@ui/flex";
import { PlusIcon } from "@ui/icon";
import { ListItem } from "@ui/list-item";
import { ParentCard } from "@ui/parent-card";
import { RelationLabel } from "@ui/relation-label";
import { TextLarge, TextSmall } from "@ui/text";
import { ViewResultsList } from "@ui/view-results-list";
import { EntityViewResultsPage } from "@ui/view-results-page";
import { ViewingWithinContext } from "@ui/viewing-within";

import { BrowserListItem } from "./browser";
import { NestedItems, toNestedItems } from "./utils";

import styles from "./preview.module.css";

interface Props {
  id: ID;
}

export function PreviewLayout({ id }: Props) {
  const view = useLazyGetView(id);
  const pushTo = usePushTo();
  const pageId = usePageId();
  const { items } = useLazyItemsForView(id);
  const onAdded = useAddToView(id);
  const viewDefaults = useDefaultsForView(id);
  const defaults = useMemo(
    () => ({
      ...viewDefaults,
      orders: {
        default: String((items?.all?.length || 0) + 1),
      },
    }),
    [viewDefaults, items?.all?.length]
  );

  const viewingIn = useLazyEntity(
    useMemo(() => toLast(view?.location), [view?.location])
  );
  const itemsSource = useEntitySource(view?.entity || "task", view?.source);
  const create = useCreateFromObject(itemsSource.type, itemsSource.scope);
  const engine = useEngine(itemsSource.type);
  const toLabel = useEntityLabels(view?.source.scope, {
    case: "title",
    plural: true,
  });
  const [activeId, setActiveId] = useState<ID>();
  const [selection, setSelection] = usePageSelection();
  const active = useLazyEntity(activeId);

  const itemsByLocation = useMemo(
    () =>
      toNestedItems(
        (items.sorted || items.all || []) as HasLocation[],
        view?.location
      ),
    [items?.sorted, items.all]
  );
  const openPath = view?.location;
  const openItemRefs = useMemo(
    () => (openPath ? itemsByLocation[openPath] || [] : []),
    [itemsByLocation, openPath]
  );
  const openItems = useLazyEntities(openItemRefs);

  // Update page.entity for app commands whenever the active item changes
  useSyncPageEntity(pageId, active);

  const handleAdd = useCallback(() => {
    if (!create) {
      return;
    }

    const saved = create([defaults]);
    onAdded(saved);
    when(saved?.[0]?.id, setActiveId);
  }, [create, defaults]);

  // Default to the first item in the list
  useEffect(() => {
    const firstItem = openItemRefs?.[0]?.id;
    if (
      !!firstItem &&
      (!activeId || !containsRef(openItemRefs, toRef(activeId)))
    ) {
      setActiveId(firstItem);
    }
  }, [openItemRefs.length]);

  // // Whenever the selection changes, update the activeId
  // useEffect(() => {
  //   const curr = selection.focused;
  //   if (!!curr && activeId !== curr) {
  //     setActiveId(curr);
  //   }
  // }, [selection?.focused]);

  if (!view) {
    return <></>;
  }

  return (
    <div className={cx(styles.rootContainer)}>
      <div className={styles.listContainer}>
        <Container padding="horizontal" size="half" inset="right">
          <SpaceBetween>
            <VStack gap={0} fit="container">
              <TextLarge bold>{toLabel(view.entity)}</TextLarge>

              {!!viewingIn && viewingIn?.source.type === view.entity && (
                <Button
                  variant="link"
                  onClick={() => setActiveId(viewingIn.id)}
                >
                  <TextSmall subtle>{toTitleOrName(viewingIn)}</TextSmall>
                </Button>
              )}
            </VStack>
            <Button subtle icon={PlusIcon} onClick={handleAdd} />
          </SpaceBetween>
        </Container>

        <VStack gap={0} fit="container">
          {maybeMap(openItems, (item) => (
            <BrowserListItem
              key={item.id}
              type={itemsSource.type}
              item={item}
              tree={itemsByLocation}
              className={cx(activeId === item.id && styles.active)}
              onSelected={(i) => setActiveId(i.id)}
              onOpen={(i) => setActiveId(i.id)}
              showPackage={false}
              selection={selection}
              setSelection={setSelection}
            />
          ))}
        </VStack>
      </div>
      <div className={styles.workContainer}>
        {active?.source.type === itemsSource.type &&
          render(engine.asPrimaryPane, {
            id: active.id,
            item: active,
            className: styles.pane,
            size: "full",
          })}

        {active && active?.source.type !== itemsSource.type && (
          <FolderView
            type={itemsSource.type}
            tree={itemsByLocation}
            item={active}
            onOpen={(i) =>
              typeFromId(logThrough(i, "selected").id) === itemsSource.type
                ? setActiveId(i.id)
                : pushTo(i)
            }
            selection={selection}
            setSelection={setSelection}
          />
        )}
        {!active && <EmptyState text="Nothing selected." />}
      </div>
    </div>
  );
}

const FolderView = ({
  tree,
  type,
  item: active,
  onOpen,
  selection,
  setSelection,
}: {
  tree: NestedItems;
  item: Entity;
  type: EntityType;
  onOpen?: Fn<Ref, void>;
  selection?: SelectionState;
  setSelection?: SetSelectionState;
}) => {
  const openRefs = useMemo(
    () => tree[toChildLocation(active.source.scope, active.id)] || [],
    [active.source.scope, active.id, tree]
  );
  const openItems = useLazyEntities(openRefs);

  return (
    <Container stack="vertical" gap={10}>
      <ParentCard
        id={active.id}
        showProps={false}
        showBody={false}
        onClick={() => onOpen?.(active)}
      />

      <VStack gap={0}>
        {map(openItems, (item) => (
          <BrowserListItem
            key={item.id}
            type={type}
            item={item}
            tree={tree}
            onOpen={(i) => onOpen?.(i)}
            showPackage={false}
            selection={selection}
            setSelection={setSelection}
          />
        ))}
      </VStack>
    </Container>
  );
};
