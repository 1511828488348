import { ReactNode } from "react";

import { Fn } from "@utils/fn";

import { SpaceBetween } from "@ui/flex";

import { Button } from "./button";
import { Dialog } from "./dialog";

interface Props {
  title?: string;
  description?: string;
  destructive?: boolean;
  onCancel?: Fn<void, void>;
  onConfirm: Fn<void, void>;
  children?: ReactNode;
}

export const ConfirmationDialog = ({
  title,
  description,
  destructive = false,
  children,
  onCancel,
  onConfirm,
}: Props) => (
  <Dialog
    autoPosition
    title={title || "Are you sure you want to do this?"}
    description={
      description ||
      "This action is kinda important. So make sure you are caffeinated."
    }
    actions={
      <SpaceBetween>
        <Button key="cancel" onClick={() => onCancel?.()}>
          Cancel
        </Button>
        <Button
          key="cancel"
          variant={destructive ? "danger" : "primary"}
          onClick={() => onConfirm?.()}
        >
          Confirm
        </Button>
      </SpaceBetween>
    }
    onDismiss={() => onCancel?.()}
    children={children}
  />
);
