import { map } from "lodash";

import { DatabaseID, VariableDef, Workflow, WorkflowStep } from "@api";

import { useLazyEntities } from "@state/generic";
import { isNotStarted, isRunning } from "@state/workflow";

import { switchEnum } from "@utils/logic";
import { Maybe } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { toFieldName } from "@utils/property-refs";
import { titleCase } from "@utils/string";

import { Card } from "@ui/card";
import { Divider } from "@ui/divider";
import { EmptyState } from "@ui/empty-state";
import { EntityPreview } from "@ui/entity-preview";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Icon, SpinnerIcon } from "@ui/icon";
import { ListItem } from "@ui/list-item";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { PropertyValue } from "@ui/property-value";
import { RelationLabel } from "@ui/relation-label";
import { ReadonlyDocument } from "@ui/rich-text";
import { Tag } from "@ui/tag";
import { Text, TextSmall } from "@ui/text";

import { WorkflowStepEditor } from "./editor";

import styles from "./styles.module.css";

interface Props {
  step: WorkflowStep;
  workflow: Workflow;
  allSteps: Maybe<WorkflowStep[]>;
}

export const WorkflowStepPane = ({ step, workflow, allSteps }: Props) => {
  const created = useLazyEntities(step?.refs?.created);
  // Hasn't started, can still edit
  if (isNotStarted(step)) {
    return (
      <WorkflowStepEditor id={step.id} workflow={workflow} steps={allSteps} />
    );
  }

  if (!!created?.length) {
    return (
      <VStack fit="container">
        {map(created, (entity) => (
          <Card key={entity.id} interactable={false}>
            <EntityPreview
              entity={entity}
              editable={true}
              showBody={true}
              className={styles.anyHeight}
            />
          </Card>
        ))}
      </VStack>
    );
  }

  // Running or waiting
  if (isRunning(step)) {
    return (
      <EmptyState>
        <Icon icon={SpinnerIcon} />
      </EmptyState>
    );
  }

  if (step.outputs?.length) {
    return (
      <VStack fit="container">
        {map(step.outputs, (v) => (
          <VariableOutput key={v.field} variable={v} source={step.source} />
        ))}
      </VStack>
    );
  }

  // Has finished running but has no outputs
  return <EmptyState text="Step completed without any outputs." />;
};

const VariableOutput = ({
  variable,
  source,
}: {
  variable: VariableDef;
  source: DatabaseID;
}) => {
  const pushTo = usePushTo();

  return (
    <Card fit="container" interactable={false}>
      <VStack>
        <SpaceBetween>
          <HStack gap={4}>
            <Text>{variable.label || toFieldName(variable)}</Text>
            <TextSmall subtle>{`{${variable.field}}`}</TextSmall>
          </HStack>
          <Tag color="gray_5" size="small">
            <Icon
              icon={
                <PropertyTypeIcon
                  field={String(variable.field)}
                  type={variable.type}
                  options={variable.options}
                />
              }
            />
            {titleCase(variable.type)}
          </Tag>
        </SpaceBetween>

        <Divider />

        <VStack fit="container">
          {switchEnum(variable.type, {
            rich_text: () => (
              <ReadonlyDocument content={variable.value.rich_text} />
            ),
            relations: () => (
              <VStack fit="container" gap={0}>
                {map(variable.value.relations, (relation) => (
                  <ListItem key={relation.id} onClick={() => pushTo(relation)}>
                    <RelationLabel relation={relation} />
                  </ListItem>
                ))}
              </VStack>
            ),
            else: () => (
              <PropertyValue
                editable={false}
                source={source}
                valueRef={variable}
              />
            ),
          })}
        </VStack>
      </VStack>
    </Card>
  );
};
