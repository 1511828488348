import { isString, last, map, omit, orderBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  DatabaseID,
  Entity,
  EntityType,
  HasLocation,
  ID,
  toTitleOrName,
  View,
} from "@api";

import { getStore, useLazyEntities, useSource } from "@state/generic";
import { useLazyPropertyDef } from "@state/properties";
import { useStableViewKey } from "@state/store";
import {
  useAddToView,
  useDefaultsForView,
  useLazyGetView,
  useLazyItemsForView,
} from "@state/views";

import { maybeMap } from "@utils/array";
import { Fn } from "@utils/fn";
import { GroupedItems } from "@utils/grouping";
import { useShowMore, useStickyState } from "@utils/hooks";
import { Maybe } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { toKey, toRef } from "@utils/property-refs";
import {
  fromScope,
  joinLocation,
  takeUpTo,
  toChildLocation,
} from "@utils/scope";
import { clearSelection, selectAll, usePageSelection } from "@utils/selectable";

import { AddEntityInput } from "@ui/add-entity-input";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { Divider } from "@ui/divider";
import { ListItemOpts } from "@ui/engine";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { GoToButton } from "@ui/go-to-button";
import {
  AngleDownIcon,
  AngleRightIcon,
  ArrowLeft,
  CounterIcon,
  FolderOpen,
  Icon,
} from "@ui/icon";
import { ListItem } from "@ui/list-item";
import { LocationBreadcrumb } from "@ui/location-button";
import { ShowMoreMenuItem } from "@ui/menu-item";
import { PackageTag } from "@ui/package-label";
import { PropertyLabel } from "@ui/property-label";
import { RelationLabel } from "@ui/relation-label";
import { SelectableListItem } from "@ui/selectable-items";
import { TextSmall } from "@ui/text";

import { NestedItems, toNestedItems } from "./utils";

type Props = { id: ID };

export function BrowserLayout({ id }: Props) {
  const view = useLazyGetView(id);
  const viewResults = useLazyItemsForView(id);
  const onAdded = useAddToView(id);
  const viewDefaults = useDefaultsForView(id);
  // Omit location since it can be changed by openPath
  const defaults = useMemo(
    () => omit(viewDefaults, "location"),
    [viewDefaults]
  );

  const pushTo = usePushTo();
  const [selection, setSelection] = usePageSelection();
  const [openGroup, setOpenGroup] = useState<GroupedItems>();
  const [openPath, setOpenPath] = useStickyState(
    view?.location,
    `drive-open-${id}`
  );
  const itemsSource = useSource(
    view?.entity || "task",
    openPath || view?.source?.scope
  );

  const setOpen = useCallback((pOrG: GroupedItems | string) => {
    if (isString(pOrG)) {
      setOpenPath(pOrG);
      setOpenGroup(undefined);
    } else {
      setOpenGroup(pOrG);
    }
  }, []);

  const mode = useMemo(
    () =>
      view?.group?.length && openPath === view.location && !openGroup
        ? "group"
        : "items",
    [view?.group?.length, openGroup, openPath]
  );
  const parent = useMemo(() => toRef(last(fromScope(openPath))), [openPath]);
  const groupByDef = useLazyPropertyDef(
    view?.source
      ? { type: view?.entity || "task", scope: view?.source.scope }
      : undefined,
    view?.group?.[0]
  );
  const toStableKey = useStableViewKey(getStore(itemsSource?.type || "page"));

  const itemsByLocation = useMemo(
    () =>
      toNestedItems(
        (openGroup?.items ||
          viewResults.items.sorted ||
          viewResults.items.all ||
          []) as HasLocation[],
        view?.location
      ),
    [viewResults?.items?.sorted]
  );
  const hasChildren = useCallback(
    (item: Entity) =>
      !!itemsByLocation[joinLocation(item.source?.scope, item.id)],
    [itemsByLocation]
  );
  const openItemRefs = useMemo(
    () => (openPath ? itemsByLocation[openPath] || [] : []),
    [itemsByLocation, openPath]
  );
  const openItems = useLazyEntities(openItemRefs);

  const ordered = useMemo(
    () =>
      orderBy(
        openGroup?.items || openItems,
        (i) => (hasChildren(i) ? "0" : "1") + toTitleOrName(i)
      ),
    [openItems, openGroup?.items]
  );
  const items = useShowMore(ordered, 50);

  const handleOpen = useCallback(
    (item: Entity) => {
      if (!hasChildren(item) || item.source.type === view?.entity) {
        pushTo(item);
      } else {
        setOpen(item.source.scope + "/" + item.id);
      }
    },
    [openPath]
  );

  // Set location when empty and view loads
  useEffect(() => {
    if (!openPath && !!view) {
      setOpen(view.location);
    }
  }, [view?.id, openPath]);

  if (!view) {
    return <></>;
  }

  return (
    <Container padding="vertical" size="half">
      <VStack gap={6}>
        {!(openPath === view.location && !openGroup) && (
          <Container fit="content" padding="none" inset="horizontal">
            <VStack>
              <SpaceBetween>
                <HStack gap={0}>
                  {parent?.id && (
                    <Button
                      size="small"
                      subtle
                      icon={ArrowLeft}
                      onClick={() =>
                        setOpen(takeUpTo(openPath || "", parent?.id, false))
                      }
                    />
                  )}
                  <LocationBreadcrumb
                    location={openPath}
                    onOpen={(r) => {
                      setOpen(takeUpTo(openPath || "", r.id, true));
                    }}
                    editable={false}
                    showIcons={true}
                    variant="full"
                    size="small"
                    trailing={
                      openGroup && (
                        <>
                          <TextSmall subtle>/</TextSmall>
                          <Button subtle size="small">
                            <PropertyLabel
                              size="small"
                              valueRef={openGroup.value}
                              source={view.source}
                            />
                          </Button>
                        </>
                      )
                    }
                  />

                  {parent && parent.id !== view.for?.id && (
                    <GoToButton text="Open" mode="push" item={parent} />
                  )}
                </HStack>

                <HStack>
                  {openPath !== view.location && (
                    <Button
                      subtle
                      size="tiny"
                      onClick={() => setOpen(view.location)}
                    >
                      Reset
                    </Button>
                  )}
                </HStack>
              </SpaceBetween>
              <Divider />
            </VStack>
          </Container>
        )}

        <Container fit="content" padding="none" inset="horizontal">
          <VStack gap={0}>
            {mode === "group" && (
              <VStack gap={4}>
                {!!groupByDef &&
                  map(
                    viewResults?.items?.grouped?.groups as GroupedItems[],
                    (group) => (
                      <GroupListItem
                        key={toKey(group.value)}
                        group={group}
                        source={view.source}
                        onOpen={setOpenGroup}
                      />
                    )
                  )}
              </VStack>
            )}
            {mode === "items" &&
              maybeMap(items.visible, (item) => (
                <BrowserListItem
                  key={toStableKey(item.id)}
                  type={view.entity}
                  item={item}
                  tree={itemsByLocation}
                  onOpen={handleOpen}
                  selection={selection}
                  setSelection={setSelection}
                />
              ))}

            {mode === "items" && !!items.hasMore && (
              <ShowMoreMenuItem
                count={items.moreCount}
                onClick={items.showMore}
              />
            )}

            {mode === "items" && itemsSource && (
              <AddEntityInput
                source={itemsSource}
                defaults={defaults}
                onAdded={onAdded}
              />
            )}
          </VStack>
        </Container>
      </VStack>
    </Container>
  );
}

export const BrowserListItem = ({
  item,
  selection,
  tree,
  indent,
  onOpen,
  onSelected,
  setSelection,
  showPackage = true,
  type,
  className,
  ...props
}: Omit<ListItemOpts<Entity>, "onOpen"> & {
  tree: NestedItems;
  type: EntityType;
  showPackage?: boolean;
  onOpen: Fn<Entity, void>;
}) => {
  const [showChildren, setShowChildren] = useState(false);
  const childRefs = useMemo(
    () => tree[toChildLocation(item.source.scope, item.id)],
    [tree, item?.source.scope, item.id, showChildren]
  );
  const children = useLazyEntities(showChildren ? childRefs : undefined);

  return (
    <>
      <EntityContextMenu
        entity={item}
        selection={selection}
        setSelection={setSelection}
      >
        <SelectableListItem
          item={item}
          selectable={true}
          selection={selection}
          setSelection={setSelection}
          onSelected={onSelected}
          onOpen={() => onOpen?.(item)}
          className={className}
        >
          <HStack gap={0} style={{ paddingLeft: `${(indent || 0) * 16}px` }}>
            {!!childRefs?.length && (
              <Button
                onClick={() => setShowChildren(!showChildren)}
                icon={showChildren ? AngleDownIcon : AngleRightIcon}
                size="tiny"
                inset
                subtle
              />
            )}

            {item.source.type === type ? (
              <RelationLabel relation={item} />
            ) : (
              <RelationLabel icon={FolderOpen} relation={item} />
            )}

            <HStack>
              {showPackage && (
                <PackageTag
                  scope={item.source?.scope}
                  type={item.source.type}
                />
              )}
              <GoToButton item={item} />
            </HStack>
          </HStack>
        </SelectableListItem>
      </EntityContextMenu>

      {map(children, (child) => (
        <BrowserListItem
          key={child.id}
          item={child}
          selection={selection}
          setSelection={setSelection}
          showPackage={showPackage}
          tree={tree}
          onSelected={onSelected}
          onOpen={onOpen}
          type={type}
          indent={(indent || 0) + 1}
          {...props}
        />
      ))}
    </>
  );
};

const GroupListItem = ({
  group,
  source,
  onOpen,
}: {
  group: GroupedItems;
  source: DatabaseID;
  onOpen: Fn<Maybe<GroupedItems>, void>;
}) => {
  const [selection, setSelection] = usePageSelection();
  const selected = useMemo(
    () => selection?.selected?.has(group?.items?.[0]?.id),
    [selection, group]
  );

  const handleOpen = useCallback(() => {
    setSelection(clearSelection());
    onOpen(group);
  }, [group, setSelection, onOpen]);

  const handleSelect = useCallback(
    () =>
      setSelection(
        selectAll(
          map(group.items, (i) => i.id),
          "replace"
        )
      ),
    [group, setSelection]
  );

  return (
    <ListItem
      onClick={handleSelect}
      selectable={true}
      selected={selected}
      onDoubleClick={handleOpen}
    >
      <SpaceBetween>
        <HStack gap={4}>
          <Button icon={AngleRightIcon} size="tiny" subtle />
          <PropertyLabel valueRef={group.value} source={source} />
        </HStack>
        <Icon
          size="small"
          icon={<CounterIcon color="gray_5" count={group.items?.length} />}
        />
      </SpaceBetween>
    </ListItem>
  );
};
