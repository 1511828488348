import { addMinutes } from "date-fns";
import { first, orderBy } from "lodash";

import { Meeting, Update } from "@api";

import { findStatus } from "@state/properties";
import { findMutation, getItem } from "@state/store";
import {
  WorkflowAction,
  WorkflowDefinitionConfig,
  WorkflowTrigger,
} from "@state/workflows";

import { usePointDate } from "@utils/date-fp";
import { equalsAny } from "@utils/logic";
import { when } from "@utils/maybe";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { isEmptyRef } from "@utils/property-refs";

import { EndMeetingDialog } from "@ui/engine/meeting";

import { isHost } from "./utils";

export const meetingDuration: WorkflowTrigger<Meeting> = {
  id: "meetingDuration",
  trigger: "WILL_UPDATE",
  type: "meeting",

  allowed: ({ entity, update }, { props, session }) =>
    // When changing the start or duration, but NOT the end
    !!findMutation(update, ({ field }) =>
      equalsAny(field, ["start", "duration"])
    ) && !when(findMutation(update, "end"), (u) => !isEmptyRef(u)),

  execute: ({ entity, update }, { session, stores, props }) => {
    const start = findMutation(update, "start")?.value.date || entity.start;
    const duration =
      findMutation(update, "duration")?.value.number || entity.duration || 60;

    if (!start || !duration) {
      return;
    }

    const end = usePointDate(
      start,
      (newStart) => newStart && addMinutes(newStart, duration)
    );

    // TODO: Make triggers able to return an Update or Mutation. If mutation, then it just adds to the current update
    // Also make trigger types accept any entity update
    return end
      ? asUpdate<Meeting>(
          update as Pick<Meeting, "id" | "source">,
          asMutation({ field: "end", type: "date" }, end)
        )
      : undefined;
  },
};

export const claimHost: WorkflowAction<Meeting> = {
  id: "claimHost",
  trigger: "ACTION",
  type: "meeting",
  title: "Claim host",
  variant: "secondary",

  allowed: ({ entity }, { props, session }) =>
    !!entity.owner &&
    !isHost(entity, session.user) &&
    entity?.status?.group !== "done",

  execute: ({ entity }, { session, stores, props }) => {
    return asUpdate(
      entity,
      asMutation({ field: "owner", type: "relation" }, { id: session.user?.id })
    );
  },
};

export const setHost: WorkflowAction<Meeting> = {
  id: "setHost",
  trigger: "ACTION",
  type: "meeting",
  title: "Set meeting host",

  allowed: ({ entity }, { props, session }) =>
    !entity.owner && entity?.status?.group !== "done",

  collect: [{ field: "owner", type: "relation" }],

  execute: ({ entity, collected }, { session, stores, props }) => {
    return asUpdate(entity, collected);
  },
};

// export const joinMeeting: WorkflowAction<Meeting, Update<Meeting>[]> = {
//   id: "joinMeeting",
//   trigger: "ACTION",
//   type: "meeting",
//   icon: Video,
//   title: "Join meeting",

//   allowed: ({ entity }, { props, session }) => entity?.status?.group !== "done",

//   execute: ({ entity, collected }, { session, stores, props }) => {
//     openInNewTab("https://zoom.us");

//     return [];
//   },
// };

export const startMeeting: WorkflowAction<Meeting, Update<Meeting>[]> = {
  id: "startMeeting",
  trigger: "ACTION",
  type: "meeting",
  title: "Start meeting",

  allowed: ({ entity }, { props, session }) =>
    isHost(entity, session.user) &&
    equalsAny(entity?.status?.group, ["planning", "not-started"]),

  execute: ({ entity, collected }, { session, stores, props }) => {
    const status = findStatus("in-progress", props, entity.source);
    const agendas = orderBy(
      entity.refs?.agendas,
      (r) => getItem(stores.agenda, r.id)?.order
    );
    return asUpdate(entity, [
      asMutation({ field: "status", type: "status" }, status),
      asMutation({ field: "refs.current", type: "relation" }, first(agendas)),
    ]);
  },
};

export const restartMeeting: WorkflowAction<Meeting, Update<Meeting>[]> = {
  id: "restartMeeting",
  trigger: "ACTION",
  type: "meeting",
  title: "Restart meeting",
  variant: "secondary",

  allowed: ({ entity }, { props, session }) =>
    isHost(entity, session.user) && equalsAny(entity?.status?.group, ["done"]),

  execute: ({ entity, collected }, { session, stores, props }) => {
    const status = findStatus("in-progress", props, entity.source);
    return asUpdate(entity, [
      asMutation({ field: "status", type: "status" }, status),
      asMutation(
        { field: "refs.current", type: "relation" },
        first(entity.refs?.agendas)
      ),
    ]);
  },
};

export const cancelMeeting: WorkflowAction<Meeting, Update<Meeting>[]> = {
  id: "cancelMeeting",
  trigger: "ACTION",
  type: "meeting",
  title: "Cancel meeting",

  allowed: ({ entity }, { session, props }) =>
    isHost(entity, session.user) &&
    equalsAny(entity?.status?.group, ["planning", "not-started"]),
  execute: ({ entity, collected }, { session, stores, props }) => {
    return asUpdate(
      entity,
      asMutation({ field: "status", type: "status" }, { id: "CNCL" })
    );
  },
};

export const endMeeting: WorkflowAction<Meeting, Update<Meeting>[]> = {
  id: "endMeeting",
  trigger: "ACTION",
  type: "meeting",
  title: "End meeting",

  allowed: ({ entity }, { session, props }) =>
    isHost(entity, session.user) &&
    equalsAny(entity?.status?.group, ["in-progress"]),

  collect: ({
    data: { entity },
    onCollected,
    onCancelled,
    context: { session, props },
  }) => {
    return (
      <EndMeetingDialog
        meeting={entity}
        onCancel={onCancelled}
        onComplete={() => onCollected([])}
      />
    );
  },

  execute: ({ entity, collected }, { session, stores, props }) => {
    return [];
  },
};

export const definitions: WorkflowDefinitionConfig<Meeting> = {
  triggers: [meetingDuration],
  suggestions: [],
  actions: [
    startMeeting,
    cancelMeeting,
    endMeeting,
    restartMeeting,
    // joinMeeting,
    claimHost,
    setHost,
  ],
};

export default definitions;
