import { WorkflowStep } from "@api";

import { safeAs, when } from "@utils/maybe";
import { plural, titleCase } from "@utils/string";

export const toWaitLabel = (
  step: Partial<WorkflowStep>,
  prefix: string = "Wait"
) => {
  const waitTimes = when(
    safeAs<number | string>(step.options?.waitTimes),
    Number
  );
  const waitPeriod = when(safeAs<string>(step.options?.waitPeriod), String);

  if (!waitTimes || !waitPeriod) {
    return "Wait";
  }

  return `${prefix} ${waitTimes} ${plural(titleCase(waitPeriod), waitTimes)}`;
};
