import { isString } from "lodash";
import { ReactNode, useMemo } from "react";

import { Color, ID, WorkflowAction, WorkflowStep } from "@api";

import { justOne } from "@utils/array";
import { cx } from "@utils/class-names";
import { fallback, Fn } from "@utils/fn";
import { ifDo, switchEnum } from "@utils/logic";
import { Maybe, when } from "@utils/maybe";

import {
  BracketsCurly,
  Clock,
  CommentLines,
  CornerDownRight,
  DocumentFilled,
  EllipsisH,
  Exchange,
  Filter,
  FormFilled,
  Icon,
  Join,
  Magic,
  Play,
  Search,
  Slack,
  SpinnerIcon,
  Split,
  SquareShape,
  TasksFilled,
} from "@ui/icon";
import { Props as SquareIconProps, SquareIcon } from "@ui/icon/square";
import { RelationIcon } from "@ui/relation-label";

import styles from "./icon.module.css";

type Props = {
  action?: WorkflowAction;
  step?: WorkflowStep;
  size?: SquareIconProps["size"];
  className?: string;
  onClick?: Fn<React.MouseEvent, void>;
};

export const WorkflowStepIcon = ({
  step,
  action = step?.action,
  size = 34,
  onClick,
  className,
}: Props) => {
  const shapeClass = useMemo(() => {
    return switchEnum<WorkflowAction, string>(action || WorkflowAction.Entry, {
      condition: "diamond",
      update: "circle",
      entry: "circle",
      exit: "circle",
      else: "square",
    });
  }, [action]);

  const color = useMemo(() => {
    // Non-templates
    if (!!step && !step?.template) {
      return switchEnum<ID, Maybe<Color>>(step?.status?.id || "", {
        FNS: "green_5",
        SKP: "gray_5",
        WAI: "blue_5",
        RUN: "blue_5",
        else: () => "gray_5",
      });
    }

    // Templates
    return switchEnum<WorkflowAction, Color>(action || WorkflowAction.Entry, {
      wait: "yellow_2",
      control: "yellow_2",
      condition: "yellow_2",
      set_var: "yellow_2",
      ai: "purple",
      run_workflow: "blue_2",
      entry: "blue_2",
      message: "blue_2",
      exit: "red_2",
      else: "blue_2",
    });
  }, [action, step?.status?.id]);

  const icon = useMemo(() => {
    return (
      switchEnum(step?.status?.id || "", {
        RUN: () => <Icon icon={SpinnerIcon} />,
        else: () => undefined,
      }) ||
      switchEnum<WorkflowAction, ReactNode>(action || WorkflowAction.Entry, {
        find: <Icon icon={Search} />,
        entry: <Icon icon={Play} />,
        exit: <Icon icon={SquareShape} />,
        ai: <Icon icon={Magic} />,
        message: (
          <Icon icon={step?.options?.via === "slack" ? Slack : CommentLines} />
        ),
        control: <Icon icon={Join} />,
        set_var: <Icon icon={BracketsCurly} />,
        create: () => (
          <Icon
            icon={fallback(
              () =>
                when(justOne(step?.refs?.created), (r) => (
                  <RelationIcon relation={r} />
                )),
              () =>
                ifDo(step?.options?.entity === "task", () => <TasksFilled />),
              () =>
                ifDo(step?.options?.entity === "form", () => <FormFilled />),
              () =>
                ifDo(step?.options?.entity === "page", () => (
                  <DocumentFilled />
                )),
              () => (
                <EllipsisH />
              )
            )}
          />
        ),
        update: () => <Icon icon={Exchange} />,
        condition: () => <Icon icon={Filter} />,
        run_workflow: () => <Icon icon={CornerDownRight} />,
        wait: <Icon icon={Clock} />,
        else: <Icon icon={EllipsisH} />,
      })
    );
  }, [action, step?.action, step?.refs?.created, step?.status?.id]);

  return (
    <SquareIcon
      color={color}
      border={isString(size) ? size !== "small" : size > 36}
      onClick={onClick}
      className={cx(
        styles[shapeClass],
        (isString(size) ? size === "small" : size <= 20)
          ? styles.hideIcon
          : undefined,
        className
      )}
      size={size}
    >
      {icon}
    </SquareIcon>
  );
};
