import { map, orderBy } from "lodash";
import { Fragment, useMemo } from "react";

import { Entity, ID, Ref } from "@api";

import { useLazyFetchResults } from "@state/fetch-results";
import { useLazyEntity } from "@state/generic";

import { useShowMore } from "@utils/hooks";
import { usePushTo } from "@utils/navigation";
import { toRef } from "@utils/property-refs";

import { Container } from "@ui/container";
import { render, useEngine } from "@ui/engine";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { MenuItem, ShowMoreMenuItem } from "@ui/menu-item";
import { PaneHeader } from "@ui/pane-header";
import { useSyncPaneCount } from "@ui/pane-manager";
import { Sheet } from "@ui/sheet-layout";
import { Text } from "@ui/text";

export const TemplateInstancesPane = ({
  template: ref,
  instanceId,
  title,
}: {
  template: Ref;
  instanceId?: ID;
  title?: string;
}) => {
  const pushTo = usePushTo();
  const template = useLazyEntity(ref.id);
  const instances = useLazyFetchResults(
    `template-instances-${ref.id}`,
    template?.source.type || "task",
    useMemo(
      () => ({
        field: "refs.fromTemplate",
        type: "relations",
        op: "equals",
        value: { relations: [toRef(ref)] },
      }),
      [ref?.id]
    ),
    { fetch: !!template }
  );
  const engine = useEngine(instances?.[0]?.source.type || "task");
  const ordered = useMemo(() => {
    if (!template) {
      return [];
    }

    return orderBy(instances, (i) => i.createdAt, "desc");
  }, [instances]);

  const showMore = useShowMore(ordered as Entity[], 6, false, "end");

  // Sync the count with the pane manager
  useSyncPaneCount(instances?.length);

  if (!template || !instances?.length) {
    return <></>;
  }

  return (
    <Sheet size="secondary" height="content">
      <PaneHeader
        title={title || "Instances"}
        description="Work created by this template"
      />
      <Container size="half">
        <Menu>
          <MenuGroup>
            {!showMore.visible.length && (
              <MenuItem disabled>
                <Text subtle>No instances</Text>
              </MenuItem>
            )}

            {map(showMore.visible, (e, i) => (
              <Fragment key={e.id}>
                {render(engine.asMenuItem, {
                  item: e,
                  selected: e.id === instanceId,
                  onOpen: pushTo,
                })}
              </Fragment>
            ))}
            {showMore.hasMore && (
              <ShowMoreMenuItem
                text="Show more"
                count={showMore.moreCount}
                onClick={showMore.showMore}
              />
            )}
          </MenuGroup>
        </Menu>
      </Container>
    </Sheet>
  );
};
