import { filter, flatMap, map, reduce, set, some } from "lodash";

import {
  Entity,
  Form,
  FormPropDef,
  HasBody,
  Person,
  PropertyDef,
  RefOrID,
  SelectOption,
} from "@api";

import { NestableOverrides } from "@state/generic";
import { toDisplayName } from "@state/persons";

import { formatDay, formatTime } from "@utils/date";
import { strip } from "@utils/html";
import { equalsAny } from "@utils/logic";
import { defs, Maybe, maybeMap, safeAs, when } from "@utils/maybe";
import { now } from "@utils/now";
import {
  getPropertyValue,
  toFieldKey,
  toFieldName,
  toHtml,
  toNestedKeys,
  toRef,
} from "@utils/property-refs";
import { append } from "@utils/rich-text";

import { FormData } from "./atoms";

export const isFormId = (id: string): boolean =>
  id?.startsWith("fm_") || id?.startsWith("[fm_");

export const makeNestedOverrides = (
  form: Form,
  template: Maybe<Entity>,
  fields: [FormPropDef, Maybe<PropertyDef>][],
  me: Person
): NestableOverrides => {
  const connections = flatMap(fields, ([prop, def]) => {
    return map(prop.connections, (c) => [c, prop, def] as const);
  });

  const overrides = reduce(
    fields,
    (all, [field]) => {
      const value = getPropertyValue(form, field)?.[field.type];
      const connectTo = maybeMap(connections, ([c, p]) =>
        toFieldKey(p?.field) === toFieldKey(field.field) ? c : undefined
      );

      return reduce(
        connectTo,
        (all, target) => {
          const targetId = target?.id || "*";

          // Not mapped to any field
          if (!target?.field) {
            return all;
          }

          return set(
            all,
            [
              // The target entity to override (or all that have that field if not defined)
              targetId,
              ...(target.field.includes(".")
                ? toNestedKeys(target.field)
                : [target.field]),
            ],
            value
          );
        },
        all
      );
    },
    {} as NestableOverrides
  );

  const table = strip(
    `
    <p>
      <a data-mention-id="${form.id}">${form.name}</a> submitted by 
      <a data-mention-id="${me.id}" data-mention-type="person">
      ${toDisplayName(me)}
      </a> on ${formatDay(now())} at ${formatTime(now())}
    </p>
    <table>` +
      reduce(
        fields,
        (acc, [prop, def]) => {
          const value = getPropertyValue(form, prop);
          const valHtml = toHtml({
            ...prop,
            value: value,
            def: def,
          });

          return (
            acc +
            strip(
              `<tr>
                <th>${prop.label || toFieldName(def || prop)}</th>
                <td>${valHtml}</td>
              </tr>
              `
            )
          );
        },
        ""
      ) +
      `
      </table>
      <p></p>
      `
  );

  // Set the body of the template to the table
  set(
    overrides,
    [form?.useTemplate?.id || form.entity || "*", "body"],
    append(safeAs<HasBody>(template)?.body || { html: "" }, { html: table })
  );

  return overrides;
};

// Forms have fixed fields that can be in the form.fields but not always
export const withFixedFields = (fields: Form["fields"]) =>
  some(fields, (f) => f.field === "createdBy")
    ? fields
    : [
        ...(fields || []),
        { field: "createdBy", type: "relation", label: "Submitted by" },
      ];

export const isReadonlyField = ([prop, def]: [
  FormPropDef,
  Maybe<PropertyDef>
]): boolean => def?.readonly || prop.field === "createdBy";

export const toStoredField = (
  [prop, def]: [FormPropDef, Maybe<PropertyDef>],
  formData: FormData
): FormPropDef => ({
  field: `custom.${toFieldKey(prop.field)}`,
  type: prop.type,
  label: prop.label || toFieldName(def || prop),
  description: prop.description,
  connections: prop.connections,
  // Store the property def values on this for the selected
  // so that we can show the correct value on the submitted form regardless if it's changed
  values: equalsAny(prop.type, ["select", "multi_select"])
    ? when(def?.values, (vs) => ({
        [prop.type]: filter(
          vs?.[prop.type],
          (v) =>
            safeAs<SelectOption>(v)?.id ===
            when(safeAs<RefOrID>(formData[toFieldKey(prop.field)]), toRef)?.id
        ),
      }))
    : undefined,
});
