import { find, map, some } from "lodash";

import { CreateOrUpdate, Outcome } from "@api";

import {
  WorkflowDefinition,
  WorkflowDefinitionConfig,
} from "@state/workflows/types";

import { asUpdate } from "@utils/property-mutations";

export const mirrorOrganisingPropsToTasks: WorkflowDefinition<Outcome> = {
  id: "mirrorOrganisingPropsToTasks",
  trigger: "WILL_UPDATE",
  type: "outcome",
  allowed: ({ entity, update }, _context) =>
    update.method === "update" &&
    some(update.changes, (c) => ["sprint", "projects"].includes(c.field)),

  execute: ({ entity, update }) => {
    const tasks = entity.refs?.tasks || [];
    const change = find((update as CreateOrUpdate<Outcome>)?.changes, (c) =>
      ["sprint", "projects"].includes(c.field)
    );

    if (!change) {
      return [];
    }

    return map(tasks, (t) =>
      asUpdate({ ...t, source: { ...entity.source, type: "task" } }, [change])
    );
  },
};

export const definitions: WorkflowDefinitionConfig<Outcome> = {
  triggers: [mirrorOrganisingPropsToTasks],
  actions: [],
};

export default definitions;
