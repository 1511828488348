import { isNumber, isString } from "lodash";
import { ReactNode, useMemo } from "react";

import { cx } from "@utils/class-names";

import styles from "./on-hover.module.css";

type TriggerProps = {
  group?: string;
  children: ReactNode;
};

type TargetProps = TriggerProps & {
  show?: boolean;
  opacity?: "none" | "partial" | number;
};

export const Trigger = ({ children, group = "default" }: TriggerProps) => {
  return (
    <div data-group={group} className={styles.parent}>
      {group !== "default" && (
        <style>
          {`
          .${styles.parent}[data-group="${group}"]:hover .${styles.target}[data-group="${group}"] > * {
            opacity: 1;
          }
        `}
        </style>
      )}
      {children}
    </div>
  );
};

export const Target = ({
  children,
  group = "default",
  show,
  opacity = "none",
}: TargetProps) => {
  const css = useMemo(
    () => (isNumber(opacity) ? { opacity } : undefined),
    [opacity]
  );
  return (
    <div
      style={css}
      data-group={group}
      className={cx(
        styles.target,
        isString(opacity) && styles[opacity],
        show && styles.show
      )}
    >
      {children}
    </div>
  );
};

export const OnHover = { Trigger, Target };
