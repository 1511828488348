import { find, groupBy, map } from "lodash";
import { memo, useCallback, useEffect, useMemo, useState } from "react";

import {
  CreateOrUpdate,
  Entity,
  EntityType,
  ID,
  JsonArray,
  PropertyMutation,
  RichText,
  Update,
  Workflow,
  WorkflowAction,
  WorkflowStep,
} from "@api";

import { useAiUseCase, workflowStepCreate } from "@state/ai";
import { usePageUndoRedo, useRegisterPage } from "@state/app";
import {
  useCreateEntity,
  useLazyEntities,
  useLazyEntity,
  useLazyQuery,
  useMarkAsSeen,
  useNestedSource,
  useUpdateEntity,
} from "@state/generic";
import { useLazyProperties } from "@state/properties";
import { useAddToRecents } from "@state/recents";
import { aiStepToUpdate } from "@state/workflow";

import { ensureArray, justOne, omitEmpty } from "@utils/array";
import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { switchEnum } from "@utils/logic";
import { Maybe, safeAs, when } from "@utils/maybe";
import { useGoTo, usePushTo } from "@utils/navigation";
import {
  asAppendMutation,
  asMutation,
  asUpdate,
} from "@utils/property-mutations";
import { getJsonValue, toRef } from "@utils/property-refs";
import { isEmpty } from "@utils/rich-text";
import { toBaseScope, toLast } from "@utils/scope";
import { useSyncPathnameID } from "@utils/url";

import { usePageId } from "@ui/app-page";
import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { CounterButton } from "@ui/counter-button";
import { Divider } from "@ui/divider";
import { EditableHeading } from "@ui/editable-heading";
import { PaneOpts } from "@ui/engine";
import { AlphaFeature } from "@ui/feature-flag";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  ArrowUpRight,
  BoltAlt,
  Box,
  ClipboardNotes,
  ClockHistory,
  CounterIcon,
  Icon,
  Magic,
  Play,
  SpinnerIcon,
} from "@ui/icon";
import { InflatedStatusTag } from "@ui/inflated-status-tag";
import { Field } from "@ui/input";
import { Label } from "@ui/label";
import { ListItem } from "@ui/list-item";
import { MenuItem } from "@ui/menu-item";
import { Modal } from "@ui/modal";
import { useMutate } from "@ui/mutate";
import { showError } from "@ui/notifications";
import { PackageTag } from "@ui/package-label";
import AppPage from "@ui/page/app-page";
import { RelationLabel } from "@ui/relation-label";
import { TextBox } from "@ui/rich-text";
import { ColoredSection } from "@ui/section";
import { PersonSelect } from "@ui/select";
import { EntityTypeMultiSelect } from "@ui/select/entity-type";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { SquareButton } from "@ui/square-button";
import { Switch } from "@ui/switch";
import { TabBar } from "@ui/tab-bar";
import { Text, TextMedium, TextSmall } from "@ui/text";
import { VariableList } from "@ui/variable-list";
import { WorkflowBuilderFlow } from "@ui/workflow-builder-flow";
import { RunWorkflowModal } from "@ui/workflow-run-modal";

import { WorkflowStepEditor } from "../workflow_step/editor";
import { WorkflowStepIcon } from "../workflow_step/icon";

import styles from "./editor.module.css";

export const WorkflowEditorPane = (props: PaneOpts<Workflow>) => {
  const { id, item: workflow } = props;
  const [page] = useRegisterPage(id, workflow);
  const stepSource = useNestedSource(workflow, "workflow_step");
  const teamId = useMemo(() => toBaseScope(workflow.source.scope), [workflow]);
  const pushTo = usePushTo();
  const goTo = useGoTo();
  const mutate = useMutate();
  const create = useCreateEntity(stepSource.type, stepSource.scope);
  const createForm = useCreateEntity("form", teamId);
  const [selected, setSelected] = useState<Maybe<ID>>();
  const [active, setActive] = useState<"build" | "edit" | "run" | string>(
    workflow?.template ? "build" : "run"
  );
  const [adding, setAdding] = useState<boolean | string>(false);
  const [addingTo, setAddingTo] = useState<WorkflowStep | undefined>();
  const steps = useLazyEntities<"workflow_step">(workflow.refs?.steps);

  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, workflow?.id);

  // Add to recents
  useAddToRecents(id);

  const addStep = useCallback(
    (defaults: PropertyMutation[]) => {
      const action =
        (find(defaults, { field: "action" })?.value
          .text as Maybe<WorkflowAction>) || WorkflowAction.Create;

      const step = create([
        ...switchEnum<WorkflowAction, PropertyMutation[]>(action, {
          create: () => [
            asMutation({ field: "name", type: "text" }, "Create Task"),
            asMutation({ field: "options.entity", type: "text" }, "task"),
          ],
          set_var: () => [
            asMutation({ field: "name", type: "text" }, "Set Variable"),
          ],
          control: () => [
            asMutation({ field: "name", type: "text" }, "Wait All"),
            asMutation({ field: "options.all", type: "boolean" }, true),
          ],
          ai: () => [
            asMutation({ field: "name", type: "text" }, "AI Prompt"),
            // asMutation({ field: "outputs", type: "json" }, [
            //   { field: "response", type: "text", label: "Response" },
            // ]),
          ],
          message: () => [
            asMutation({ field: "name", type: "text" }, "Send Message"),
            asMutation({ field: "options.via", type: "text" }, "slack"),
          ],
          exit: () => [
            asMutation({ field: "name", type: "text" }, "End Workflow"),
          ],
          condition: () => [
            asMutation({ field: "name", type: "text" }, "Condition"),
          ],
          update: () => [asMutation({ field: "name", type: "text" }, "Update")],
          wait: () => [asMutation({ field: "name", type: "text" }, "Wait")],
          else: () => [],
        }),

        asMutation({ field: "location", type: "text" }, stepSource.scope),
        asMutation({ field: "action", type: "text" }, action),
        asMutation({ field: "status", type: "status" }, { id: "NTS" }),
        asMutation(
          { field: "template", type: "text" },
          !!workflow.template ? "nested" : undefined
        ),
        asMutation(
          { field: "refs.blockedBy", type: "relations" },
          when(toRef(addingTo), ensureArray)
        ),
        asMutation({ field: "refs.workflow", type: "relations" }, [
          { id: workflow.id },
        ]),
        ...defaults,
      ]);

      if (addingTo) {
        // Always add here (even though done serverside) so that UI updates immediately
        mutate(
          asUpdate(
            addingTo,
            omitEmpty([
              asAppendMutation({ field: "refs.blocks", type: "relations" }, [
                toRef(step),
              ]),
              adding === "else"
                ? asAppendMutation({ field: "refs.else", type: "relations" }, [
                    toRef(step),
                  ])
                : undefined,
            ])
          )
        );
      }

      mutate(
        asUpdate(
          workflow,
          asAppendMutation({ field: "refs.steps", type: "relations" }, [
            toRef(step),
          ])
        )
      );

      setAdding(false);
      setAddingTo(undefined);

      setSelected(step.id);
    },
    [addingTo, workflow?.template]
  );

  const handleAddOrUpdate = useCallback(
    (updates: Update<Entity>[]) => {
      const { creates, rest } = groupBy(updates, (u) =>
        switchEnum(u.method, {
          create: "creates",
          else: "rest",
        })
      );
      // Process the creates first
      map(creates, (s: CreateOrUpdate) =>
        addStep([
          { field: "id", type: "text", value: { text: s.id } },
          ...s.changes,
        ])
      );

      // Then process the rest
      map(rest, (u) => mutate(u));
    },
    [workflow]
  );

  const handleAddStep = useCallback(
    (connectTo?: WorkflowStep, handle?: string) => {
      setAddingTo(connectTo);
      setAdding(handle || true);
    },
    [create]
  );

  const handleOpenStep = useCallback(
    (step: WorkflowStep) => {
      if (step.template) {
        setSelected(step.id);
      } else if (step.refs?.created?.length) {
        pushTo(step.refs.created[0]);
      }
    },
    [setSelected, setActive]
  );

  const handleSetupForm = useCallback(() => {
    const form = createForm([
      asMutation({ field: "name", type: "text" }, workflow.name),
      asMutation({ field: "template", type: "text" }, "root"),
      asMutation(
        { field: "refs.runWorkflow", type: "relation" },
        toRef(workflow)
      ),
    ]);

    // Link the form to the workflow since refs.runWorkflow doesn't sync back
    mutate(
      asUpdate(
        workflow,
        asAppendMutation({ field: "refs.forms", type: "relations" }, [
          toRef(form),
        ])
      )
    );
    pushTo([form, "/builder"]);
  }, [workflow]);

  const availableOn = useMemo(
    () => getJsonValue<EntityType[]>(workflow, "options.availableOn"),
    [workflow.options?.availableOn]
  );

  useEffect(() => {
    if (!selected?.length && active === "edit") {
      setActive(workflow?.template ? "build" : "run");
    }
  }, [selected]);

  if (!workflow) {
    return <></>;
  }

  return (
    <Sheet
      size="full"
      transparency="mid"
      interactable={false}
      className={styles.container}
    >
      {adding && <AddStepModal onAdd={addStep} onClose={setAdding} />}

      <HeaderBar {...props} />

      <div className={styles.main}>
        <WorkflowBuilderFlow
          id={id}
          selected={when(selected, ensureArray) || []}
          onAddStep={handleAddStep}
          onOpenStep={handleOpenStep}
          onSelectionChanged={(s) => {
            setSelected(justOne(s));
            setActive("edit");
          }}
        />

        <AlphaFeature>
          <AddNextStep
            workflow={workflow}
            steps={steps}
            mutate={handleAddOrUpdate}
          />
        </AlphaFeature>
      </div>

      <VStack className={styles.pane} fit="container" gap={20}>
        <TabBar
          active={active}
          onActiveChanged={setActive}
          className={styles.tabBar}
          options={[
            { id: "build", title: "Build" },
            { id: "edit", title: workflow?.template ? "Edit" : "Details" },
            { id: "run", title: "Run" },
          ]}
        />

        {active === "build" && (
          <SpaceBetween direction="vertical" width="container">
            <FillSpace direction="vertical" fit="container" width="container">
              <VStack fit="container" gap={16}>
                <Field label="Status">
                  <SpaceBetween>
                    <MenuItem>
                      <SpaceBetween>
                        <Text>
                          {workflow.status?.id === "DFT"
                            ? "Ready to publish"
                            : "Available for use"}
                        </Text>
                        <Switch
                          checked={workflow.status?.id !== "DFT"}
                          onChange={(checked) =>
                            mutate(
                              asUpdate(
                                workflow,
                                asMutation(
                                  { field: "status", type: "status" },
                                  { id: checked ? "AVL" : "DFT" }
                                )
                              )
                            )
                          }
                        />
                      </SpaceBetween>
                    </MenuItem>
                  </SpaceBetween>
                </Field>

                <Field label="Workflow Owner">
                  <PersonSelect
                    value={workflow.owner}
                    className={{ trigger: styles.control }}
                    onChange={(v) =>
                      mutate(
                        asUpdate(
                          workflow,
                          asMutation({ field: "owner", type: "relation" }, v)
                        )
                      )
                    }
                  />
                </Field>

                <Field label="Workflow Triggers">
                  <ColoredSection>
                    <VStack gap={10}>
                      <SpaceBetween>
                        <Label icon={Box}>Show as Button</Label>
                        <EntityTypeMultiSelect
                          scope={workflow.source.scope}
                          additional={["team"]}
                          value={availableOn}
                          onChange={(v) =>
                            mutate(
                              asUpdate(
                                workflow,
                                asMutation(
                                  {
                                    field: "options.availableOn",
                                    type: "json",
                                  },
                                  v
                                )
                              )
                            )
                          }
                        >
                          {when(availableOn?.length || undefined, (count) => (
                            <CounterIcon color="green" count={count} />
                          )) || (
                            <Button size="tiny">
                              <TextSmall subtle>Setup</TextSmall>
                            </Button>
                          )}
                        </EntityTypeMultiSelect>
                      </SpaceBetween>

                      <SpaceBetween>
                        <Label icon={ClockHistory}>Recurring Schedules</Label>

                        <div
                          onClick={() => goTo([teamId, "/settings/schedules"])}
                        >
                          {when(
                            workflow?.refs?.schedules?.length || undefined,
                            (count) => (
                              <Container inset="right" padding="none">
                                <CounterButton count={count} />
                              </Container>
                            )
                          ) || (
                            <Button size="tiny" variant="primary">
                              <TextSmall color="inverse">Setup</TextSmall>
                            </Button>
                          )}
                        </div>
                      </SpaceBetween>

                      <SpaceBetween>
                        <Label icon={ClipboardNotes}>Forms</Label>
                        <div>
                          {when(
                            workflow?.refs?.forms?.length || undefined,
                            (count) => (
                              <Container
                                inset="right"
                                padding="none"
                                onClick={() =>
                                  goTo([teamId, "/settings/templates"])
                                }
                              >
                                <CounterButton count={count} />
                              </Container>
                            )
                          ) || (
                            <Button
                              size="tiny"
                              variant="primary"
                              onClick={handleSetupForm}
                            >
                              <TextSmall color="inverse">Setup</TextSmall>
                            </Button>
                          )}
                        </div>
                      </SpaceBetween>

                      <SpaceBetween>
                        <Label icon={BoltAlt}>Automated Rules</Label>
                        {/* <TextSmall subtle>Setup</TextSmall> */}
                        <TextSmall subtle>Coming soon...</TextSmall>
                      </SpaceBetween>
                    </VStack>
                  </ColoredSection>
                </Field>

                <Field label="Input Variables">
                  <VariableList
                    variables={workflow.inputs}
                    scope={workflow.source.scope}
                    onChanged={(vars) => {
                      mutate(
                        asUpdate(
                          workflow,
                          asMutation(
                            { field: "inputs", type: "json" },
                            safeAs<JsonArray>(vars)
                          )
                        )
                      );
                    }}
                  />
                </Field>
              </VStack>
            </FillSpace>
            <Field>
              <SquareButton
                className={styles.addButton}
                onClick={() => handleAddStep()}
              >
                <VStack fit="container" justify="center" align="center">
                  <HStack className={styles.stack}>
                    <WorkflowStepIcon action={WorkflowAction.AI} />
                    <WorkflowStepIcon action={WorkflowAction.Create} />
                    <WorkflowStepIcon action={WorkflowAction.Condition} />
                    <WorkflowStepIcon action={WorkflowAction.Message} />
                    <WorkflowStepIcon action={WorkflowAction.Exit} />
                  </HStack>
                  <Text>Add Step</Text>
                </VStack>
              </SquareButton>
            </Field>
          </SpaceBetween>
        )}

        {active === "edit" && (
          <WorkflowStepEditor id={selected} workflow={workflow} steps={steps} />
        )}

        {active === "run" && (
          <>
            {!!workflow.template && (
              <WorkflowRunHistory id={id} workflow={workflow} />
            )}
          </>
        )}
      </VStack>
    </Sheet>
  );
};

const HeaderBar = ({ id, item: workflow }: PaneOpts<Workflow>) => {
  const mutate = useUpdateEntity(id);
  const goTo = useGoTo();
  return (
    <VStack className={styles.header} fit="content" gap={0}>
      <HStack>
        <EditableHeading
          key={workflow.id}
          size="h3"
          text={workflow.name || ""}
          placeholder="Untitled"
          onChange={(t) =>
            t && mutate(asMutation({ field: "name", type: "text" }, t))
          }
        />
        <PackageTag type={workflow.source.type} scope={workflow.source.scope} />
      </HStack>
      {!!workflow?.refs?.fromTemplate?.length && (
        <Button
          className={styles.hoverLink}
          variant="link"
          onClick={() => when(workflow.refs?.fromTemplate?.[0], (t) => goTo(t))}
        >
          <HStack gap={4}>
            <Text>Based on</Text>
            <RelationLabel
              icon={false}
              relation={toRef(workflow.refs.fromTemplate[0])}
              iconRight={ArrowUpRight}
            />
          </HStack>
        </Button>
      )}
    </VStack>
  );
};

const ADD_STEPS = [
  {
    action: WorkflowAction.Create,
    label: "Add Work",
  },
  {
    action: WorkflowAction.Update,
    label: "Update Work",
  },
  {
    action: WorkflowAction.Find,
    label: "Find Work",
  },
  {
    action: WorkflowAction.Wait,
    label: "Wait Time",
  },
  {
    action: WorkflowAction.Control,
    label: "Wait All",
  },
  {
    action: WorkflowAction.Condition,
    label: "Condition",
  },
  {
    action: WorkflowAction.SetVar,
    label: "Set Variable",
  },
  {
    action: WorkflowAction.AI,
    label: "AI Prompt",
  },
  {
    action: WorkflowAction.Message,
    label: "Send Message",
  },
  {
    action: WorkflowAction.RunWorkflow,
    label: "Run Workflow",
  },
  {
    action: WorkflowAction.Exit,
    label: "End Workflow",
  },
];

const AddStepOptions = memo(
  ({ onAdd }: { onAdd: Fn<WorkflowAction, void> }) => {
    return (
      <HStack wrap gap={0}>
        {map(ADD_STEPS, ({ label, action }) => (
          <SquareButton
            key={action}
            className={styles.squareButton}
            icon={<WorkflowStepIcon size={"medium"} action={action} />}
            iconSize="xlarge"
            text={label}
            onClick={() => onAdd?.(action)}
          />
        ))}
      </HStack>
    );
  },
  (prev, next) => prev.onAdd === next.onAdd
);

AddStepOptions.displayName = "AddStepOptions";

const AddStepModal = ({
  onAdd,
  onClose,
}: {
  onAdd: Fn<PropertyMutation[], void>;
  onClose: Fn<boolean, void>;
}) => {
  const [action, _setAction] = useState<WorkflowAction>();

  const setAction = useCallback((a: WorkflowAction) => {
    _setAction(a);
    onAdd([asMutation({ field: "action", type: "text" }, a)]);
  }, []);

  return (
    <Modal
      padding="none"
      open={true}
      autoPosition
      onOpenChanged={onClose}
      closeOnEscape={true}
      className={styles.addModal}
    >
      {!action && (
        <Container size="half">
          <AddStepOptions onAdd={setAction} />
        </Container>
      )}
    </Modal>
  );
};

const WorkflowRunHistory = ({
  id,
  workflow,
}: {
  id: Maybe<ID>;
  workflow: Workflow;
}) => {
  const pushTo = usePushTo();
  const parent = useLazyEntity(toLast(workflow.location));
  const [starting, setStarting] = useState<Workflow>();
  const runs = useLazyQuery(
    "workflow",
    useMemo(
      () => ({
        field: "refs.fromTemplate",
        type: "relation",
        op: "equals",
        // TODO: getting replaced by createFromTemplate
        value: { relation: { id: workflow.id } },
      }),
      [workflow.id]
    )
  );

  return (
    <VStack>
      <TextMedium bold>Run History</TextMedium>

      {starting && (
        <RunWorkflowModal
          entity={parent}
          workflow={starting}
          autoStart={true}
          onClose={() => setStarting(undefined)}
        />
      )}

      <Field>
        <VStack>
          {map(runs, (r) => (
            <ListItem key={r.id} onClick={() => pushTo(r)}>
              <SpaceBetween>
                <Text>{r.name}</Text>
                <InflatedStatusTag status={r.status} source={r.source} />
              </SpaceBetween>
            </ListItem>
          ))}
        </VStack>
      </Field>

      <Divider />

      <HStack fit="container" justify="flex-end">
        <Button
          variant="primary"
          size="small"
          loading={!!starting}
          onClick={() => setStarting?.(workflow)}
          icon={Play}
        >
          Test Workflow
        </Button>
      </HStack>
    </VStack>
  );
};

export const WorkflowEditorPage = ({ id }: { id: ID }) => {
  const workflow = useLazyEntity<"workflow">(id);
  const pageId = usePageId();
  const [page] = useRegisterPage(id, workflow);
  const goTo = useGoTo();

  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, workflow?.id);

  // Mark the note as seen by current user
  useMarkAsSeen(id, pageId);

  if (!workflow) {
    return <>Not found.</>;
  }

  return (
    <AppPage page={page} loading={!workflow} title={workflow.name}>
      <StackContainer>
        {!workflow.template ? (
          <SmartBreadcrumbSheet />
        ) : (
          <SmartBreadcrumbSheet
            ignorePath={true}
            onClick={() =>
              goTo(
                location.pathname?.replace(`/${workflow.id || id}/builder`, "")
              )
            }
          />
        )}
        <WorkflowEditorPane id={id} item={workflow} />
      </StackContainer>
    </AppPage>
  );
};

const AddNextStep = ({
  workflow,
  steps,
  mutate,
}: {
  workflow: Workflow;
  steps: Maybe<WorkflowStep[]>;
  mutate: Fn<Update<Entity>[], void>;
}) => {
  const [input, setInput] = useState<RichText>({ html: "" });
  const [focused, setFocused] = useState(false);
  const stepSource = useNestedSource(workflow, "workflow_step");
  const stepProps = useLazyProperties(stepSource);

  const ai = useAiUseCase(workflowStepCreate);
  const createNextStep = useCallback(
    async (p: Maybe<RichText>) => {
      if (!p || isEmpty(p)) {
        showError("Please write what you want to happen next in the workflow.");
        return;
      }

      try {
        const result = await ai.run({
          workflow,
          steps: steps || [],
          prompt: p.html || "",
        });
        mutate(
          map(result, (a) =>
            aiStepToUpdate(workflow, a, stepProps)
          ) as Update<Entity>[]
        );
      } catch (e) {
        showError("Failed to add next step.");
      }
    },
    [ai.run, workflow, steps]
  );

  return (
    <div className={cx(styles.floatingInput, focused && styles.large)}>
      <HStack gap={0}>
        <Icon icon={ai.loading ? SpinnerIcon : Magic} />

        <TextBox
          key="workflow-next-step"
          className={styles.input}
          text={input}
          onChanged={setInput}
          placeholder="What should happen next?"
          submitOnEnter
          blurOnEnter
          onEnter={createNextStep}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </HStack>
    </div>
  );
};
