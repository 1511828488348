import { filter, map, without } from "lodash";
import {
  createContext,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ID } from "@api";

import { Fn, isFunc } from "@utils/fn";
import { Maybe } from "@utils/maybe";
import { ComponentOrNode } from "@utils/react";

import { Button } from "@ui/button";
import { Icon } from "@ui/icon";

import { HStack } from "./flex";
import ShadowPadding from "./shadow-padding";
import { Sheet, StackSheets } from "./sheet-layout";

import styles from "./pane-manager.module.css";

interface PaneItem {
  id: ID;
  title: string;
  icon: ComponentOrNode;
  count?: number;
}

interface PaneManagerState {
  panes: PaneItem[];
  active: ID[];
  setActive: Fn<SetStateAction<ID[]>, void>;
  register: Fn<PaneItem, void>;
  unregister: Fn<ID, void>;
  isVisible: Fn<ID, boolean>;
  setCount: (id: ID, count: number) => void;
}

type PaneItemProps = PaneItem & {
  children: ReactNode;
};

export const PaneItemContext = createContext<Maybe<PaneItem>>(undefined);

export const PaneManagerContext =
  createContext<Maybe<PaneManagerState>>(undefined);

export const usePaneManagerState = (defaultActive?: ID[]): PaneManagerState => {
  const [data, setData] = useState<Pick<PaneManagerState, "panes" | "active">>(
    () => ({
      panes: [],
      active: defaultActive || ["*"],
    })
  );

  const setActive = useCallback(
    (active: SetStateAction<ID[]>) =>
      setData((data) => ({
        ...data,
        active: isFunc(active) ? active(data.active) : active,
      })),
    []
  );

  const setPanes = useCallback(
    (panes: SetStateAction<PaneItem[]>) =>
      setData((data) => ({
        ...data,
        panes: isFunc(panes) ? panes(data.panes) : panes,
      })),
    [setData]
  );

  const isVisible = useCallback(
    (id: string) => data.active?.includes(id) || data.active?.includes("*"),
    [data.active]
  );

  const register = useCallback(
    (pane: PaneItem) => setPanes((panes) => [...panes, pane]),
    [setPanes]
  );

  const unregister = useCallback(
    (id: string) => setPanes((panes) => filter(panes, (p) => p.id !== id)),
    [setPanes]
  );

  const setCount = useCallback(
    (id: ID, count: number) => {
      setPanes((panes) =>
        map(panes, (pane) => (pane.id === id ? { ...pane, count } : pane))
      );
    },
    [setPanes]
  );

  return useMemo(
    () =>
      ({
        ...data,
        setCount,
        setActive,
        register,
        unregister,
        isVisible,
      } as PaneManagerState),
    [data, setData, setCount]
  );
};

export const useSyncPaneCount = (count: Maybe<number>) => {
  const pane = useContext(PaneItemContext);
  const manager = useContext(PaneManagerContext);

  const updateCount = useCallback(
    (count: Maybe<number>) => {
      if (!pane?.id) {
        return;
      }

      manager?.setCount?.(pane?.id, count || 0);
    },
    [manager?.setCount, manager?.panes?.length, pane?.id]
  );

  useEffect(() => updateCount(count), [count, updateCount]);
};

export const PaneItem = ({ children, ...pane }: PaneItemProps) => {
  const { register, unregister, isVisible } =
    useContext(PaneManagerContext) || {};

  useEffect(() => {
    register?.(pane);
    return () => unregister?.(pane.id);
  }, []);

  return (
    <PaneItemContext.Provider value={pane}>
      {!!isVisible?.(pane.id) && children}
    </PaneItemContext.Provider>
  );
};

interface PaneManagerProps {
  size: "primary" | "secondary" | "full";
  children: React.ReactNode;
  defaultActive?: string[];
}

export const PaneManager = ({
  size,
  children,
  defaultActive = ["*"],
}: PaneManagerProps) => {
  const existingState = useContext(PaneManagerContext);
  const newState = usePaneManagerState(defaultActive);
  const state = existingState || newState;

  const toggleActive = useCallback((id: ID) => {
    state.setActive?.((active) =>
      active.includes(id)
        ? // Remove current if already active
          without(active, id)
        : // Add new value
          [id, ...active]
    );
  }, []);

  const hiddenPanes = useMemo(
    () => filter(state.panes, (p) => !state.isVisible(p.id)),
    [state.panes, state.isVisible]
  );

  return (
    <PaneManagerContext.Provider value={state}>
      <Sheet mode="sizing" size={size} height="container">
        <ShadowPadding className={styles.scrollable} padding={16}>
          <StackSheets
            size="full"
            height="container"
            className={styles.container}
          >
            {children}

            {!!hiddenPanes.length && (
              <Sheet className={styles.paneBar} size={size} height="content">
                <HStack gap={4} align="center">
                  {map(hiddenPanes, (pane) => (
                    <Button
                      key={pane.id}
                      className={styles.paneItem}
                      subtle
                      variant={
                        state.isVisible(pane.id) ? "primary" : "secondary"
                      }
                      icon={<Icon size="medium" icon={pane.icon} />}
                      onClick={() => toggleActive(pane.id)}
                      // tooltip={pane.title}
                    />
                  ))}
                </HStack>
              </Sheet>
            )}
          </StackSheets>
        </ShadowPadding>
      </Sheet>
    </PaneManagerContext.Provider>
  );
};
