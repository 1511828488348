import { ReactNode } from "react";

const BREAKS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1550,
};

const useWindowSize = () => {
  return { width: window.innerWidth, height: window.innerHeight };
};

export const BigScreens = ({ children }: { children: ReactNode }) => {
  const { width } = useWindowSize();

  if (width <= BREAKS.xl) return <></>;

  return <>{children}</>;
};

export const useIsLargeScreen = () => {
  const { width } = useWindowSize();
  return width >= BREAKS.xl;
};
