import { filter, map, take } from "lodash";
import { ReactNode, useCallback, useMemo } from "react";

import {
  FileMeta,
  Form,
  FormPropDef,
  PropertyDef,
  PropertyType,
  PropertyValue as PropertyValueType,
  VariableDef,
} from "@api";

import { FormData, isReadonlyField, useFormFields } from "@state/form";
import { useCreateEntity, useLazyEntities } from "@state/generic";
import { useActiveWorkspaceId } from "@state/workspace";

import {
  ensureMany,
  justOne,
  maybeMap,
  omitEmpty,
  OneOrMany,
} from "@utils/array";
import { fromISO, toISODate } from "@utils/date-fp";
import { withHardHandle } from "@utils/event";
import { Fn } from "@utils/fn";
import { equalsAny } from "@utils/logic";
import { Maybe } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { toFieldName, toRef } from "@utils/property-refs";
import { toChildLocation, toScope } from "@utils/scope";
import { withoutStar } from "@utils/wildcards";

import { usePageId } from "@ui/app-page";
import { Banner } from "@ui/banner";
import { Button } from "@ui/button";
import { DateInputPicker } from "@ui/date-picker";
import { EmptyState } from "@ui/empty-state";
import { SpaceBetween, VStack } from "@ui/flex";
import { CheckIcon, CounterIcon } from "@ui/icon";
import { RadioIcon } from "@ui/icon/radio";
import { Field, TextInput } from "@ui/input";
import { LinkInput, LinksInput } from "@ui/input-link";
import { Label } from "@ui/label";
import { CheckMenuItem } from "@ui/menu-item";
import { TextBox } from "@ui/rich-text";
import {
  EntityMultiSelect,
  EntitySelect,
  TagMultiSelect,
  TagSelect,
} from "@ui/select";
import { Tag } from "@ui/tag";
import { Text, TextSmall } from "@ui/text";
import { UploadFileButton } from "@ui/upload-modal";

import { getEngine, render } from "..";

import styles from "./fields.module.css";

type FormFillerProps = {
  form: Form;
  fields?: [FormPropDef, PropertyDef | undefined][];
  data: FormData;
  onChange?: Fn<Partial<FormData>, void>;
};

export const FormFields = ({ form, data, onChange }: FormFillerProps) => {
  const allFields = useFormFields(form);
  // When the form is a template, we don't want any uploaded resources to be
  // saved in the template itself, but rather in the new form that is created
  const fieldScope = useMemo(
    () =>
      form.template ? form.location : toChildLocation(form.location, form.id),
    [form]
  );
  const fields = useMemo(
    () => filter(allFields, (f) => !isReadonlyField(f)),
    [allFields]
  );

  if (!fields?.length) {
    return <EmptyState text="Form not setup yet..." />;
  }

  return (
    <VStack fit="container" gap={30}>
      {maybeMap(fields, ([prop, def]) => (
        <FormField
          key={prop.field}
          prop={prop}
          def={def}
          scope={fieldScope}
          value={{ [prop.type]: data[prop.field] }}
          editable={!!onChange}
          onChange={(value) => onChange?.({ [prop.field]: value })}
        />
      ))}
    </VStack>
  );
};

type FormFieldProps = {
  prop: FormPropDef;
  def: Maybe<PropertyDef | VariableDef>;
  value: PropertyValueType;
  editable?: boolean;
  focus?: boolean;
  onChange: Fn<PropertyValueType[PropertyType], void>;
  scope: string;
};

export const FormField = (props: FormFieldProps) => {
  const { prop, focus, def, editable = true, value, onChange } = props;
  const propType = prop.type;

  const withField = (children: ReactNode) => (
    <Field fit="container">
      <SpaceBetween fit="container">
        <VStack gap={0} fit="content">
          <Label bold>{prop.label || toFieldName(def || prop)}</Label>
          {prop.description && <TextSmall subtle>{prop.description}</TextSmall>}
        </VStack>

        {prop.type === "multi_select" && (
          <TextSmall subtle>Select multiple</TextSmall>
        )}
        {prop.type === "select" && <TextSmall subtle>Select one</TextSmall>}
      </SpaceBetween>
      {children}
    </Field>
  );

  if (propType === "boolean") {
    return withField(
      <CheckMenuItem
        className={styles.control}
        checked={value.boolean ?? false}
        disabled={!editable}
        onChecked={onChange}
        text={value.boolean ? "Yes" : "No"}
      />
    );
  }

  if (propType === "rich_text") {
    return withField(
      <TextBox
        className={styles.control}
        key={prop.field}
        text={value.rich_text}
        disabled={!editable}
        focus={focus}
        size="two-line"
        onChanged={onChange}
      />
    );
  }

  if (propType === "text") {
    return withField(
      <TextInput
        className={styles.control}
        autoFocus={focus}
        disabled={!editable}
        value={value.text || ""}
        onChange={onChange}
      />
    );
  }

  if (
    propType === "relations" &&
    equalsAny(
      "resource",
      ensureMany((def?.options || prop.options)?.references)
    )
  ) {
    return withField(<ResourcesField {...props} />);
  }

  if (propType === "relation") {
    return withField(
      <EntitySelect
        value={value.relation}
        onChange={onChange}
        className={{ trigger: styles.control }}
        type={
          justOne(withoutStar((def?.options || prop.options)?.references)) ||
          "person"
        }
      />
    );
  }

  if (propType === "relations") {
    return withField(
      <EntityMultiSelect
        value={value.relations}
        onChange={onChange}
        className={{ trigger: styles.control }}
        type={
          justOne(withoutStar((def?.options || prop.options)?.references)) ||
          "person"
        }
      />
    );
  }

  if (propType === "select" || propType === "status") {
    return withField(
      <TagSelect
        value={value.select}
        onChange={onChange}
        placeholder="Select..."
        options={def?.values?.[propType] || []}
      >
        {!!value?.[propType] && (
          <Button
            subtle
            className={styles.value}
            icon={<RadioIcon checked={true} />}
          >
            <Tag color={value?.[propType]?.color}>
              {value?.[propType]?.name}
            </Tag>
          </Button>
        )}

        {!value?.[propType] && (
          <VStack gap={4}>
            {map(take(def?.values?.[propType], 5), (v) => (
              <Button
                subtle
                className={styles.value}
                icon={<RadioIcon checked={v.id === value?.[propType]?.id} />}
                onClick={withHardHandle(() => onChange?.(v))}
              >
                <Tag key={v.id} color={v.color}>
                  {v.name}
                </Tag>
              </Button>
            ))}
            {(def?.values?.[propType]?.length || 0) > 5 && (
              <Button
                icon={
                  <CounterIcon
                    count={Math.max(
                      (def?.values?.[propType]?.length || 0) - 5,
                      0
                    )}
                  />
                }
                subtle
                className={styles.value}
              >
                More options
              </Button>
            )}
          </VStack>
        )}
      </TagSelect>
    );
  }

  if (propType === "date") {
    return withField(
      <DateInputPicker
        date={fromISO(value.date)}
        onChanged={(d) =>
          onChange?.(d && toISODate(d, def?.options?.mode || "calendar"))
        }
      />
    );
  }

  if (propType === "multi_select") {
    const selectedIds = map(value.multi_select || [], (v) => v.id);
    return withField(
      <TagMultiSelect
        value={value.multi_select}
        onChange={onChange}
        placeholder="Select..."
        className={{ trigger: styles.value }}
        options={def?.values?.multi_select || []}
      >
        <VStack gap={4}>
          {map(
            [
              ...take(def?.values?.[propType], 5),
              ...filter(
                def?.values?.[propType],
                (s, i) => i > 5 && equalsAny(s.id, selectedIds)
              ),
            ],
            (v) => (
              <Button
                subtle
                className={styles.value}
                icon={<CheckIcon checked={equalsAny(v.id, selectedIds)} />}
                onClick={withHardHandle(() =>
                  onChange?.([...(value.multi_select || []), v])
                )}
              >
                <Tag key={v.id} color={v.color}>
                  {v.name}
                </Tag>
              </Button>
            )
          )}
          {(def?.values?.[propType]?.length || 0) > 5 && (
            <Button
              icon={
                <CounterIcon
                  count={Math.max(
                    (def?.values?.[propType]?.length || 0) - 5,
                    0
                  )}
                />
              }
              subtle
              className={styles.value}
            >
              More options
            </Button>
          )}
        </VStack>
      </TagMultiSelect>
    );
  }

  if (propType === "number") {
    return withField(
      <TextInput
        inputType="number"
        className={styles.control}
        value={value.number?.toString() || ""}
        autoFocus={focus}
        disabled={!editable}
        onChange={(v) => onChange?.(Number(v))}
        placeholder="Enter a number..."
      />
    );
  }

  if (propType === "link") {
    return withField(
      <LinkInput
        className={styles.control}
        link={value.link}
        onChanged={onChange}
      />
    );
  }

  if (propType === "links") {
    return withField(
      <LinksInput
        className={styles.control}
        links={value.links}
        onChanged={onChange}
      />
    );
  }

  return withField(
    <Banner variant="rounded" color="red">
      Unsupported field.
    </Banner>
  );
};

const ResourcesField = ({ scope, value, onChange }: FormFieldProps) => {
  const wID = useActiveWorkspaceId();
  const pageId = usePageId();
  const goTo = useGoTo();
  const resources = useLazyEntities<"resource">(value.relations);
  const engine = getEngine("resource");
  const source = useMemo(() => ({ scope, type: "resource" }), [scope]);
  const createResource = useCreateEntity(
    "resource",
    scope || toScope(wID),
    pageId
  );

  const onUploaded = useCallback(
    (files: OneOrMany<FileMeta>) => {
      // Create resources from file meta
      const newResources = maybeMap(ensureMany(files), (f) =>
        toRef(
          createResource(
            omitEmpty([
              { field: "name", type: "text", value: { text: f.name } },
              { field: "path", type: "text", value: { text: f.path } },
              { field: "url", type: "text", value: { text: f.url } },
              { field: "mimeType", type: "text", value: { text: f.mimeType } },
              { field: "type", type: "text", value: { text: "file" } },
            ])
          )
        )
      );

      onChange?.([...(value.relations || []), ...newResources]);
    },
    [value.relations, createResource]
  );

  if (!source) {
    return <></>;
  }

  return (
    <VStack>
      <VStack gap={2} fit="container" wrap>
        {map(resources, (u) =>
          render(engine.asListItem, {
            key: u.id,
            item: u,
            onOpen: goTo,
          })
        )}
      </VStack>

      <UploadFileButton
        scope={scope}
        onUploaded={onUploaded}
        fit="container"
        className={styles.control}
      >
        <Text subtle>Upload</Text>
      </UploadFileButton>
    </VStack>
  );
};
