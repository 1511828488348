import { last, map } from "lodash";
import { ReactNode, useCallback, useMemo, useState } from "react";

import {
  Entity,
  FileMeta,
  HasBody,
  hasBody,
  HasRefs,
  HasResources,
  Ref,
  RelationRef,
  RichText,
  toTitleOrName,
} from "@api";

import {
  useLazyEntity,
  useNestedSource,
  useUpdateEntity,
} from "@state/generic";
import { useLazyPropertyDef } from "@state/properties";
import { useAttachFile } from "@state/resources";
import { useEntityLabels } from "@state/settings";
import { toTemplateViewId, useLazyItemsForView } from "@state/views";

import { ensureMany, justOne, OneOrMany } from "@utils/array";
import { composel, Fn } from "@utils/fn";
import { Maybe, safeAs, when } from "@utils/maybe";
import { useGoTo, usePushTo } from "@utils/navigation";
import { asMutation } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { append, isEmpty, isEqual, toHtml } from "@utils/rich-text";
import { fromScope } from "@utils/scope";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { CollapsibleSection } from "@ui/collapsible-section";
import { Container } from "@ui/container";
import { CreatePageButton } from "@ui/engine/page";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { DocumentFilled, MagicPurple } from "@ui/icon";
import { Props as LabelProps } from "@ui/label";
import { ReadonlyDocument } from "@ui/rich-text";
import { ColoredSection } from "@ui/section";
import { Text, TextSmall } from "@ui/text";

import { AIAutoBrief } from "./ai-auto-brief";
import { RealTimeDocumentEditor } from "./real-time-field";
import { NoSelectable } from "./selectable-items";
import { UploadFileButton } from "./upload-modal";

interface Props {
  entity: RelationRef;
  body: Maybe<RichText>;
  onBodyChanged?: Fn<RichText, void>;
  editable?: boolean;
  className?: string;
  placeholder?: string;
  showAll?: boolean;
  newLineSpace?: "small" | "large" | "xlarge";
}

type SectionProps = Props & {
  label?: string | ReactNode;
  labelSize?: LabelProps["size"];
};

export const PageBody = ({
  body,
  onBodyChanged,
  entity: ref,
  placeholder = "Write something...",
  className,
  editable,
  showAll: _showAll = true,
  newLineSpace = "large",
}: Props) => {
  const pageId = usePageId();
  const goTo = useGoTo();
  const pushTo = usePushTo();

  const mutate = useUpdateEntity(ref.id);
  const entity = useLazyEntity(ref.id);
  const nested = useNestedSource(entity);
  const toEntityLabel = useEntityLabels(entity?.source.scope, {
    case: "title",
    plural: false,
  });
  const onFileUploaded = useAttachFile(entity as Maybe<HasResources>, pageId);

  const nestedPageViewId = useMemo(
    () =>
      toTemplateViewId("nested-pages", {
        for: ref.id,
        parent: ref.id,
      }),
    [ref?.id]
  );
  const nestedPages = useLazyItemsForView(nestedPageViewId);

  const [showAutoBrief, setShowAutoBrief] = useState(false);
  const def = useLazyPropertyDef(entity?.source, {
    field: "body",
    type: "rich_text",
  });
  const childScope = useNestedSource(entity);

  const handleChanged = useCallback(
    (rt: RichText) => {
      // Hasn't changed
      if (isEqual(rt, body)) {
        return;
      }

      if (onBodyChanged) {
        onBodyChanged(rt);
      } else {
        mutate(asMutation({ field: "body", type: "rich_text" }, rt));
      }
    },
    [mutate, body, onBodyChanged]
  );

  const handleFileUploaded = useCallback(
    (files: OneOrMany<FileMeta>) => {
      const markup = map(ensureMany(files), (file) =>
        file.mimeType?.startsWith("image/")
          ? `<embed data-embed-type="image" data-embed-title="${file.name}" data-embed-url="${file.url}">`
          : `<p><embed data-embed-type="link" data-embed-title="${file.name}" data-embed-url="${file.url}" /></p>`
      )?.join("");

      handleChanged(append(body, { html: markup }));
      onFileUploaded(files);
    },
    [onFileUploaded]
  );

  const handleNewPage = useCallback(
    (ref: Ref) => {
      const markup = `<a data-mention-id=${ref.id}>${
        when(safeAs<Entity>(ref), toTitleOrName) || ""
      }</a>`;
      handleChanged({ html: (when(body, toHtml) || "") + markup });
    },
    [goTo]
  );

  return (
    <VStack className={className} fit="container" gap={0}>
      <RealTimeDocumentEditor
        key={ref.id}
        entity={ref.id}
        field="body"
        editable={editable}
        scope={childScope?.scope}
        newLineSpace={newLineSpace}
        placeholder={placeholder}
        content={body}
        onChanged={handleChanged}
      />
      {showAutoBrief && def && nested && (
        <AIAutoBrief
          original={body}
          prop={def}
          source={nested}
          onDismiss={() => setShowAutoBrief(false)}
          onSaved={(rt) => {
            handleChanged(rt);
            setShowAutoBrief(false);
          }}
        />
      )}

      {childScope && (
        <SpaceBetween>
          <HStack fit="container">
            {!!nestedPages?.items.all?.length && (
              <Button
                size="small"
                variant="secondary"
                subtle
                icon={DocumentFilled}
                onClick={() => pushTo(nestedPageViewId)}
              >
                {nestedPages?.items?.all?.length} nested{" "}
                {toEntityLabel("page", {
                  case: "lower",
                  plural: nestedPages?.items?.all?.length > 1,
                })}
              </Button>
            )}
          </HStack>

          <HStack justify="flex-end" fit="container" gap={0}>
            <Button
              size="small"
              subtle
              icon={MagicPurple}
              onClick={() => setShowAutoBrief(true)}
            >
              <Text subtle>Doc Assist</Text>
            </Button>

            <UploadFileButton
              scope={childScope?.scope}
              onUploaded={handleFileUploaded}
            >
              <Text subtle>Upload files</Text>
            </UploadFileButton>

            <CreatePageButton
              scope={childScope.scope}
              onCreated={handleNewPage}
            >
              <Text subtle>New {toEntityLabel("page")}</Text>
            </CreatePageButton>
          </HStack>
        </SpaceBetween>
      )}
    </VStack>
  );
};

export const PageBodySection = ({
  label: sectionLabel,
  labelSize = "medium",
  entity: ref,
  ...props
}: SectionProps) => {
  const entity = useLazyEntity(ref?.id);
  const goTo = useGoTo();
  const parentRef = useMemo(() => {
    const ref =
      when(safeAs<HasRefs>(entity)?.refs?.parent, justOne) ||
      when(entity?.source.scope, composel(fromScope, last, toRef));

    // Only show parent documentation for certain parent types
    // if (!!ref?.id && equalsAny(ref.id, ["task", "outcome", "content"])) {
    return ref;
    // }

    return undefined;
  }, [entity]);
  const parent = useLazyEntity(parentRef?.id);
  const [showParent, setShowParent] = useState(false);

  return (
    <>
      <NoSelectable>
        <CollapsibleSection
          title={sectionLabel || "Documentation"}
          labelSize={labelSize}
        >
          <PageBody {...props} entity={entity as HasBody} />
          {parent && hasBody(parent) && !isEmpty(parent.body) && (
            <>
              <ColoredSection>
                <Container inset="vertical" padding="none">
                  <SpaceBetween align="center">
                    <TextSmall subtle>
                      More information from{" "}
                      <Button variant="link" onClick={() => goTo(parent)}>
                        {toTitleOrName(parent)}
                      </Button>
                    </TextSmall>

                    <Button
                      size="small"
                      subtle
                      onClick={() => setShowParent(!showParent)}
                    >
                      {showParent ? "Hide" : "Show"}
                    </Button>
                  </SpaceBetween>
                </Container>
                {showParent && (
                  <ReadonlyDocument editable={false} content={parent.body} />
                )}
              </ColoredSection>
            </>
          )}
        </CollapsibleSection>
      </NoSelectable>
    </>
  );
};
