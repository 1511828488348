import { isArray, isDate, isEmpty, isObject, keys, map, reduce } from "lodash";

import * as Api from "@api/types";

import * as Graph from "@graph/types";

import { OneOrMany } from "./array";
import { JsonArray, JsonObject } from "./json";
import { switchEnum } from "./logic";
import { isDefined, Maybe } from "./maybe";
import { sentenceCase } from "./string";
import { PartialWithoutNulls, setDirty } from "./object";

type PropertyDef = Api.PropertyDef<Api.Task>;
// type PropertyDef = Graph.PropertyDef | Api.PropertyDef<Api.Task>;

export const toAIField = (p: PropertyDef | Api.PropertyValueRef): string =>
  (p as { label: Maybe<string> }).label ||
  (p as Api.PropertyValueRef)?.def?.label ||
  sentenceCase(p.field);

export type PropertyMap = Record<
  string,
  Maybe<OneOrMany<{ id?: string; name?: string }>>
>;

const formatTagValues = (p: PropertyDef): PropertyMap => ({
  [p.field]: reduce(
    [
      ...(p.values?.[p.type as Api.PropertyType] as Api.Ref[]),
      { id: "null", name: "Unknown" },
    ],
    (res, v) => ({
      ...res,
      [v.id]: v.name,
    }),
    {}
  ),
});

export const toAiPropertyDef = (props: PropertyDef[]): PropertyMap =>
  reduce(
    props,
    (res, p) =>
      switchEnum(p.type as Graph.PropertyType, {
        status: () => ({ ...res, ...formatTagValues(p) }),
        select: () => ({ ...res, ...formatTagValues(p) }),
        multi_select: () => ({ ...res, ...formatTagValues(p) }),
        relation: () => ({ ...res, ...formatTagValues(p) }),
        relations: () => ({ ...res, ...formatTagValues(p) }),
        else: () => res,
      }),
    {} as PropertyMap
  );

export const toAiPropertyValue = (props: Api.PropertyValueRef[]): PropertyMap =>
  reduce(
    props,
    (res, p) =>
      switchEnum(p.type as Graph.PropertyType, {
        select: () => ({
          ...res,
          [toAIField(p)]: {
            id: p.value?.select?.id,
            name: p.value?.select?.name,
          },
        }),
        relation: () => ({
          ...res,
          [toAIField(p)]: {
            id: p.value?.relation?.id,
            name: p.value?.relation?.name,
          },
        }),
        relations: () => ({
          ...res,
          [toAIField(p)]: map(p.value?.relations, (r) => ({
            id: r?.id,
            name: r?.name,
          })),
        }),
        multi_select: () => ({
          ...res,
          [toAIField(p)]: map(p.value?.multi_select, (r) => ({
            id: r?.id,
            name: r?.name,
          })),
        }),
        else: () => res,
      }),
    {} as PropertyMap
  );

export const toLLMFriendlyFormat = (
  object: JsonObject | JsonArray,
  indent: string = ""
): string => {
  if (isArray(object)) {
    return object
      .map((val) =>
        isObject(val) && !isDate(val)
          ? toLLMFriendlyFormat(val, addTab(indent))
          : JSON.stringify(val)?.replace(/"/g, "")
      )
      .join("\n");
  }

  return Object.keys(object)
    .map((key) => {
      const val = object[key];
      if (isObject(val) && !isDate(val)) {
        return `${indent}${key}:\n${toLLMFriendlyFormat(val, addTab(indent))}`;
      }

      return `${indent}${key}: ${
        val ? JSON.stringify(val)?.replace(/"/g, "") : ""
      }`;
    })
    .join("\n");
};

// Calculates the indentation (strings or tabs) based on the first line
// and then removes the same indentation from all lines.
export const trimIndents = (str: string): string => {
  // Split string into lines
  const lines = str.split("\n");

  // Find the first non-empty line
  const firstNonEmptyLine = lines.find((line) => line.trim().length > 0);

  // If there are no non-empty lines, return original string
  if (!firstNonEmptyLine) {
    return str;
  }

  // Calculate leading whitespace
  const leadingWhitespace = firstNonEmptyLine.match(/^[ \t]*/)?.[0] || "";

  // If there's no leading whitespace, return original string
  if (leadingWhitespace.length === 0) {
    return str;
  }

  // Create a regex to match the indentation at the beginning of each line
  const indentRegex = new RegExp(`^${leadingWhitespace}`);

  // Remove the indentation from each line
  const trimmedLines = lines.map((line) => {
    // Only remove indentation from non-empty lines
    if (line.trim().length === 0) {
      return line;
    }
    return line.replace(indentRegex, "");
  });

  // Join the lines back together
  return trimmedLines.join("\n");
};

// Used for intenting lines for an llm. Adds an additional tab to the string start
export const addTab = (str: Maybe<string>): string => `  ${str || ""}`;

export const toSingleLine = (str: string): string => str.replace(/\n/g, " ");

export const omitUnuseful = <T, K extends keyof T>(
  obj: T,
  blacklist?: Set<string>
): PartialWithoutNulls<T> =>
  reduce(
    keys(obj) as K[],
    (v: PartialWithoutNulls<T>, key: K) => {
      if (blacklist?.has(String(key))) {
        return v;
      }

      const val = obj[key];
      if (isDefined(val) && !isEmpty(val)) {
        if (isObject(val) && !isDate(val)) {
          const inner = omitUnuseful(val, blacklist);

          if (!isEmpty(inner)) {
            // @ts-ignore
            v[key] = inner;
          }
        } else {
          // @ts-ignore
          setDirty(v, key, val);
        }
      }
      return v;
    },
    {} as PartialWithoutNulls<T>
  );
