import { filter, orderBy, reduce } from "lodash";
import { atomFamily, selector, selectorFamily } from "recoil";

import { ID, Job } from "@api";

import { appendKey, localStorageEffect } from "@state/local-storage-effect";
import { SimpleStoreState } from "@state/store";
import { ActiveUserAtom, WorkspaceWrappedAtom } from "@state/workspace";

import { ISODate, usePointDate } from "@utils/date-fp";
import { use } from "@utils/fn";
import { newID } from "@utils/id";
import { switchEnum } from "@utils/logic";
import { Maybe } from "@utils/maybe";
import { maybeValues } from "@utils/object";
import { isDefault } from "@utils/recoil";
import { minutesAgo } from "@utils/time";

import { clearJob, setJob } from "./actions";

export type JobStore = SimpleStoreState<Job> & {
  lastChecked: Maybe<ISODate>;
  claiming?: Job;
  running?: Job;
};

export const WorkspaceJobStoreAtom = atomFamily<JobStore, ID>({
  key: "WorkspaceJobStoreAtom",
  default: {
    dirty: [],
    lookup: {},
    lastChecked: undefined,
    claiming: undefined,
    running: undefined,
  },
  effects: (wid) => [
    localStorageEffect<JobStore>({
      key: appendKey("traction.store.job", wid),
      // Don't load/read running/claiming jobs from local storage
      props: ["lookup", "dirty", "lastChecked"],
      default: {
        dirty: [],
        lookup: {},
        lastChecked: undefined,
        claiming: undefined,
        running: undefined,
      },
      clean: (state) => ({
        ...state,
        lookup: reduce(
          state.lookup,
          (acc, v) =>
            !v ||
            ["completed", "failed"]?.includes(v.status || "never") ||
            (v.status === "running" &&
              usePointDate(v.lockedAt, (d) => minutesAgo(d) > 10))
              ? acc
              : { ...acc, [v.id]: v },
          {}
        ),
      }),
    }),
  ],
});

export const JobStoreAtom = WorkspaceWrappedAtom(
  "JobStoreAtom",
  WorkspaceJobStoreAtom
);

export const JobAtom = selectorFamily<Maybe<Job>, ID>({
  key: "JobAtom",
  get:
    (id) =>
    ({ get }) => {
      const store = get(JobStoreAtom);
      return store.lookup[id];
    },
  set:
    (id) =>
    ({ set }, newValue) => {
      set(
        JobStoreAtom,
        !newValue || isDefault(newValue) ? clearJob(id) : setJob(newValue)
      );
    },
});

export const allJobs = selector({
  key: "allJobs",
  get: ({ get }) => {
    const store = get(JobStoreAtom);
    return maybeValues(store.lookup, (v) => v.status !== "completed");
  },
});

export const MyJobQueueAtom = selectorFamily({
  key: "JobQueueAtom",
  get:
    ({ isIdle, lockKey }: { isIdle: boolean; lockKey: string }) =>
    ({ get }) => {
      const jobs = get(allJobs);
      const me = get(ActiveUserAtom);

      if (!me) return [];

      const available = filter(jobs, (j) =>
        switchEnum(j.status || "", {
          // Always return jobs that have been claimed by this tab
          running: () => j.lockedBy?.id === me.id && j.lockKey === lockKey,

          queued:
            // If app is idle
            isIdle &&
            // If the job is created by me (take immediately, otherwise wait until 5 mins old)
            // This prevents people from claiming each others jobs before they can
            (j.createdBy?.id === me.id ||
              usePointDate(j.createdAt, (d) => minutesAgo(d) > 2)),

          // Else not for me
          else: false,
        })
      );

      return orderBy(
        // Unclaimed jobs or running jobs claimed by me
        available,
        (j) =>
          use(
            switchEnum(j.status, {
              running: 1,
              queued: 2,
              else: 3,
            }),
            (index) => `${index}-${j.createdAt}`
          ),
        "asc"
      );
    },
});
