import { difference, join, map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { setRecoil } from "recoil-nexus";

import {
  DatabaseID,
  Entity,
  EntityType,
  FilterQuery,
  ID,
  Project,
  PropertyDef,
  PropertyType,
  View,
} from "@api";
import { EntityForType } from "@api/mappings";

import {
  GlobalFetchOptionsAtom,
  lastFetchedAt,
  useLazyFetchResults,
  useOnFetchResults,
} from "@state/fetch-results";
import { getStore } from "@state/generic";
import { useInstalledEntities } from "@state/packages";
import { setItems } from "@state/store";
import { useActiveWorkspaceId } from "@state/workspace";

import { indexById } from "@utils/array";
import { useAsyncEffect } from "@utils/effects";
import { append, transformAll } from "@utils/filtering";
import { Maybe } from "@utils/maybe";
import { maybeValues } from "@utils/object";
import { asRelationValue, isAnyRelation } from "@utils/property-refs";
import { hashable } from "@utils/serializable";

import { getOptimizedTemplatesLoader } from "./queries";
import { allTemplates, templatesForSource } from "./selectors";

type TemplateInfo<T> = {
  id: string;
  name: string;
  icon?: string;
  description?: string;
  image?: URL;
  tags?: string[];
  search?: string;
};

type Template<T> = TemplateInfo<T> & {
  template: T;
};

type ProjectWithViews = {
  project: Partial<Project>;
  views: Partial<View>[];
};

export const allPropertyTemplates = (): Template<
  Partial<PropertyDef<Entity, PropertyType>>
>[] => [
  {
    id: "priority-moscow",
    name: "MoSCoW Prioritization",
    description: "Must, Should, Could, Won't Have",
    tags: ["prioritization", "agile", "startup"],
    template: {
      label: "Priority",
      type: "select",
      field: "priority",
      locked: true,
      values: {
        select: [
          { id: "MUST", name: "Must have", color: "green" },
          { id: "SHOULD", name: "Should have", color: "green" },
          { id: "COULD", name: "Could have", color: "blue" },
          { id: "Wont", name: "Won't have", color: "yellow" },
        ],
      },
    },
  },
  {
    id: "content-format",
    name: "Content Format",
    description: "Email, SMS, Push, etc.",
    tags: ["prioritization", "agile", "startup"],
    template: {
      field: "format",
      type: "select",
      values: {
        select: [
          {
            id: "EML",
            name: "Email",
            color: "default",
          },
          {
            id: "PSH",
            name: "Push Notification",
            color: "default",
          },
          {
            id: "SMS",
            name: "SMS",
            color: "default",
          },
          {
            id: "BLG",
            name: "Blog Post",
            color: "default",
          },
          {
            id: "ART",
            name: "Article",
            color: "default",
          },
          {
            id: "SOC",
            name: "Social Post",
            color: "default",
          },
          {
            id: "VID",
            name: "Video",
            color: "default",
          },
          {
            id: "GRH",
            name: "Graphic",
            color: "default",
          },
          {
            id: "ADV",
            name: "Ad",
            color: "default",
          },
        ],
      },
    },
  },
  {
    id: "priority-hml",
    name: "High-Low",
    description: "High, Medium, Low",
    tags: ["prioritization", "agile", "startup"],
    template: {
      label: "Priority",
      type: "select",
      field: "priority",
      locked: true,
      values: {
        select: [
          { id: "MUST", name: "Must have", color: "green" },
          { id: "SHOULD", name: "Should have", color: "green" },
          { id: "COULD", name: "Could have", color: "blue" },
          { id: "Wont", name: "Won't have", color: "yellow" },
        ],
      },
    },
  },
  {
    id: "shirts",
    name: "T-Shirt Sizes",
    description: "X-Small -> X-Large",
    tags: ["prioritization", "agile", "startup"],
    template: {
      label: "Size",
      type: "select",
      field: "size",
      locked: true,
      values: {
        select: [
          { id: "XXL", name: "XX-Large", color: "gray" },
          { id: "XL", name: "X-Large", color: "gray" },
          { id: "L", name: "Large", color: "gray" },
          { id: "M", name: "Medium", color: "gray" },
          { id: "S", name: "Small", color: "gray" },
          { id: "XS", name: "X-Small", color: "gray" },
          { id: "XXS", name: "XX-Small", color: "gray" },
        ],
      },
    },
  },
  {
    id: "bms",
    name: "Size",
    description: "Big, Medium, Small",
    tags: ["prioritization", "agile", "startup"],
    template: {
      label: "Size",
      type: "select",
      field: "size",
      locked: true,
      values: {
        select: [
          { id: "HGE", name: "Huge", color: "gray" },
          { id: "BIG", name: "Big", color: "gray" },
          { id: "MED", name: "Medium", color: "gray" },
          { id: "SML", name: "Small", color: "gray" },
          { id: "TIN", name: "Tiny", color: "gray" },
        ],
      },
    },
  },
  {
    id: "story-points",
    name: "Story Points",
    description: "1, 3, 5, 8",
    tags: ["prioritization", "agile", "startup"],
    template: {
      label: "Story Points",
      type: "select",
      field: "effort",
      locked: true,
      values: {
        select: [
          { id: "1", name: "1", color: "gray" },
          { id: "3", name: "3", color: "gray" },
          { id: "5", name: "5", color: "gray" },
          { id: "8", name: "8", color: "gray" },
        ],
      },
    },
  },
  {
    id: "effort",
    name: "Effort",
    description: "Estimates development effort",
    tags: ["prioritization", "estimation"],
    template: {
      label: "Effort",
      type: "select",
      field: "effort",
      locked: true,
      values: {
        select: [
          { id: "QUIWIN", name: "Quick Win", color: "green" },
          { id: "MEDEFF", name: "Medium Effort", color: "yellow" },
          { id: "HIEFF", name: "High Effort", color: "red" },
        ],
      },
    },
  },
  {
    id: "value",
    name: "Business Value",
    description: "Estimates business value",
    tags: ["prioritization", "estimation"],
    template: {
      label: "Value",
      type: "select",
      field: "value",
      locked: true,
      values: {
        select: [
          { id: "H", name: "High Value", color: "green" },
          { id: "M", name: "Medium Value", color: "yellow" },
          { id: "L", name: "Low Value", color: "red" },
        ],
      },
    },
  },
  {
    id: "risk",
    name: "Risk Level",
    description: "Estimates risk level",
    tags: ["prioritization", "risk"],
    template: {
      label: "Risk",
      type: "select",
      field: "risk",
      locked: true,
      values: {
        select: [
          { id: "H", name: "High Risk", color: "red" },
          { id: "M", name: "Medium Risk", color: "yellow" },
          { id: "L", name: "Low Risk", color: "green" },
        ],
      },
    },
  },
  {
    id: "user-impact",
    name: "User Impact",
    description: "Estimates user impact",
    tags: ["prioritization", "ux"],
    template: {
      label: "User Impact",
      type: "select",
      field: "user_impact",
      locked: true,
      values: {
        select: [
          { id: "H", name: "High Impact", color: "green" },
          { id: "M", name: "Medium Impact", color: "yellow" },
          { id: "L", name: "Low Impact", color: "red" },
        ],
      },
    },
  },
  {
    id: "quarter",
    name: "Quarter",
    description:
      "Useful for planning out which quarter work should be completed in.",
    tags: ["timeline"],
    template: {
      label: "Quarter",
      type: "select",
      field: "quarter",
      locked: true,
      values: {
        select: [
          { id: "Q1", name: "Q1" },
          { id: "Q2", name: "Q2" },
          { id: "Q3", name: "Q3" },
          { id: "Q4", name: "Q4" },
        ],
      },
    },
  },
  {
    id: "size",
    name: "Size",
    description: "Estimates size",
    tags: ["estimation"],
    template: {
      label: "Size",
      type: "select",
      field: "size",
      locked: true,
      values: {
        select: [
          { id: "S", name: "Small" },
          { id: "M", name: "Medium" },
          { id: "L", name: "Large" },
        ],
      },
    },
  },
];

export const allProjectTemplates = (): Template<
  Partial<ProjectWithViews>
>[] => [];

export const allViewTemplates = indexById([
  {
    id: "team-task",
    name: "Team Tasks",
    tags: [],
    template: {
      name: "Tasks",
      layout: "table",
      entity: "task",
      settings: { collapse: true },
      sort: [{ field: "updatedAt", type: "date", direction: "desc" }],
      filter: {
        field: "status",
        type: "status",
        op: "equals",
        values: {
          status: [{ group: "in-progress" }, { group: "not-started" }],
        },
      },
    },
  },

  {
    id: "today-meetings",
    name: "Starting Soon",
    tags: [],
    template: {
      name: "Starting Soon",
      layout: "list",
      entity: "meeting",
      settings: {
        calStart: { field: "start", type: "date" },
        weekends: true,
      },
      sort: [{ field: "start", type: "date", direction: "asc" }],
      filter: {
        and: [
          {
            field: "start",
            type: "date",
            op: "after",
            value: { formula: "=today" },
          },
          {
            field: "start",
            type: "date",
            op: "before",
            value: { formula: "=today+1d" },
          },
        ],
      },
    },
  },
  {
    id: "team-company",
    name: "Team Companies",
    tags: [],
    template: {
      name: "All",
      layout: "table",
      entity: "company",
      sort: [{ field: "name", type: "text", direction: "asc" }],
    },
  },

  {
    id: "team-contact",
    name: "Team Contacts",
    tags: [],
    template: {
      name: "All",
      layout: "table",
      entity: "contact",
      sort: [{ field: "name", type: "text", direction: "asc" }],
    },
  },

  {
    id: "team-schedule",
    name: "Team Recurring Schedules",
    tags: [],
    template: {
      name: "Recurring Schedules",
      layout: "list",
      entity: "schedule",
      sort: [{ field: "name", type: "text", direction: "asc" }],
    },
  },
  {
    id: "team-backlog",
    name: "Team Backlogs",
    tags: [],
    template: {
      name: "Backlogs",
      layout: "card",
      entity: "backlog",
    },
  },
  {
    id: "team-pipeline",
    name: "Team Pipeline",
    tags: [],
    template: {
      name: "Pipeline",
      layout: "card",
      entity: "pipeline",
    },
  },
  {
    id: "team-roadmap",
    name: "Team Roadmaps",
    tags: [],
    template: {
      name: "Roadmaps",
      layout: "card",
      entity: "roadmap",
    },
  },
  {
    id: "team-calendar",
    name: "Team Calendars",
    tags: [],
    template: {
      name: "Calendars",
      entity: "calendar",
      layout: "card",
    },
  },
  {
    id: "team-sprint",
    name: "Team Sprints",
    tags: [],
    template: {
      name: "Sprints",
      layout: "timeline",
      entity: "sprint",
      sort: [{ field: "start", type: "date", direction: "asc" }],
    },
  },
  {
    id: "team-process",
    name: "Team Processes",
    tags: [],
    template: {
      name: "All",
      layout: "list",
      entity: "process",
      showProps: [{ field: "custom.category", type: "multi_select" }],
      sort: [
        { field: "custom.category", type: "multi_select", direction: "asc" },
      ],
      grouping: "rows",
      group: [
        { field: "custom.category", type: "multi_select", direction: "asc" },
      ],
    },
  },
  {
    id: "team-form",
    name: "Team Forms",
    tags: [],
    template: {
      name: "All",
      layout: "list",
      entity: "form",
      settings: {
        templates: true,
      },
    },
  },
  {
    id: "team-page",
    name: "Team Pages",
    tags: [],
    template: {
      name: "Browse",
      layout: "browser",
      entity: "page",
    },
  },
  {
    id: "team-deal",
    name: "Team Deals",
    tags: [],
    template: {
      name: "Deal Browser",
      layout: "browser",
      entity: "deal",
    },
  },

  {
    id: "nested-pages",
    name: "Nested Pages",
    tags: [],
    template: {
      name: "Nested",
      layout: "preview",
      entity: "page",
      settings: {
        hideNested: false,
        showSubs: true,
      },
    },
  },

  {
    id: "my-private-docs",
    name: "My Notepad",
    tags: ["personal"],
    template: {
      name: "Notepad",
      entity: "page",
      layout: "preview",
      settings: {
        hideNested: false,
        showSubs: true,
      },
      sort: [{ field: "updatedAt", type: "date", direction: "desc" }],
      order: 1,
    },
  },

  {
    id: "task-task",
    name: "Default Subtasks",
    tags: [],
    template: {
      name: "Subtasks",
      layout: "preview",
      entity: "task",
    },
  },

  {
    id: "default-list",
    name: "Default list",
    tags: [],
    template: {
      name: "",
      layout: "list",
      entity: "task",
      settings: {},
      order: 1,
      showProps: [],
    },
  },

  {
    id: "form-submissions",
    name: "Recent Submissions",
    tags: [],
    template: {
      name: "Recent Submissions",
      layout: "list",
      group: [
        { field: "createdAt", type: "date", format: "day", sort: "desc" },
      ],
      sort: [{ field: "createdAt", type: "date", direction: "desc" }],
      filter: {
        field: "createdAt",
        type: "date",
        op: "after",
        value: { formula: "=today-90d" },
      },
    },
  },

  {
    id: "content-list",
    name: "Content List",
    tags: [],
    template: {
      name: "By Date",
      layout: "table",
      entity: "content",
      order: 2,
      sort: [{ field: "publish", direction: "asc" }],
      showProps: [],
      filter: {
        field: "status",
        type: "status",
        op: "does_not_equal",
        value: { status: { group: "done" } },
      },
    },
  },

  {
    id: "content-canvas",
    name: "Content canvas",
    tags: [],
    template: {
      layout: "canvas",
      entity: "content",
      showProps: [],
      settings: {
        canvasBy: { field: "refs.blockedBy", type: "relations" },
      },
    },
  },

  {
    id: "content-cal",
    name: "Content Cal",
    tags: [],
    template: {
      layout: "calendar",
      entity: "content",
      showProps: [],
      settings: {
        calStart: { field: "publish", type: "date" },
      },
    },
  },

  {
    id: "team-event",
    name: "Team Events",
    tags: [],
    template: {
      name: "Events",
      layout: "calendar",
      entity: "event",
      settings: {
        calStart: { field: "start", type: "date" },
        calEnd: { field: "end", type: "date" },
      },
    },
  },

  {
    id: "team-outcome",
    name: "Team Outcomes",
    tags: [],
    template: {
      name: "Outcomes",
      layout: "list",
      grouping: "rows",
      entity: "outcome",
      group: [
        {
          field: "status",
          type: "status",
          ordering: [],
          alwaysHide: [],
          alwaysShow: ["ONTR", "OFTR", "ATRK"],
          hideEmpty: true,
        },
      ],
      filter: {
        or: [
          {
            field: "status",
            type: "status",
            op: "does_not_equal",
            value: { status: { group: "done" } },
          },
          {
            field: "updatedAt",
            type: "date",
            op: "after",
            value: { formula: "=today-30d" },
          },
        ],
      },
    },
  },

  {
    id: "team-outcome-current",
    name: "Team Outcomes",
    tags: [],
    template: {
      name: "",
      layout: "list",
      grouping: "rows",
      entity: "outcome",
      group: [
        {
          field: "status",
          type: "status",
          ordering: [],
          alwaysHide: [],
          alwaysShow: ["ONTR", "OFTR", "ATRK"],
          hideEmpty: true,
        },
      ],
      filter: {
        field: "status",
        type: "status",
        op: "equals",
        value: { status: { group: "in-progress" } },
      },
    },
  },

  {
    id: "team-workflow",
    name: "Team Workflows",
    tags: [],
    template: {
      name: "Workflows",
      layout: "card",
      entity: "workflow",
      filter: {
        field: "status",
        type: "status",
        op: "equals",
        value: { status: { group: "in-progress" } },
      },
      sort: [{ field: "stamps.lastRun.at", type: "date", direction: "desc" }],
    },
  },

  {
    id: "team-project",
    name: "Team Projects",
    tags: [],
    template: {
      name: "Projects",
      layout: "table",
      entity: "project",
    },
  },
  {
    id: "team-campaign",
    name: "Team Campaigns",
    tags: [],
    template: {
      name: "Campaigns",
      layout: "table",
      entity: "campaign",
      sort: [{ field: "start", direction: "asc" }],
    },
  },

  {
    id: "person-task",
    name: "Person Tasks",
    tags: [],
    template: {
      name: "Tasks",
      layout: "card",
      entity: "task",
      showProps: [],
    },
  },

  {
    id: "person-outcome",
    name: "Person Outcomes",
    tags: [],
    template: {
      name: "All Outcomes",
      layout: "list",
      grouping: "rows",
      entity: "outcome",
      group: [
        {
          field: "location",
          type: "text",
          ordering: [],
          alwaysHide: [],
          alwaysShow: [],
        },
      ],
      filter: {
        field: "status",
        type: "status",
        op: "equals",
        values: {
          status: [{ group: "not-started" }, { group: "in-progress" }],
        },
      },
      showProps: [],
    },
  },

  {
    id: "project-task",
    name: "Project Tasks",
    tags: [],
    template: {
      name: "All Tasks",
      layout: "list",
      grouping: "rows",
      entity: "task",
      group: [
        {
          field: "status",
          type: "status",
          ordering: [],
          alwaysHide: [],
          alwaysShow: [],
          hideEmpty: true,
        },
      ],
      showProps: [{ field: "assigned", type: "relation" }],
    },
  },

  {
    id: "sprint-task",
    name: "Sprint Tasks",
    tags: [],
    template: {
      name: "By Person",
      layout: "list",
      grouping: "rows",
      entity: "task",
      group: [{ field: "assigned", type: "relation" }],
      showProps: [],
    },
  },

  {
    id: "calendar-content",
    name: "Content Calendar",
    tags: [],
    template: {
      name: "Calendar",
      entity: "content",
      layout: "calendar",
      settings: {
        calStart: { field: "publish", type: "date" },
      },
    },
  },

  {
    id: "calendar-event",
    name: "Calendar Events",
    tags: [],
    template: {
      name: "All events",
      entity: "event",
      layout: "calendar",
      settings: {
        collapse: true,
        calStart: { field: "start", type: "date" },
        calEnd: { field: "end", type: "date" },
      },
    },
  },

  {
    id: "calendar-task",
    name: "Calendar Tasks",
    tags: [],
    template: {
      name: "All tasks",
      entity: "task",
      layout: "calendar",
      settings: {
        collapse: true,
        calStart: { field: "start", type: "date" },
        calEnd: { field: "end", type: "date" },
      },
    },
  },

  {
    id: "campaign-task",
    name: "Campaign Tasks",
    tags: [],
    template: {
      name: "All tasks",
      entity: "task",
      layout: "list",
      grouping: "rows",
      group: [{ field: "assigned", type: "relation", hideEmpty: true }],
      showProps: [{ field: "assigned", type: "relation" }],
      settings: {
        collapse: false,
      },
    },
  },
  {
    id: "campaign-outcome",
    name: "Campaign Outcomes",
    tags: [],
    template: {
      name: "All outcomes",
      entity: "outcome",
      layout: "list",
      grouping: "rows",
      group: [{ field: "status", type: "status" }],
    },
  },
  {
    id: "campaign-content",
    name: "Campaign Contents",
    tags: [],
    template: {
      name: "All content",
      entity: "content",
      layout: "calendar",
      showProps: [{ field: "owner", type: "relation" }],
      sort: [{ field: "publish", direction: "asc" }],
      settings: {
        calStart: { field: "publish", type: "date" },
      },
    },
  },
  {
    id: "pipeline-default",
    name: "Pipeline",
    tags: [],
    template: {
      name: "All",
      layout: "card",
      grouping: "columns",
    },
  },

  {
    id: "backlog-list",
    name: "Backlog Tasks",
    tags: [],
    template: {
      name: "Prioritized",
      entity: "task",
      layout: "table",
    },
  },

  {
    id: "backlog-open-task",
    name: "Backlog Tasks",
    tags: [],
    template: {
      name: "Open Tasks",
      entity: "task",
      layout: "table",
      showProps: [{ field: "assigned", type: "relation" }],
      filter: {
        field: "status",
        type: "status",
        op: "does_not_equal",
        value: { status: { group: "done" } },
      },
    },
  },

  {
    id: "roadmap-planned",
    name: "Roadmap Current",
    tags: [],
    template: {
      order: 1,
      name: "Upcoming",
      layout: "card",
      entity: "outcome",
      grouping: "columns_rows",
      group: [
        { field: "status", type: "status", alwaysHide: ["INB", "DRFT"] },
        { field: "end", type: "date", format: "month" },
      ],
      filter: {
        or: [
          {
            field: "status",
            type: "status",
            op: "equals",
            values: {
              status: [{ group: "not-started" }, { group: "in-progress" }],
            },
          },
          {
            and: [
              {
                field: "end",
                type: "date",
                op: "after",
                value: { formula: "=today-7d" },
              },
              {
                field: "status",
                type: "status",
                op: "equals",
                values: {
                  status: [{ group: "done" }],
                },
              },
            ],
          },
        ],
      },
    },
  },

  {
    id: "roadmap-all",
    name: "Roadmap",
    tags: [],
    template: {
      order: 3,
      name: "All",
      entity: "outcome",
      layout: "table",
      filter: {
        field: "status",
        type: "status",
        op: "does_not_equal",
        values: {
          status: [{ group: "done" }],
        },
      },
    },
  },

  {
    id: "project-outcome",
    name: "Project Outcomes",
    tags: [],
    template: {
      name: "Outcomes",
      entity: "outcome",
      layout: "list",
      showProps: [{ field: "assigned", type: "relation" }],
    },
  },
  {
    id: "sprint-outcome",
    name: "Sprint Outcomes",
    tags: [],
    template: {
      name: "Outcomes",
      entity: "outcome",
      layout: "list",
      grouping: "rows",
      group: [{ field: "status", type: "status" }],
      showProps: [{ field: "assigned", type: "relation" }],
    },
  },

  {
    id: "my-pages",
    name: "My Docs",
    tags: [],
    template: {
      name: "All",
      layout: "browser",
      entity: "page",
    },
  },

  {
    id: "my-current-sprint",
    name: "Current sprint",
    tags: ["personal"],
    template: {
      name: "Current sprint",
      entity: "sprint",
      layout: "list",
      filter: {
        field: "status",
        type: "status",
        op: "equals",
        value: { status: { group: "in-progress" } },
      },
    },
  },

  {
    id: "my-outcomes",
    name: "Outcomes",
    tags: ["personal"],
    template: {
      name: "Working towards",
      entity: "outcome",
      layout: "list",
      filter: {
        and: [
          {
            field: "status",
            type: "status",
            op: "equals",
            values: {
              status: [{ group: "in-progress" }, { group: "not-started" }],
            },
          },
        ],
      },
    },
  },

  {
    id: "my-actions",
    name: "Actions",
    tags: ["personal"],
    template: {
      name: "Meeting actions",
      entity: "action",
      layout: "list",
      filter: {
        field: "status",
        type: "status",
        op: "equals",
        values: {
          status: [{ group: "in-progress" }, { group: "not-started" }],
        },
      },
      sort: [{ field: "createdAt", type: "date", direction: "desc" }],
      grouping: "rows",
      group: [
        {
          field: "refs.meeting",
          type: "relation",
          hideEmpty: true,
        },
      ],
    },
  },

  {
    id: "tasks-by-me",
    name: "Tasks created by me",
    tags: ["personal"],
    template: {
      name: "Tasks",
      entity: "task",
      layout: "list",
      sort: [{ field: "createdAt", type: "date", direction: "desc" }],
      filter: {
        and: [
          {
            field: "createdBy",
            type: "relation",
            op: "equals",
            value: {
              formula: "=me",
            },
          },
          {
            field: "assigned",
            type: "relation",
            op: "does_not_equal",
            value: {
              formula: "=me",
            },
          },
          {
            field: "createdAt",
            type: "date",
            op: "after",
            value: {
              formula: "=today-30d",
            },
          },
          {
            field: "status",
            type: "status",
            op: "does_not_equal",
            values: {
              status: [{ group: "planning" }],
            },
          },
        ],
      },
    },
  },
  {
    id: "actions-by-me",
    name: "Actions created by me",
    tags: ["personal"],
    template: {
      name: "Actions",
      entity: "action",
      layout: "list",
      sort: [{ field: "createdAt", type: "date", direction: "desc" }],
      filter: {
        and: [
          {
            field: "createdBy",
            type: "relation",
            op: "equals",
            value: {
              formula: "=me",
            },
          },
          {
            field: "assigned",
            type: "relation",
            op: "does_not_equal",
            value: {
              formula: "=me",
            },
          },
          {
            field: "createdAt",
            type: "date",
            op: "after",
            value: {
              formula: "=today-30d",
            },
          },
          {
            field: "status",
            type: "status",
            op: "does_not_equal",
            values: {
              status: [{ group: "planning" }],
            },
          },
        ],
      },
    },
  },

  {
    id: "notes-by-me",
    name: "Notes created by me",
    tags: ["personal"],
    template: {
      name: "Messages",
      entity: "note",
      layout: "list",
      sort: [{ field: "createdAt", type: "date", direction: "desc" }],
      filter: {
        and: [
          {
            field: "createdBy",
            type: "relation",
            op: "equals",
            value: {
              formula: "=me",
            },
          },
          {
            field: "createdAt",
            type: "date",
            op: "after",
            value: {
              formula: "=today-30d",
            },
          },
        ],
      },
    },
  },

  {
    id: "meeting-actions",
    name: "Actions",
    tags: ["team"],
    template: {
      name: "Actions by person",
      entity: "action",
      layout: "list",
      sort: [{ field: "createdAt", type: "date", direction: "asc" }],
      grouping: "rows",
      group: [
        {
          field: "assigned",
          type: "relation",
          hideEmpty: true,
        },
      ],
    },
  },

  {
    id: "all-people",
    name: "All People",
    tags: ["team"],
    template: {
      name: "All",
      layout: "table",
      entity: "person",
      sort: [
        { field: "name", type: "text", direction: "asc" },
        { field: "email", type: "text", direction: "asc" },
      ],
      showProps: [
        { field: "email", type: "text" },
        { field: "role", type: "select" },
        { field: "teams", type: "relations" },
      ],
    },
  },

  {
    id: "all-meetings",
    name: "My Meetings",
    tags: ["personal"],
    template: {
      name: "My meetings",
      entity: "meeting",
      layout: "calendar",
      settings: {
        nested: true,
        calStart: { field: "start", type: "date" },
        weekends: true,
      },
      sort: [{ field: "start", type: "date", direction: "asc" }],
      order: 1,
      filter: {
        and: [
          {
            field: "start",
            type: "date",
            op: "after",
            value: { formula: "=today-90d" },
          },
          {
            field: "end",
            type: "date",
            op: "before",
            value: { formula: "=today+90d" },
          },
        ],
      },
    },
  },

  {
    id: "upcoming-meetings",
    name: "Upcoming Meetings",
    tags: ["personal"],
    template: {
      name: "Upcoming",
      entity: "meeting",
      layout: "calendar",
      settings: {
        nested: true,
        calStart: { field: "start", type: "date" },
        weekends: true,
      },
      sort: [{ field: "start", type: "date", direction: "asc" }],
      order: 1,
      filter: {
        and: [
          {
            field: "start",
            type: "date",
            op: "on_or_after",
            value: { formula: "=today" },
          },
          {
            field: "end",
            type: "date",
            op: "before",
            value: { formula: "=today+90d" },
          },
        ],
      },
    },
  },

  {
    id: "my-meetings",
    name: "My Meetings",
    tags: ["personal"],
    template: {
      name: "My meetings",
      entity: "meeting",
      layout: "calendar",
      settings: {
        nested: true,
        calStart: { field: "start", type: "date" },
        weekends: true,
      },
      sort: [{ field: "start", type: "date", direction: "asc" }],
      order: 1,
      filter: {
        and: [
          {
            field: "start",
            type: "date",
            op: "after",
            value: { formula: "=today-90d" },
          },
          {
            field: "end",
            type: "date",
            op: "before",
            value: { formula: "=today+90d" },
          },
        ],
      },
    },
  },

  {
    id: "my-today-meetings",
    name: "My Meetings Today",
    tags: ["personal"],
    template: {
      name: "Upcoming meetings",
      entity: "meeting",
      layout: "list",
      sort: [{ field: "start", type: "date", direction: "asc" }],
      order: 1,
      group: [
        {
          field: "start",
          type: "date",
          format: "day",
          ordering: [],
          hideEmpty: true,
        },
      ],
      filter: {
        and: [
          {
            field: "status",
            type: "status",
            op: "does_not_equal",
            values: {
              status: [{ group: "done" }],
            },
          },
          {
            field: "start",
            type: "date",
            op: "after",
            value: { formula: "=today-1d" },
          },
          {
            field: "end",
            type: "date",
            op: "before",
            value: { formula: "=today+2d" },
          },
        ],
      },
    },
  },

  {
    id: "my-campaigns",
    name: "Campaigns",
    tags: ["personal"],
    template: {
      name: "Responsible for",
      entity: "campaign",
      layout: "list",
      sort: [{ field: "start", type: "date", direction: "asc" }],
      filter: {
        and: [
          {
            field: "status",
            type: "status",
            op: "equals",
            values: {
              status: [{ group: "in-progress" }, { group: "not-started" }],
            },
          },
        ],
      },
    },
  },

  {
    id: "my-workflows",
    name: "Workflows Involving me",
    tags: ["personal"],
    template: {
      name: "Involving me",
      entity: "workflow",
      layout: "list",
      sort: [{ field: "updatedAt", type: "date", direction: "desc" }],
      filter: {
        and: [
          {
            field: "refs.followers",
            type: "relations",
            op: "contains",
            value: { formula: "=me" },
          },
          {
            field: "status",
            type: "status",
            op: "does_not_equal",
            value: { status: { group: "done" } },
          },
        ],
      },
    },
  },

  {
    id: "my-projects",
    name: "Campaigns",
    tags: ["personal"],
    template: {
      name: "In charge of",
      entity: "project",
      layout: "list",
      sort: [{ field: "start", type: "date", direction: "asc" }],
      filter: {
        and: [
          {
            field: "status",
            type: "status",
            op: "equals",
            values: {
              status: [{ group: "in-progress" }, { group: "not-started" }],
            },
          },
        ],
      },
    },
  },

  {
    id: "my-contents",
    name: "Contents",
    tags: ["personal"],
    template: {
      name: "Going out",
      entity: "content",
      layout: "list",
      filter: {
        and: [
          {
            field: "status",
            type: "status",
            op: "equals",
            values: {
              status: [{ group: "in-progress" }, { group: "not-started" }],
            },
          },
        ],
      },
      sort: [{ field: "publish", type: "date", direction: "asc" }],
    },
  },

  {
    id: "my-deals",
    name: "Deals",
    tags: ["personal"],
    template: {
      name: "My deals",
      entity: "deal",
      layout: "list",
      filter: {
        and: [
          {
            field: "status",
            type: "status",
            op: "equals",
            values: {
              status: [{ group: "in-progress" }, { group: "not-started" }],
            },
          },
        ],
      },
    },
  },

  {
    id: "my-events",
    name: "Events",
    tags: ["personal"],
    template: {
      name: "Responsible for",
      entity: "event",
      layout: "list",
      filter: {
        and: [
          {
            field: "status",
            type: "status",
            op: "equals",
            values: {
              status: [{ group: "in-progress" }, { group: "not-started" }],
            },
          },
        ],
      },
      sort: [{ field: "publish", type: "date", direction: "asc" }],
    },
  },

  {
    id: "my-tasks",
    name: "Tasks",
    tags: ["personal"],
    template: {
      name: "My tasks",
      entity: "task",
      layout: "list",
      filter: {
        and: [
          {
            field: "status",
            type: "status",
            op: "does_not_equal",
            values: { status: [{ group: "planning" }, { group: "done" }] },
          },
          {
            field: "blocked",
            type: "boolean",
            op: "does_not_equal",
            value: { boolean: true },
          },
          {
            field: "start",
            type: "date",
            op: "before",
            value: { formula: "=today+1d" },
            empty: true,
          },
        ],
      },
      showProps: [],
      grouping: "rows",
      settings: {
        showSubs: true,
      },
      group: [
        {
          field: "status",
          type: "status",
          ordering: [],
          alwaysHide: ["DRFT"],
          alwaysShow: [],
        },
      ],
    },
  },

  {
    id: "my-in-progress",
    name: "Current work",
    tags: ["personal"],
    template: {
      name: "Work in progress",
      entity: "task",
      layout: "list",
      filter: {
        field: "status",
        type: "status",
        op: "equals",
        value: { status: { group: "in-progress" } },
      },
      settings: {
        showSubs: true,
      },
    },
  },
  {
    id: "my-ready",
    name: "Ready to start",
    tags: ["personal"],
    template: {
      name: "Ready to start",
      entity: "task",
      layout: "list",
      filter: {
        and: [
          {
            field: "status",
            type: "status",
            op: "equals",
            value: { status: { group: "not-started" } },
          },
          {
            field: "blocked",
            type: "boolean",
            op: "does_not_equal",
            value: { boolean: true },
          },
        ],
      },
      settings: {
        showSubs: true,
      },
    },
  },

  {
    id: "my-recently-completed",
    name: "Recently completed",
    tags: ["personal"],
    template: {
      name: "Recently completed",
      entity: "task",
      layout: "list",
      sort: [{ field: "updatedAt", type: "date", direction: "desc" }],
      filter: {
        and: [
          {
            field: "status",
            type: "status",
            op: "equals",
            value: { status: { group: "done" } },
          },
          {
            field: "updatedAt",
            type: "date",
            op: "after",
            value: { formula: "=today-7d" },
          },
        ],
      },
      settings: {
        showSubs: true,
      },
    },
  },

  {
    id: "my-planned",
    name: "Planned",
    tags: ["personal"],
    template: {
      name: "Upcoming",
      entity: "task",
      layout: "list",
      filter: {
        or: [
          {
            field: "status",
            type: "status",
            op: "equals",
            value: { status: { group: "planning" } },
          },
          {
            and: [
              {
                field: "status",
                type: "status",
                op: "equals",
                value: { status: { group: "not-started" } },
              },
              {
                field: "blocked",
                type: "boolean",
                op: "equals",
                value: { boolean: true },
              },
            ],
          },
          {
            and: [
              {
                field: "status",
                type: "status",
                op: "equals",
                value: { status: { group: "not-started" } },
              },
              {
                field: "blocked",
                type: "boolean",
                op: "does_not_equal",
                value: { boolean: true },
              },
              {
                field: "start",
                type: "date",
                op: "after",
                value: { formula: "=today" },
                empty: false,
              },
            ],
          },
        ],
      },
      sort: [{ field: "start", type: "date", op: "asc" }],
      settings: {
        showSubs: true,
      },
    },
  },
] as Template<Partial<View>>[]);

export const getViewTemplate = (id: string, activeUserId?: string) => {
  const { template } = allViewTemplates[id] || {};

  // TODO: Move to eval formulas (needs access to active user)
  // Replace all "me" values with the current user id
  if (template && activeUserId) {
    return {
      ...template,
      filter: transformAll(template?.filter, (f) =>
        f.value?.formula === "=me" && isAnyRelation(f)
          ? { ...f, value: asRelationValue(f.type, activeUserId) }
          : f
      ),
    };
  }

  return template;
};

export const useViewTemplates = (tag: string) => {
  const [templates, setTemplates] = useState([] as Template<Partial<View>>[]);

  useEffect(() => {
    setTemplates(maybeValues(allViewTemplates, (t) => !!t.tags?.includes(tag)));
  }, [tag]);

  return templates;
};

export const usePropertyTemplates = () => {
  const [templates, setTemplates] = useState(
    [] as Template<Partial<PropertyDef<Entity, PropertyType>>>[]
  );

  useEffect(() => {
    setTemplates(
      map(allPropertyTemplates(), (t) => ({
        ...t,
        search: join([t.name, t.description, ...(t.tags || [])], " "),
      }))
    );
  }, []);

  return templates;
};

export const useLazyAllTemplates = (
  teamId: ID,
  opts?: { allowed?: EntityType[]; blacklist?: EntityType[] }
) => {
  const { allowed, blacklist } = opts || {};
  const installed = useInstalledEntities(teamId);
  const entities = useMemo(
    () =>
      allowed ||
      (blacklist?.length ? difference(installed, blacklist) : installed),
    [allowed, blacklist, installed]
  );
  const globalFetch = useRecoilValue(GlobalFetchOptionsAtom);
  const templates = useRecoilValue(
    allTemplates(
      hashable({
        entities: entities,
        archived: globalFetch.archived,
        teamId,
      })
    )
  );

  useAsyncEffect(async () => {
    map(entities, async (type) => {
      // Fetch templates for this type
      const { changed } = await getOptimizedTemplatesLoader(type);
      if (changed) {
        // Update the state
        setRecoil(getStore(type), setItems(changed));
      }
    });
  }, [entities?.length]);

  return templates;
};

export const useLazyTemplates = <T extends EntityType = EntityType>(
  source: Maybe<DatabaseID<T>>
): EntityForType<T>[] => {
  const wID = useActiveWorkspaceId();
  const queryKey = useMemo(
    () => `templates-query-${source?.type}.${source?.scope}`,
    [source?.type, source?.scope]
  );
  const onLoaded = useOnFetchResults(queryKey, source?.type);
  const lastFetched = useRecoilValue(lastFetchedAt(queryKey));
  const templates = useRecoilValue(
    templatesForSource(hashable(source || { type: "task", scope: wID }))
  );

  useAsyncEffect(async () => {
    if (!source) {
      return;
    }
    // Fetch templates for this type
    await getOptimizedTemplatesLoader(
      source.type,
      { since: lastFetched },
      undefined,
      ({ changed, all }) => onLoaded(changed, all)
    );
  }, [source?.type, source?.scope]);

  return templates as EntityForType<T>[];
};

export const useFilteredTemplates = <T extends EntityType = EntityType>(
  source: DatabaseID<T>,
  filter?: FilterQuery
): EntityForType<T>[] => {
  return useLazyFetchResults(
    `templates-query-${source.type + source.scope}`,
    source.type,
    useMemo(
      () =>
        append(
          {
            field: "template",
            type: "text",
            op: "equals",
            value: { text: "root" },
          },
          filter
        ),
      [source?.type, source.scope, filter]
    ),
    { templates: true }
  );
};
