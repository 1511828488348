import { filter, first, flatMap, map, toLower } from "lodash";
import { useEffect, useMemo, useState } from "react";

import { ID, Workflow, WorkflowStep } from "@api";

import {
  useLazyEntities,
  useLazyEntity,
  useRunningTime,
  useUpdateEntity,
} from "@state/generic";
import { useMe } from "@state/persons";
import { useLazyPropertyValue } from "@state/properties";
import {
  isFinished,
  toStepAssigned,
  toSystemVars,
  useWorkflowSteps,
} from "@state/workflow";

import { justOne, maybeMap, whenEmpty } from "@utils/array";
import { cx } from "@utils/class-names";
import { formatHuman } from "@utils/date";
import { useISODate, usePointDate } from "@utils/date-fp";
import { debug } from "@utils/debug";
import { composel } from "@utils/fn";
import { useStickyState } from "@utils/hooks";
import { equalsAny, switchEnum } from "@utils/logic";
import { when } from "@utils/maybe";
import { useGoTo, usePathName, usePushTo, useReplace } from "@utils/navigation";
import { asMutation } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { toParentScope } from "@utils/scope";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { Card } from "@ui/card";
import { CollapsibleSection } from "@ui/collapsible-section";
import { Container } from "@ui/container";
import { Divider } from "@ui/divider";
import { EditableHeading } from "@ui/editable-heading";
import { EmptyState } from "@ui/empty-state";
import { EntityHeaderBar } from "@ui/entity-header-bar";
import { EntitySheet } from "@ui/entity-sheet";
import { FillSpace, HalfSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { ArrowUpRight, Icon } from "@ui/icon";
import { StatusIndicator } from "@ui/icon/status-indicator";
import { Field } from "@ui/input";
import { Label } from "@ui/label";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { EmptyMenuItem } from "@ui/menu-item";
import { PeopleStack } from "@ui/people-stack";
import { PropertyValuesList } from "@ui/property-values-list";
import { RelationText } from "@ui/relation-label";
import { ColoredSection, Section } from "@ui/section";
import { NoSelectable } from "@ui/selectable-items";
import { Switch } from "@ui/switch";
import { Text, TextLarge, TextSmall } from "@ui/text";
import { Tooltip } from "@ui/tooltip";
import { WorkflowActions } from "@ui/workflow-action-button";
import { WorkflowBuilderFlow } from "@ui/workflow-builder-flow";

import { render, toEngine } from "..";
import { PaneOpts } from "../types";
import { WorkflowStepPane } from "../workflow_step/pane";

import styles from "./styles.module.css";

export const WorkflowPane = ({ item, size, className }: PaneOpts<Workflow>) => {
  const me = useMe();
  const pushTo = usePushTo();
  const redirect = useReplace();
  const path = usePathName();
  const steps = useWorkflowSteps(item);
  const current = useMemo(
    () =>
      flatMap(steps, (s) =>
        equalsAny(s.status?.id, ["WAI", "RUN"]) ? s.refs.created || [] : []
      ),
    [steps]
  );
  const blockingWork = useLazyEntities(current);
  const myWorkSteps = useMemo(
    () =>
      maybeMap(steps, (s) =>
        toStepAssigned(s, { workflow: item, steps })?.id === me?.id
          ? toRef(first(s.refs?.created) || s)
          : undefined
      ),
    [current, steps]
  );
  const myWork = useLazyEntities(myWorkSteps);

  const runningTime = useRunningTime(item);

  useEffect(() => {
    if (item.template) {
      debug("Redirecting to builder, this is probably a bad link.");
      redirect([path, "/builder"]);
    }
  }, []);

  return (
    <EntitySheet
      height="container"
      size={size || "full"}
      className={className}
      entity={item}
    >
      <NoSelectable>
        <Container
          stack="vertical"
          gap={40}
          fit="container"
          style={{ paddingTop: "0px" }}
        >
          <Container inset="left" padding={"none"} stack="vertical" gap={40}>
            <WorkflowHeader workflow={item} />
          </Container>
          <Divider />

          <CollapsibleSection divider={false}>
            <WorkflowTimeline workflow={item} />
          </CollapsibleSection>

          <Divider />

          <Card interactable={false} padding="both">
            <VStack gap={20}>
              <SpaceBetween align="flex-start">
                <HalfSpace>
                  <VStack gap={10}>
                    <VStack gap={0}>
                      <Text subtle>Running Time</Text>
                      <TextLarge bold>
                        {runningTime?.mins < 60
                          ? `${runningTime.mins} mins`
                          : runningTime?.hours < 24
                          ? `${runningTime.hours} hours`
                          : `${runningTime.days} days`}
                      </TextLarge>
                    </VStack>
                  </VStack>
                </HalfSpace>
              </SpaceBetween>

              <SpaceBetween align="flex-start" gap={20}>
                <HalfSpace>
                  <Section title="Current" padded={false}>
                    <Menu>
                      <MenuGroup>
                        {map(blockingWork, (item) =>
                          render(toEngine(item)?.asMenuItem, {
                            key: item.id,
                            item,
                            showProps: [
                              { field: "assigned", type: "relation" },
                            ],
                            onOpen: () => when(item, pushTo),
                          })
                        )}
                        {!blockingWork?.length && (
                          <EmptyMenuItem text="All required steps complete." />
                        )}
                      </MenuGroup>
                    </Menu>
                  </Section>
                </HalfSpace>
                <HalfSpace>
                  <Section title="Assigned to Me" padded={false}>
                    <Menu>
                      <MenuGroup>
                        {map(myWork, (item) =>
                          render(toEngine(item)?.asMenuItem, {
                            key: item.id,
                            item,
                            disabled: item.source.type === "workflow_step",
                            showProps: [
                              { field: "assigned", type: "relation" },
                            ],
                            onOpen: pushTo,
                          })
                        )}
                        {!myWork?.length && (
                          <EmptyMenuItem text="Nothing in this workflow is assigned to you." />
                        )}
                      </MenuGroup>
                    </Menu>
                  </Section>
                </HalfSpace>
              </SpaceBetween>
            </VStack>
          </Card>

          <CollapsibleSection
            title="Workflow Templates"
            padded={false}
            defaultOpen={false}
            actions={
              <Button
                onClick={() => pushTo("/builder")}
                iconRight={ArrowUpRight}
                size="small"
                subtle
              >
                Edit
              </Button>
            }
          >
            <WorkflowBuilderFlow id={item.id} className={styles.flowPreview} />
          </CollapsibleSection>

          <HStack>
            <WorkflowActions entity={item} />
          </HStack>
        </Container>
      </NoSelectable>
    </EntitySheet>
  );
};

interface HeaderProps {
  workflow: Workflow;
}

const WorkflowHeader = ({ workflow }: HeaderProps) => {
  const pageId = usePageId();
  const mutate = useUpdateEntity(workflow.id, pageId);
  const goTo = useGoTo();
  const status = useLazyPropertyValue(workflow, {
    field: "status",
    type: "status",
  });

  if (!workflow) {
    return <h1>Not found.</h1>;
  }

  return (
    <VStack gap={20}>
      <EntityHeaderBar entity={workflow} padding="vertical" />

      <VStack gap={20} fit="container">
        <SpaceBetween align="flex-end">
          <VStack gap={6} fit="container">
            <Container gap={4} stack="horizontal" inset="left" padding="none">
              <Tooltip text={status.value.status?.name} side="top">
                <Icon icon={<StatusIndicator status={status.value.status} />} />
              </Tooltip>
              <EditableHeading
                key={workflow.id}
                text={workflow.name || ""}
                size="h3"
                placeholder="Workflow name"
                onChange={(text) => {
                  when(text, (i) =>
                    mutate(asMutation({ field: "name", type: "text" }, i))
                  );
                }}
              />
            </Container>

            {when(toParentScope(workflow.location), (id) => (
              <TextSmall subtle>
                Started for{" "}
                <Button variant="link" onClick={() => goTo(id)}>
                  <RelationText
                    icon={false}
                    subtle
                    size="small"
                    relation={{ id }}
                  />
                </Button>{" "}
                {usePointDate(
                  workflow.createdAt,
                  composel(formatHuman, toLower)
                )}{" "}
                {when(justOne(workflow.refs?.fromTemplate), (r) => (
                  <>
                    from{" "}
                    <Button
                      variant="link"
                      onClick={() => goTo([r.id, "/builder"])}
                    >
                      <RelationText relation={toRef(r)} />
                    </Button>
                  </>
                ))}
              </TextSmall>
            ))}
          </VStack>

          <HStack>
            <PeopleStack size="large" people={workflow.refs?.followers} />
          </HStack>
        </SpaceBetween>
      </VStack>
    </VStack>
  );
};

export const WorkflowTimeline = ({ workflow }: HeaderProps) => {
  const pushTo = usePushTo();
  const finished = useMemo(() => isFinished(workflow), [workflow?.status]);
  const allSteps = useWorkflowSteps(workflow);
  const [showAll, setShowAll] = useStickyState<boolean>(
    false,
    "workflow-timeline-show-all"
  );
  const [openId, setOpenId] = useState<ID>();
  const open = useLazyEntity(openId);
  const steps = useMemo(() => {
    if (showAll) {
      return allSteps;
    }

    const filtered = filter(allSteps, (s) =>
      switchEnum(s.action || "", {
        create: s.status?.id !== "SKP",
        wait: s.status?.id === "WAI",
        else: false,
      })
    );

    return whenEmpty(filtered, () => allSteps);
  }, [allSteps, showAll]);

  const hasInputs = !!workflow.inputs?.length;

  // Select the first step if there is no openId
  useEffect(() => {
    if (!openId && steps?.length) {
      setOpenId(steps[0].id);
    }
  }, [openId, steps?.length]);

  return (
    <div className={styles.timelineContainer}>
      <div className={styles.timelineList}>
        <Container
          stack="vertical"
          padding="horizontal"
          inset="horizontal"
          size="half"
          gap={12}
        >
          {!!hasInputs && (
            <Card
              className={cx(openId && openId === workflow.id && styles.active)}
              onClick={() => setOpenId(workflow.id)}
            >
              <VStack gap={10}>
                <SpaceBetween>
                  <Text>
                    Started by <RelationText relation={workflow.createdBy} />
                    {when(workflow.refs?.startedFrom?.[0], (from) => (
                      <>
                        {" "}
                        from{" "}
                        <Button variant="link" onClick={() => pushTo(from)}>
                          <RelationText relation={from} />
                        </Button>
                      </>
                    ))}{" "}
                    with {workflow?.inputs?.length} inputs
                  </Text>
                </SpaceBetween>

                <TextSmall subtle>
                  {useISODate(workflow.createdAt, formatHuman)}
                </TextSmall>
              </VStack>
            </Card>
          )}

          {map(steps, (item, i) =>
            render(toEngine(item)?.asListItem, {
              key: item.id,
              item,
              className: cx(
                !!openId &&
                  equalsAny(openId, [
                    item.id,
                    toRef(item.refs?.created?.[0])?.id,
                  ]) &&
                  styles.active
              ),
              onOpen: () => setOpenId(item.id),
            })
          )}

          {finished && (
            <VStack gap={4}>
              <ColoredSection>
                <VStack gap={10}>
                  {workflow.status?.id === "FNS" && (
                    <Label
                      icon={<StatusIndicator status={{ color: "green" }} />}
                    >
                      Workflow finished successfully.
                    </Label>
                  )}
                  {workflow.status?.id === "CNC" && (
                    <Label icon={<StatusIndicator status={workflow.status} />}>
                      Workflow run cancelled.
                    </Label>
                  )}
                </VStack>
              </ColoredSection>
            </VStack>
          )}
        </Container>

        <Field padded>
          <Switch
            size="small"
            checked={showAll}
            onChange={setShowAll}
            label="Show all steps"
            subtle
          />
        </Field>
      </div>

      <FillSpace direction="horizontal">
        {open &&
          switchEnum(open.source.type, {
            workflow: () => (
              <>
                <PropertyValuesList
                  propsEditable={false}
                  values={workflow.inputs || []}
                  source={workflow.source}
                  editable={false}
                />

                <Divider label="System" />

                <PropertyValuesList
                  propsEditable={false}
                  values={toSystemVars(workflow)}
                  source={workflow.source}
                  editable={false}
                />
              </>
            ),
            workflow_step: () => (
              <WorkflowStepPane
                step={open as WorkflowStep}
                workflow={workflow}
                allSteps={allSteps}
              />
            ),
            else: () => <EmptyState text="Something went wrong." />,
          })}

        {!openId && <EmptyState text="Nothing selected." />}
      </FillSpace>
    </div>
  );
};
