import { map } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { FileDrop } from "react-file-drop";

import { FileMeta } from "@api";

import { ensureArray, OneOrMany } from "@utils/array";
import { log } from "@utils/debug";
import { useWindowEvent, withHardHandle } from "@utils/event";
import { formatBytes } from "@utils/file";
import { Fn } from "@utils/fn";
import { when } from "@utils/maybe";
import { mapAll } from "@utils/promise";
import { useTick } from "@utils/time";

import { Button, Props as ButtonProps } from "@ui/button";
import { Container } from "@ui/container";
import { SpaceBetween, VStack } from "@ui/flex";
import { LinkIcon, Upload } from "@ui/icon";
import { Label } from "@ui/label";
import { Modal } from "@ui/modal";
import { showError } from "@ui/notifications";
import ProgressBar from "@ui/progress-bar";
import { Text } from "@ui/text";
import { useUpload } from "@ui/upload";

import styles from "./upload-modal.module.css";

interface Props {
  scope: string;
  multiple?: boolean;
  onUploaded: Fn<OneOrMany<FileMeta>, void>;
  onCancel: Fn<void, void>;
}

export default function UploadModal({
  scope,
  multiple = true,
  onUploaded,
  onCancel: _onCancel,
}: Props) {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [triedClosing, setTriedClosing] = useState(false);
  const [uploads, setUploads] = useState<{ file: File; percent: number }[]>([]);
  const uploading = uploads.length > 0;
  const { upload, abort } = useUpload(scope);

  const onCancel = useCallback(() => {
    abort();
    _onCancel?.();
  }, [abort, _onCancel]);

  const setPercent = useCallback(
    (file: File, percent: number) =>
      setUploads((uploads) =>
        map(uploads, (u) => (u.file === file ? { ...u, percent } : u))
      ),
    []
  );

  const handleFileUpload = useCallback(
    async (file: OneOrMany<File>) => {
      const files = ensureArray(file);
      try {
        setUploads(map(files, (file) => ({ file, percent: 0 })));
        const uploaded = await mapAll(files, (f) =>
          upload(f, (p) => setPercent(f, p))
        );
        onUploaded(uploaded);
      } catch (error) {
        log(error);
        showError("Failed to upload file. Please try again.");
      } finally {
        setUploads([]);
      }
    },
    [upload, onUploaded, scope]
  );

  useWindowEvent(
    "paste",
    (e) => {
      map(e.clipboardData?.items, (item) => {
        if (item.kind === "file") {
          when(item.getAsFile(), handleFileUpload);
        }
      });
    },
    false,
    [handleFileUpload]
  );

  return (
    <Modal
      open={true}
      onOpenChanged={() => (!uploading ? onCancel?.() : setTriedClosing(true))}
    >
      {!uploading && (
        <input
          onChange={({ target: { files } }) =>
            files && handleFileUpload(multiple ? Array.from(files) : files[0])
          }
          ref={inputFileRef}
          multiple={multiple}
          type="file"
          className={styles.hidden}
        />
      )}

      <FileDrop
        targetClassName={styles.target}
        onTargetClick={() => inputFileRef.current?.click()}
        onDrop={(files) =>
          files && handleFileUpload(multiple ? Array.from(files) : files[0])
        }
      >
        {uploading && (
          <Container stack="vertical" gap={20} align="center">
            {map(uploads, ({ file, percent }) => (
              <UploadProgress key={file.name} file={file} percent={percent} />
            ))}

            {triedClosing && (
              <Button
                size="small"
                subtle
                onClick={withHardHandle(() => onCancel?.())}
              >
                Cancel upload
              </Button>
            )}
          </Container>
        )}
        {!uploading && (
          <Text className={styles.text}>
            Drop, paste, or <span className={styles.underline}>select</span>{" "}
            {multiple ? "files" : "a file"}...
          </Text>
        )}
      </FileDrop>
    </Modal>
  );
}

const UploadProgress = ({
  file,
  percent: _percent,
}: {
  file: File;
  percent: number;
}) => {
  const [percent, setPercent] = useState(_percent);

  useEffect(() => {
    if (_percent > percent) {
      setPercent(_percent);
    } else if (percent < 99) {
      // Ensure the input is within the valid range
      const clampedPercent = Math.max(0, Math.min(100, percent));

      // Use an exponential decay function
      // The formula creates a curve that:
      // - Starts at 10 when percent is 0
      // - Rapidly decreases as percent increases
      // - Approaches 1 as percent approaches 100
      const maxChunkSize = 10 * Math.pow(0.5, clampedPercent / 20);

      // Round to the nearest integer and ensure it's at least 1
      const chunk = Math.max(1, Math.round(maxChunkSize));

      setPercent(Math.min(percent + chunk, 99));
    }
  }, [useTick("500ms")]);

  return (
    <VStack gap={5}>
      <SpaceBetween>
        <Label icon={<LinkIcon url={file.webkitRelativePath} />}>
          {file.name}
        </Label>
        <Text subtle>{formatBytes(file.size)}</Text>
      </SpaceBetween>
      <ProgressBar
        percent={Math.floor(percent)}
        showPercents
        label={percent === 100 ? "Uploaded" : "Uploading"}
      />
    </VStack>
  );
};

type UploadFileButtonProps = Pick<
  ButtonProps,
  "children" | "icon" | "iconSize" | "fit" | "className"
> &
  Omit<Props, "onCancel">;

export const UploadFileButton = ({
  onUploaded,
  scope,
  icon,
  children,
  multiple,
  ...rest
}: UploadFileButtonProps) => {
  const [collecting, setCollecting] = useState(false);

  const handleAdded = useCallback(
    (o: OneOrMany<FileMeta>) => {
      when(o, onUploaded);
      setCollecting(false);
    },
    [onUploaded]
  );

  return (
    <>
      {collecting && (
        <UploadModal
          scope={scope}
          multiple={multiple}
          onUploaded={handleAdded}
          onCancel={() => setCollecting(false)}
        />
      )}

      <Button
        size="small"
        subtle
        icon={icon !== false ? icon || Upload : undefined}
        onClick={() => setCollecting(true)}
        {...rest}
      >
        {children || "Upload"}
      </Button>
    </>
  );
};
