import {
  filter,
  find,
  flatMap,
  includes,
  isString,
  map,
  orderBy,
  some,
  values,
} from "lodash";

import {
  Entity,
  EntityType,
  EntityTypeEnum,
  ID,
  isTemplate,
  isTitleable,
  PropertyDef,
  PropertyMutation,
  PropertyType,
  PropertyValue,
  PropertyValueRef,
  Ref,
  Update,
  VariableDef,
  Vars,
  Workflow,
  WorkflowStep,
} from "@api";

import { RichText } from "@graph";

import { persistedID } from "@state/store";

import {
  dependencySort,
  ensureMany,
  justOne,
  mapUniq,
  maybeLookup,
  maybeLookupById,
  maybeMap,
  overlaps,
} from "@utils/array";
import { formatHuman } from "@utils/date";
import { useISODate } from "@utils/date-fp";
import { logThrough } from "@utils/debug";
import { asEnum } from "@utils/enum";
import { composel, Fn, isFunc } from "@utils/fn";
import { extractVarReference, isFormula } from "@utils/formula";
import { isLocalID, typeFromId } from "@utils/id";
import { equalsAny, not, switchEnum } from "@utils/logic";
import { Maybe, safeAs, SafeRecord, when } from "@utils/maybe";
import { asMutation, isUpdate, toMutation } from "@utils/property-mutations";
import {
  asRelationValue,
  getPropertyValue,
  isEmptyRef,
  toRef,
} from "@utils/property-refs";
import { containsRef } from "@utils/relation-ref";
import { toChildLocation } from "@utils/scope";
import { replaceVariables } from "@utils/variables";
import { isStar, withoutStar } from "@utils/wildcards";

import { WorkflowRunContext } from "./atoms";

export const isNotStarted = (step: Maybe<WorkflowStep | Workflow>) =>
  equalsAny(step?.status?.id, ["AVL", "NTS"]);

export const isRunning = (step: Maybe<WorkflowStep | Workflow>) =>
  equalsAny(step?.status?.id, ["RUN", "WAI"]);

export const isFinished = (step: Maybe<WorkflowStep | Workflow>) =>
  equalsAny(step?.status?.id, ["FNS", "SKP", "ERR"]);

export const isBlocking = (step: WorkflowStep, dependency: WorkflowStep) => {
  // If the dependency is successfuly finished (exclude SKP, ERR), then block
  if (dependency.status?.id !== "FNS") {
    return true;
  }

  // Check condition rules
  if (dependency.action === "condition") {
    if (!dependency.outputs?.[0]?.value) {
      throw new Error("Condition step must have a boolean output.");
    }
    // Expects result to be stored in the first output
    const passed = dependency.outputs[0].value?.boolean;
    const isElsePath = containsRef(dependency?.refs?.else, step);

    // If condition passed, then block when on else path
    // If condition failed, then block when not on else path
    return passed === isElsePath;
  }

  // Otherwise not blocking
  return false;
};

export const toVariables = (
  workflow: Maybe<Workflow>,
  steps?: Maybe<WorkflowStep>[]
) => [
  ...(workflow?.inputs || []),
  // When running workflow only use finished steps variables
  ...flatMap(steps || [], (step) =>
    isTemplate(workflow) || step?.status?.id === "FNS"
      ? step?.outputs || []
      : []
  ),
  ...(when(workflow, toSystemVars) || []),
];

export const evalFormula = (
  formula: string,
  asType: PropertyType,
  vars: Vars | Fn<string, Maybe<VariableDef>>
) => {
  const getVar = isFunc(vars) ? vars : maybeLookup(vars, (v) => v.field);
  const handle = extractVarReference(formula);
  const value = getVar(handle)?.value;

  if (!value) {
    return { [asType]: undefined };
  }

  // TODO: Need to validate that the formula returns the right thing (date/ref/number/etc)
  return switchEnum<PropertyType, Maybe<PropertyValue>>(asType, {
    relation: () =>
      asRelationValue("relation", value?.relation || value?.relations),
    relations: () =>
      asRelationValue("relations", value?.relations || value?.relation),

    // Output and variable type must match  (no coercion)
    number: () => ({ number: Number(value?.number) }),

    // Soft type coercion
    text: () => ({ text: String(values(value)[0]) }),

    // Soft type coercion
    rich_text: () => ({
      rich_text: value?.rich_text || when(value?.text, (text) => ({ text })),
    }),

    // Must exactly match expected type
    else: () => when(value?.[asType], (v) => ({ [asType]: v })),
  });
};

export const evalFormulas = (
  overrides: PropertyMutation[],
  vars: Vars | Fn<string, Maybe<VariableDef>>
) => {
  const getVar = isFunc(vars) ? vars : maybeLookup(vars, (v) => v.field);
  return maybeMap(overrides, (o) => {
    // Common mistake from LLMs to use formula inside the value
    const formula =
      o.value?.formula || safeAs<PropertyValue>(o.value?.rich_text)?.formula;
    if (!!formula && isFormula(formula)) {
      // TODO: Need to validate that the formula returns the right thing (date/ref/number/etc)
      return logThrough(
        {
          ...o,
          value: evalFormula(formula, o.type, getVar) || {
            [o.type]: undefined,
          },
        },
        "formula eval",
        o
      );
    }

    if (o.type === "rich_text") {
      return {
        ...o,
        value: {
          rich_text: replaceVariables(o.value.rich_text, getVar),
        },
      };
    }

    return o;
  });
};

export const toStepMessage = (step: WorkflowStep, running?: boolean) => {
  if (step.status?.id === "RUN" || running) {
    return switchEnum(step.action || "", {
      create: () => "Creating work...",
      update: () => "Updating work...",
      ai: () => "Asking the AI overlords...",
      message: () => "Sending messages...",
      condition: () => "Analyzing results...",
      control: () => "Optimizing paths...",
      run_workflow: () => "Running other workflows...",
      wait: () => "Waiting...",
      else: () => "Optimizing work...",
    });
  }

  if (isFinished(step)) {
    return switchEnum(step.action || "", {
      create: () =>
        `Created work at ${useISODate(step.createdAt, formatHuman)}`,
      else: () => "Ran successfully.",
    });
  }

  return switchEnum(step.action || "", {
    else: () => "Not started.",
  });
};

export const toStepAssigned = (
  step: WorkflowStep,
  context: { workflow: Workflow; steps: WorkflowStep[] }
): Maybe<Ref> =>
  switchEnum(step.action || "", {
    create: () => {
      const valueRef = find(step.overrides, (o) =>
        equalsAny(o.field, ["owner", "assigned"])
      );

      if (valueRef?.value.formula) {
        return evalFormula(
          valueRef.value.formula,
          "relation",
          toVariables(context.workflow, context.steps)
        )?.relation;
      }

      return valueRef?.value.relation || valueRef?.value.relations?.[0];
    },

    else: () => step.owner,
  });

// Fix common issues with LLM data
export const healPropertyValue = (valueRef: PropertyValueRef) =>
  // Titles and names are often used interchangeably
  switchEnum(valueRef.field, {
    title: () =>
      when(withoutStar(justOne(valueRef.def?.entity)), not(isTitleable))
        ? { ...valueRef, field: "name" }
        : valueRef,

    name: () =>
      when(withoutStar(justOne(valueRef.def?.entity)), isTitleable)
        ? { ...valueRef, field: "title" }
        : valueRef,

    else: () => undefined,
  }) ||
  // Value is set to a formula that is valid
  when(valueRef.value?.formula, (formula) =>
    isFormula(formula)
      ? { ...valueRef, value: { formula } as PropertyValue }
      : undefined
  ) ||
  // Further healing by property type logics
  switchEnum(valueRef.type, {
    // Drop the relation if it isn't allowed by the definition
    relation: () => {
      const { def } = valueRef;
      const ref =
        valueRef.value.relation || when(valueRef.value.relations, justOne);

      // Nothing to heal
      if (!ref || !def) {
        return valueRef;
      }

      const isAllowed =
        isStar(def?.options?.references) ||
        equalsAny(typeFromId(ref.id), ensureMany(def?.options?.references));

      if (!isAllowed) {
        return {
          ...valueRef,
          value: {
            relation: undefined,
          },
        };
      }
      return valueRef;
    },
    // Drop the relation if it isn't allowed by the definition
    relations: () => {
      const { def } = valueRef;
      const refs =
        valueRef.value.relations || when(valueRef.value.relation, ensureMany);

      // Nothing to heal
      if (!refs?.length || !def) {
        return valueRef;
      }

      const isAllowed =
        isStar(def?.options?.references) ||
        overlaps(
          mapUniq(refs, (r) => typeFromId(r.id)),
          ensureMany(def?.options?.references)
        );

      if (!isAllowed) {
        return {
          ...valueRef,
          value: {
            relations: undefined,
          },
        };
      }
      return valueRef;
    },

    rich_text: () => ({
      ...valueRef,
      value: {
        rich_text:
          // If it's a double nested PropertyValue
          safeAs<PropertyValue>(valueRef.value?.rich_text)?.rich_text ||
          // If it's a PropertyValueRef already
          valueRef.value?.rich_text ||
          // If it's a RichText object, then use as is
          when(safeAs<RichText>(valueRef.value)?.html, (html) => ({ html })) ||
          when(safeAs<RichText>(valueRef.value)?.markdown, (markdown) => ({
            markdown,
          })) ||
          when(safeAs<RichText>(valueRef.value)?.text, (text) => ({ text })) ||
          // If it's just a string, then convert to RichText object
          (isString(valueRef.value)
            ? { text: valueRef.value as string }
            : undefined),
      },
    }),
    else: () => valueRef,
  });

// Fix common issues with LLM EntityTypes
export const healEntityType = (type: string): EntityType =>
  switchEnum<string, EntityType>(type, {
    document: () => "page",
    else: () => asEnum(type, EntityTypeEnum) || "task",
  });

export const aiStepToUpdate = (
  workflow: Workflow,
  step: WorkflowStep | Update<Entity>,
  props: PropertyDef[]
): Update<Entity> => {
  if (isUpdate(step)) {
    return step;
  }

  return {
    id: step.id,
    method: isLocalID(step.id) ? "create" : "update",
    source: step.source,
    changes: [
      ...maybeMap(props, (p) => {
        const val = getPropertyValue(step, p);
        const mutation = healPropertyValue(toMutation(step, p, val?.[p.type]));

        if (isEmptyRef(mutation)) {
          return undefined;
        }

        // Heal every inner overide value
        if (p.field === "overrides") {
          return {
            ...mutation,
            value: {
              json: map(mutation.value.json, healPropertyValue),
            },
          };
        }

        return mutation;
      }),
      asMutation({ field: "status", type: "status" }, { id: "NTS" }),
      asMutation(
        { field: "location", type: "text" },
        toChildLocation(workflow.source.scope, workflow.id)
      ),
      asMutation({ field: "refs.workflow", type: "relations" }, [
        toRef(workflow),
      ]),
    ],
  };
};

export const orderedSteps = (
  steps: Maybe<WorkflowStep[]>,
  aliases?: SafeRecord<string, string>
) => {
  const toId = (id: ID) => persistedID(id, aliases) || id;

  return composel(
    // First order by the position on the workflow board
    (s) => orderBy(s || [], (s) => s.orders?.["default"]),
    // Then use the dependency sort
    (s) => dependencySort(s, (s) => map(s.refs?.blockedBy, (r) => toId(r.id)))
  )(steps);
};

// Workflow input variables that are dynamically generated at runtime
export const toSystemVars = (workflow: Workflow): VariableDef[] => [
  {
    field: "sys_started_at",
    label: "Started At",
    description: "The time the workflow was started",
    type: "date",
    system: true,
    value: { date: !!workflow.template ? undefined : workflow.createdAt },
  },
  {
    field: "sys_created_by",
    label: "Started By",
    description: "The person who started the workflow",
    type: "relation",
    system: true,
    value: { relation: !!workflow.template ? undefined : workflow.createdBy },
    options: { references: ["person"] },
  },
  {
    field: "sys_owner",
    label: "Owner",
    description: "The person who is responsible for the workflow",
    type: "relation",
    system: true,
    value: { relation: !!workflow.template ? undefined : workflow.owner },
    options: { references: ["person"] },
  },
];

export const toWorkflowOwner = (workflow: Workflow) =>
  workflow.owner || workflow.createdBy;

export const toNextSteps = (
  workflow: Workflow,
  steps: WorkflowStep[],
  context: WorkflowRunContext
) => {
  const getStep = maybeLookupById(steps);
  return filter(orderedSteps(steps), (s) => {
    const deps = map(s.refs?.blockedBy, (r) => getStep(r.id));
    return (
      // Hasn't already tried to run the step
      !includes(context.ran, s.id) &&
      // Not the current running step
      s.id !== context.running?.id &&
      // And conditions based on status
      switchEnum(s.status?.id || "", {
        // Hack: Any previous steps that got left in RUN state
        RUN: () => true,

        // Any unblocked steps
        NTS: () => !deps.length || some(deps, (d) => !!d && !isBlocking(s, d)),

        // Or waiting steps that haven't just been run since the last dirty
        WAI: () => true,

        // Otherwise not eligible for running
        else: () => false,
      })
    );
  });
};
