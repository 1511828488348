import Mention, { MentionOptions } from "@tiptap/extension-mention";
import {
  mergeAttributes,
  NodeViewProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
  ReactRenderer,
} from "@tiptap/react";
import { SuggestionProps } from "@tiptap/suggestion";
import { first, isString, last } from "lodash";
import { PluginKey } from "prosemirror-state";
import { FC, forwardRef, useCallback } from "react";
import tippy, { Instance } from "tippy.js";

import { isPerson, Ref, RelationRef, toTitleOrName } from "@api";

import { useGetItemFromAnyStore } from "@state/generic";
import { toDisplayName } from "@state/persons";

import { cx } from "@utils/class-names";
import { withHardHandle } from "@utils/event";
import { Fn } from "@utils/fn";
import { isPersonId, maybeTypeFromId, typeFromId } from "@utils/id";
import { toTractionIds } from "@utils/link";
import { Maybe, when } from "@utils/maybe";
import { useGoTo, usePushTo } from "@utils/navigation";

import { RelationLabel } from "@ui/relation-label";
import { GlobalEntitySelect } from "@ui/select";

import { pasteHandler } from "./embed-extension";
import { clickHandler } from "./link-extension";
import { isFocused as isEditorFocused } from "./utils";

import styles from "./mention-extension.module.css";

const DOM_RECT_FALLBACK: DOMRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
  toJSON() {
    return {};
  },
};

type MentionListProps = SuggestionProps & {
  items: { id: string; label: string }[];
  command: Fn<{ id: string; label: string }, void>;
  scope?: string;
  onClose: Fn<void, void>;
};

export const MentionList = forwardRef(
  ({ command, onClose, scope }: MentionListProps, ref) => {
    const getItem = useGetItemFromAnyStore();
    // TODO: Could pass in first query value in as default search value to select
    const selectItem = useCallback(
      (ref: Maybe<RelationRef>) => {
        if (!ref) {
          return command(undefined);
        }

        const entity = getItem(ref.id);
        command({
          id: ref.id,
          label:
            when(entity, (e) =>
              isPerson(e) ? toDisplayName(e) : toTitleOrName(e)
            ) ||
            ref.name ||
            ref.id,
          type: entity?.source.type || typeFromId(ref.id),
        });
      },
      [command]
    );

    return (
      <div
        onClick={withHardHandle(() => {})}
        onMouseDown={withHardHandle(() => {})}
        onMouseUp={withHardHandle(() => {})}
      >
        <GlobalEntitySelect
          portal={false}
          open={true}
          setOpen={(open) => !open && onClose()}
          value={undefined}
          scope={scope}
          onChange={selectItem}
          type={when(scope, isPersonId) ? "team" : "person"}
          allowed="*"
        >
          <span />
        </GlobalEntitySelect>
      </div>
    );
  }
);

/*
 * Reusable definitions
 */

export type MentionProps = {
  scope?: string;
  onMention?: Fn<Ref, void>;
};

export const GlobalSuggestion = ({ scope, onMention }: MentionProps) =>
  toSuggester({
    Comp: MentionList,
    char: "@",
    key: "mention",
    scope: scope,
    onMention,
  });

/*
 * Abstract away wrapping a reac component for mentioner
 */

interface SuggesterOptions {
  Comp: FC<MentionListProps>;
  char: string;
  key: string;
  scope?: string;
  onMention?: Fn<Ref, void>;
}

export function toSuggester({
  Comp,
  char = "@",
  key = "mention",
  scope,
  onMention,
}: SuggesterOptions): MentionOptions["suggestion"] {
  return {
    char: char,
    pluginKey: new PluginKey(key),
    render: function () {
      let reactRenderer: Maybe<ReactRenderer>;
      let popup: Maybe<Instance>;

      const handleClose = (editor: SuggestionProps["editor"]) => {
        editor.commands.focus(); // Focus the editor to ensure the selection is correct
        // TODO: Remove as I don't think this works...
        editor.commands.setMeta("plugin-focused", false);
        popup?.destroy();
        reactRenderer?.destroy();
      };

      return {
        onStart: (props) => {
          if (!props.clientRect || !when(props.editor, isEditorFocused)) {
            return;
          }

          // TODO: Remove as I don't think this works...
          props.editor.commands.setMeta("plugin-focused", true);

          reactRenderer = new ReactRenderer(Comp, {
            props: {
              ...props,
              scope,
              command: ((val: { id: string; label: string }) => {
                props.command(val);
                onMention?.(val);
              }) as MentionListProps["command"],
              onClose: () => handleClose(props.editor),
            },
            editor: props.editor,
          });

          popup = first(
            tippy("body", {
              getReferenceClientRect: () =>
                props.clientRect?.() || DOM_RECT_FALLBACK,
              appendTo: () => document.body,
              content: reactRenderer.element,
              showOnCreate: true,
              interactive: true,
              trigger: "manual",
              placement: "top-start",
            })
          );
        },

        onUpdate(props) {
          reactRenderer?.updateProps({
            ...props,
            onClose: () => handleClose(props.editor),
            scope,
          });

          if (!props.clientRect) {
            return;
          }

          popup?.setProps({
            getReferenceClientRect: () =>
              props.clientRect?.() || DOM_RECT_FALLBACK,
          });
        },

        onExit(props) {
          if (popup && reactRenderer) {
            handleClose(props.editor);
          }
        },
      };
    },
  };
}

const PersonMention = (props: NodeViewProps) => {
  const goTo = useGoTo();
  return (
    <RelationLabel
      className={cx(styles.mention, styles.inlineRelation)}
      relation={{ id: props.node.attrs.id }}
      fit="content"
      onClick={() => goTo(props.node.attrs.id)}
    />
  );
};

const GenericMention = (props: NodeViewProps) => {
  const pushTo = usePushTo();
  return (
    <RelationLabel
      className={styles.inlineRelation}
      relation={{ id: props.node.attrs.id }}
      fit="content"
      onClick={() => pushTo(props.node.attrs.id)}
    />
  );
};

export const MentionComp = (props: NodeViewProps) => (
  <NodeViewWrapper as="span">
    {props.node?.attrs?.type === "person" ? (
      <PersonMention {...props} />
    ) : (
      <GenericMention {...props} />
    )}
  </NodeViewWrapper>
);

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    mention: {
      setMention: (options: { id: string; label?: string }) => ReturnType;
    };
  }
}

export const Mentioner = Mention.extend({
  name: "mentioner",
  // Override Link & Embed priority
  priority: 2001,
  // Don't allow other link marks to run alongside this
  excludes: "_",
  // Define tag rule
  tag: "[data-mention-id]",

  // Override the default click handler
  addProseMirrorPlugins() {
    return [
      ...(this?.parent?.() || []),
      clickHandler({ editor: this.editor, type: this.type }),
      pasteHandler({
        type: this.type,
        editor: this.editor,
        handle: ({ url }) =>
          when(last(toTractionIds(url)), (id) => {
            this.editor.commands.setMention({ id });
            return true;
          }) ?? false,
      }),
    ];
  },

  addCommands() {
    // Used from other components
    return {
      setMention:
        ({ id, label }) =>
        ({ tr, dispatch, chain }) => {
          const type = maybeTypeFromId(id);
          if (!type) {
            return false;
          }

          const { selection } = tr;
          const node = this.type.create({ type: type, id, label: label });

          if (dispatch) {
            tr.replaceRangeWith(selection.from, selection.to, node);
          }

          // move the cursor after the mention
          chain().selectTextblockEnd().run();

          return true;
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-a": ({ editor }) => {
        const state = editor.view.state;
        const { selection } = state;
        const { $anchor } = selection;

        // Check if the selection is a mention
        const text = selection.$from.nodeBefore?.textContent;
        const currentWord = last(
          text?.slice(0, selection.$anchor.parentOffset)?.split(" ")
        );

        const char = this.options.suggestion.char || "@";

        if (currentWord?.startsWith(char)) {
          editor.commands.setTextSelection({
            from: $anchor.pos - currentWord.length + 1,
            to: $anchor.pos,
          });
          return true;
        }

        return false;
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(MentionComp);
  },

  // Parse out attribute values
  addAttributes() {
    return {
      ...this?.parent?.(),
      id: {
        default: null,
        parseHTML: (element: HTMLElement) =>
          element.getAttribute("data-mention-id"),
        renderHTML: (attributes) => {
          return { "data-mention-id": attributes.id };
        },
      },
      label: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-mention-label"),
        renderHTML: (attributes) => {
          return {
            "data-mention-label": attributes.label,
          };
        },
      },
      type: {
        default: this.options.suggestion.char,
        parseHTML: (element: HTMLElement) => {
          const type = element.getAttribute("data-mention-type");
          // Convert old type to entity type]
          if (type === "@") {
            return "person";
          }
          return (
            type ||
            when(element.getAttribute("data-mention-id"), maybeTypeFromId)
          );
        },
        renderHTML: (attributes) => {
          return {
            "data-mention-type": attributes.type,
          };
        },
      },
    };
  },
  // Rules for extracting from html
  parseHTML() {
    return [
      {
        tag: "[data-mention-id]",
        priority: 2000,
        excludes: "_",
        getAttrs: (element) => {
          if (isString(element) || !element.getAttribute("data-mention-id")) {
            return false;
          }

          // Above attribute parsing extracts all values
          return {};
        },
      },
    ];
  },
  renderHTML(props) {
    const { node, HTMLAttributes } = props;

    return [
      "a",
      mergeAttributes(
        { class: "mention" },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),

      when(
        node.attrs.label,
        (lbl) => `${this?.options?.suggestion?.char}${lbl}`
      ) || "",
    ];
  },
});
