import { filter, includes, map } from "lodash";
import { useMemo } from "react";

import { Entity, hasBody, hasCode, isTitleable, toTitleOrName } from "@api";

import { useLazyProperties } from "@state/properties";
import { useEntityLabels } from "@state/settings";

import { justOne } from "@utils/array";
import { cx } from "@utils/class-names";
import { typeFromId } from "@utils/id";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { isEmptyRef, toPropertyValueRef } from "@utils/property-refs";
import { isEmpty } from "@utils/rich-text";
import { withoutStar } from "@utils/wildcards";

import { CodeLabel } from "@ui/code-label";
import { Divider } from "@ui/divider";
import { EntityProperties } from "@ui/entity-properties";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { GoToButton } from "@ui/go-to-button";
import { useMutate } from "@ui/mutate";
import { DocumentEditor, ReadonlyDocument } from "@ui/rich-text";
import { ShowMoreOverflow } from "@ui/show-more-overflow";
import { SectionLabel, TextLarge } from "@ui/text";
import { ViewRelationsMenu } from "@ui/view-results-section";

import { EditableHeading } from "./editable-heading";

import styles from "./entity-preview.module.css";

interface EntityPreviewProps<T extends Entity> {
  entity: T;
  editable?: boolean;
  showBody?: boolean;
  propBlacklist?: string[];
  hideParents?: boolean;
  onOpen?: (e: T) => void;
  className?: string;
}

export const EntityPreview = <T extends Entity>({
  entity,
  editable = true,
  showBody = false,
  propBlacklist,
  hideParents,
  onOpen,
  className,
}: EntityPreviewProps<T>) => {
  const mutate = useMutate();
  const toEntityLabel = useEntityLabels(entity.source.scope);
  const props = useLazyProperties(entity.source);
  const parentRelations = useMemo(
    () =>
      filter(
        props,
        (p) => p.type === "relations" && p.options?.hierarchy === "parent"
      ),
    [props]
  );
  const childRelations = useMemo(
    () =>
      filter(
        props,
        (p) =>
          !includes(propBlacklist, p.field) &&
          p.type === "relations" &&
          p.options?.hierarchy === "child"
      ),
    [props]
  );
  const finalBlacklist = useMemo(
    () => [
      ...(propBlacklist || []),
      ...(hideParents ? map(parentRelations, "field") : []),
    ],
    [propBlacklist?.length, parentRelations]
  );

  return (
    <VStack fit="container" className={cx(styles.preview, className)} gap={16}>
      <SpaceBetween align="flex-start">
        <VStack fit="container" gap={0}>
          <HStack>
            <SectionLabel primary>
              {toEntityLabel(entity.source.type || typeFromId(entity.id))}
            </SectionLabel>
            {hasCode(entity) && <CodeLabel code={entity.code} />}
          </HStack>
          {editable ? (
            <EditableHeading
              key={`${entity.id}-name`}
              size="h3"
              onChange={(t) =>
                mutate(
                  asUpdate(
                    entity,
                    asMutation(
                      {
                        field: isTitleable(entity.source.type)
                          ? "title"
                          : "name",
                        type: "text",
                      },
                      t
                    )
                  )
                )
              }
              text={toTitleOrName(entity)}
            />
          ) : (
            <TextLarge bold>{toTitleOrName(entity)}</TextLarge>
          )}
        </VStack>
        <GoToButton item={entity} mode="push" text="Open" />
      </SpaceBetween>

      <EntityProperties
        entityId={entity.id}
        hideSections={true}
        hideEmptyProps={true}
        blacklist={finalBlacklist}
        className={{ propsList: styles.props }}
        editable={editable}
      />

      {map(childRelations, (p) => {
        const value = toPropertyValueRef(entity, p);
        const reffedTyped = justOne(withoutStar(p.options?.references));

        if (isEmptyRef(value) || !reffedTyped) {
          return <></>;
        }

        return (
          <ViewRelationsMenu
            key={p.entity?.[0] + p.field}
            childType={reffedTyped}
            parentId={entity.id}
          />
        );
      })}

      {hasBody(entity) && !isEmpty(entity.body) && (
        <>
          <Divider />
          {onOpen ? (
            <ShowMoreOverflow
              showAll={false}
              onShowAll={() => onOpen?.(entity)}
            >
              <ReadonlyDocument key={entity?.id} content={entity.body} />
            </ShowMoreOverflow>
          ) : (
            // Controlled internally when no onShowAll
            <ShowMoreOverflow>
              {editable ? (
                <DocumentEditor
                  key={entity.id}
                  content={entity.body}
                  newLineSpace="small"
                  onChanged={(rt) =>
                    mutate(
                      asUpdate(
                        entity,
                        asMutation({ field: "body", type: "rich_text" }, rt)
                      )
                    )
                  }
                />
              ) : (
                <ReadonlyDocument key={entity?.id} content={entity.body} />
              )}
            </ShowMoreOverflow>
          )}
        </>
      )}
    </VStack>
  );
};
