import { atomFamily } from "recoil";

import { ID, Workflow, WorkflowStep } from "@api";

import { indexedDBStorageForEntityStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { EpochDate } from "@utils/epoch-date";
import { Maybe } from "@utils/maybe";

export type WorkflowMode = "foreground" | "background";

export type WorkflowStoreState = StoreState<Workflow>;

export const WorkspaceWorkflowStoreAtom = atomFamily<WorkflowStoreState, ID>({
  key: "WorkspaceWorkflowStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "workflow" },
  effects: (wid) => [indexedDBStorageForEntityStore(wid, "workflow")],
});

export const WorkflowStoreAtom = WorkspaceWrappedAtom(
  "WorkflowStoreAtom",
  WorkspaceWorkflowStoreAtom
);

export type WorkflowStepStoreState = StoreState<WorkflowStep>;

export const WorkspaceWorkflowStepStoreAtom = atomFamily<
  WorkflowStepStoreState,
  ID
>({
  key: "WorkspaceWorkflowStepStoreAtom",
  default: { updatedAt: undefined, lookup: {}, type: "workflow_step" },
  effects: (wid) => [indexedDBStorageForEntityStore(wid, "workflow_step")],
});

export const WorkflowStepStoreAtom = WorkspaceWrappedAtom(
  "WorkflowStepStoreAtom",
  WorkspaceWorkflowStepStoreAtom
);

export type WorkflowRunContext = {
  workflow: Workflow;
  queue: WorkflowStep[];
  ran: ID[];
  mode?: "foreground" | "background";
  errored?: ID[];
  more?: boolean;
  running: Maybe<WorkflowStep>;
  startedAt: Maybe<EpochDate>;
  finishedAt?: Maybe<EpochDate>;
};

export const WorkflowRunContextAtom = atomFamily<Maybe<WorkflowRunContext>, ID>(
  {
    key: "WorkflowRunContextAtom",
    default: undefined,
  }
);
