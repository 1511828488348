import { addMinutes, roundToNearestMinutes, startOfDay } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  Agenda,
  Entity,
  EntityType,
  ID,
  Meeting,
  PropertyMutation,
  Ref,
  Schedule,
  Update,
} from "@api";

import {
  useCreateFromObject,
  useCreateFromTemplate,
  useEntityState,
  useLazyEntity,
  useLocalChanges,
  useQueueUpdates,
} from "@state/generic";
import { isInProgress } from "@state/meetings";
import { useMe } from "@state/persons";
import { ScheduleStoreAtom, useTempSchedule } from "@state/schedule";
import { useActiveWorkspaceId } from "@state/workspace";

import { OneOrMany } from "@utils/array";
import { formatTime } from "@utils/date";
import {
  convertToCalDate,
  convertToPointDate,
  ensureISODate,
  fromPointDate,
  toCalDate,
  toDirtyDate,
  toPointDate,
  useISODate,
} from "@utils/date-fp";
import { composel, Fn } from "@utils/fn";
import { useSlowMemo } from "@utils/hooks";
import { isPersonId, newLocalHumanId } from "@utils/id";
import { Maybe, maybeMap, required, safeAs, when } from "@utils/maybe";
import { now } from "@utils/now";
import { merge } from "@utils/object";
import { asMutation, asTempUpdate, asUpdate } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { uniqRefs } from "@utils/relation-ref";
import { append } from "@utils/scope";
import { keepTime, keepTimeDirty, toTime, withTime } from "@utils/time";

import { usePageId } from "@ui/app-page";
import { Banner } from "@ui/banner";
import { Button } from "@ui/button";
import { CollapsibleSection } from "@ui/collapsible-section";
import { Container } from "@ui/container";
import { DateInputPicker } from "@ui/date-picker";
import { DialogSplit } from "@ui/dialog-split";
import { EducationTip } from "@ui/education-tip";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { ClockHistory, EmojiIcon, PaintTool } from "@ui/icon";
import { Field, TextInput } from "@ui/input";
import { showError } from "@ui/notifications";
import { DocumentEditor } from "@ui/rich-text";
import { LocationSelect, PersonMultiSelect, TemplateSelect } from "@ui/select";
import { Text, TextSmall } from "@ui/text";
import { DurationPicker, TimePicker } from "@ui/time-picker";

import { ScheduleRepeatOptions } from "../schedule";

import styles from "./styles.module.css";

interface Props {
  defaults?: Partial<Omit<Meeting, "id">>;
  onSaved?: Fn<Ref, void>;
  onCancel?: Fn<void, void>;
}

export const MeetingCreateDialog = ({ onCancel, onSaved, defaults }: Props) => {
  const wID = useActiveWorkspaceId();
  const me = useMe();
  const [smartName, setSmartName] = useState(true);
  const showName = !smartName;
  const [suggestedName, setSuggestedName] = useState("");
  const [creating, setCreating] = useState(false);
  const scope = useMemo(
    () => defaults?.source?.scope || defaults?.location || wID,
    [defaults?.source?.scope]
  );

  const [repeat, setRepeat] = useState(false);
  const [showingTemplate, setShowingTemplate] = useState(false);
  const [fromTemplate, setFromTemplate] = useEntityState<"meeting">();
  const [scheduleId, setScheduleId] = useState<string>();
  const { save: saveSchedule, rollback: rollbackSchedule } = useLocalChanges(
    scheduleId || "",
    ScheduleStoreAtom
  );
  const schedule = useLazyEntity(scheduleId);

  const [meeting, _setMeeting] = useState<Partial<Meeting>>(() => {
    const duration = 60;
    const time = roundToNearestMinutes(now(), {
      nearestTo: 15,
      roundingMethod: "ceil",
    });
    const start = keepTimeDirty(
      when(ensureISODate(defaults?.start), fromPointDate) || now(),
      time
    );

    return {
      ...defaults,
      owner: toRef(me),
      refs: { ...defaults?.refs, people: [toRef(me)] },
      start: toPointDate(start),
      duration,
      end: toPointDate(addMinutes(start, duration)),
      id: newLocalHumanId("meeting"),
      source: { type: "meeting", scope },
    };
  });

  const [agenda, _setAgenda] = useState<Partial<Agenda>>(() => {
    return {
      id: newLocalHumanId("agenda"),
      body: {
        html: `<ul data-type="taskList"><li data-checked="false" data-type="taskItem"><label><input type="checkbox"><span></span></label><div><p></p></div></li></ul>`,
      },
      order: 0,
      refs: { meeting: [{ id: required(meeting.id, "Expected meeting id.") }] },
    };
  });

  const create = useCreateFromObject("meeting", scope);
  const createAgenda = useCreateFromObject("agenda", append(scope, meeting.id));
  const createFromTemplate = useCreateFromTemplate(
    { type: "meeting", scope: meeting?.location || scope },
    (ref) => {
      onSaved?.(ref);
      setCreating(false);
    }
  );

  const setMeeting = useCallback(
    (changes: Partial<Meeting>) =>
      _setMeeting((r) =>
        changes?.start
          ? merge(r, {
              ...changes,
              end: toPointDate(
                addMinutes(
                  toDirtyDate(changes.start),
                  changes.duration || meeting.duration || 0
                )
              ),
            })
          : merge(r, changes)
      ),
    [_setMeeting, meeting.duration]
  );
  const setAgenda = useCallback(
    (changes: Partial<Agenda>) => _setAgenda((r) => merge(r, changes)),
    [_setAgenda]
  );

  const setDuration = useCallback(
    (d: number) => {
      setMeeting({
        start: meeting?.start || toPointDate(now()),
        duration: d,
        end: when(meeting?.start, (start) =>
          useISODate(start, (date) => addMinutes(date, d))
        ),
      });
    },
    [meeting?.start]
  );

  const onCreate = useCallback(() => {
    if (!create || !createAgenda) {
      return showError("Not ready.");
    }

    if (!meeting.start || !meeting.refs?.people?.length) {
      showError("Fill in all required fields.");
      return;
    }

    if (repeat && !schedule) {
      showError("Cant find schedule.");
      return;
    }

    setCreating(true);

    const start = meeting.start;

    const merged: Partial<Meeting> = {
      ...meeting,
      name: smartName && suggestedName ? suggestedName : meeting.name,
      status: { id: "UPC" },
      // Clear start from meeting when creating as template for schedule.
      start: !!(repeat && schedule) ? undefined : start,
      refs: {
        ...meeting.refs,

        // If there is a template, then merge the people in here...
        people: uniqRefs([
          ...(meeting.refs?.people || []),
          ...(safeAs<Meeting>(fromTemplate)?.refs?.people || []),
        ]),
      },
    };

    // Creating from template
    if (fromTemplate) {
      createFromTemplate.create(fromTemplate, {
        overrides: { [fromTemplate?.id]: merged },
      });

      return;
    }

    // If not from template

    if (merged.refs && agenda?.id) {
      merged.refs.agenda = [{ id: agenda.id }];
    }

    const [saved] = create([merged]);

    if (agenda) {
      createAgenda([agenda]);
    }

    // Creating with a schedule (not possible with fromTemplate)
    if (repeat && schedule) {
      saveSchedule([
        asUpdate(schedule, [
          asMutation(
            { field: "useTemplate", type: "relation" },
            { id: saved.id }
          ),

          // Set live immediately
          asMutation({ field: "status", type: "status" }, { id: "ACT" }),

          asMutation(
            { field: "from", type: "date" },
            when(start, (s) =>
              useISODate(convertToCalDate(s, "local"), startOfDay)
            )
          ),
          asMutation(
            { field: "name", type: "text" },
            saved.name || merged.name || suggestedName || ""
          ),
        ]),
      ]);
    }

    if (saved) {
      onSaved?.(saved);
    } else {
      onCancel?.();
    }
    setCreating(false);
  }, [
    create,
    meeting,
    agenda,
    suggestedName,
    fromTemplate,
    smartName,
    onSaved,
    repeat,
    schedule,
    saveSchedule,
  ]);

  const handleDismiss = useCallback(() => {
    rollbackSchedule?.();
    onCancel?.();
  }, [rollbackSchedule]);

  const slowName = useSlowMemo(
    () => meeting.name,
    { delay: 1000, maxWait: 4000, leading: false, trailing: true },
    [meeting?.name]
  );

  return (
    <DialogSplit
      title={"New meeting"}
      onDismiss={handleDismiss}
      className={{ modal: styles.createModal, left: styles.splitLeft }}
      side={
        <SpaceBetween direction="vertical" align="flex-start">
          <Text subtle>Meet with purpose and clarity.</Text>
          <EducationTip relevantTo={["meeting"]} />
        </SpaceBetween>
      }
      actions={
        <SpaceBetween>
          <span />
          <HStack>
            <Button onClick={() => onCancel?.()}>Cancel</Button>
            <Button variant="primary" onClick={onCreate} loading={creating}>
              Create meeting
            </Button>
          </HStack>
        </SpaceBetween>
      }
    >
      <SpaceBetween fit="container">
        <span />
        <LocationSelect
          size="small"
          location={meeting.location}
          allowed={["team"]}
          onChange={(l) => setMeeting({ location: l })}
          showOpen={false}
          variant="full"
          showCaret={true}
        />
      </SpaceBetween>
      <FillSpace direction="vertical">
        <Container gap={40} stack="vertical" fit="container">
          <VStack gap={20} fit="container">
            <Field label="Meeting Name">
              <TextInput
                value={meeting.name || ""}
                onChange={(t) => setMeeting({ name: t })}
                placeholder={suggestedName || "Give this meeting a name..."}
                updateOn="change"
                autoFocus={true}
              />
            </Field>

            {/* <Field label="Purpose">
              <VStack gap={2}>
                <TextBox
                  key="purpose"
                  text={when(meeting.purpose, (html) => ({ html }))}
                  controls={false}
                  size="one-line"
                  updateOn="change"
                  placeholder="Share the purpose of this meeting..."
                  onChanged={(t) =>
                    setMeeting({ purpose: toPlainText(t.html || "") })
                  }
                />
              </VStack>
            </Field> */}

            <Field label="People">
              <PersonMultiSelect
                className={{ trigger: styles.control }}
                value={meeting.refs?.people}
                onChange={(vs) =>
                  setMeeting({ refs: { people: maybeMap(vs, toRef) } })
                }
                placeholder="Select people..."
              />
            </Field>

            {(meeting.refs?.people?.length || 0) > 1 &&
              meeting.location &&
              isPersonId(meeting.location) && (
                <Banner
                  icon={<EmojiIcon emoji="⚠️" />}
                  text="This is only visible to you. Change above location to a team."
                  variant="rounded"
                  align="left"
                  fit="container"
                  color="yellow"
                />
              )}

            <SpaceBetween fit="container" gap={12}>
              <Field label="Day">
                {/* TODO: Convert to ISODate */}
                <DateInputPicker
                  date={when(meeting.start, fromPointDate)}
                  onChanged={(v) => {
                    // Convert to ISO Date since date picker is returning a PointDate but using local 00:00 to reference the day...
                    return (
                      v &&
                      setMeeting({
                        start: meeting.start
                          ? keepTime(
                              convertToPointDate(toCalDate(v, "local"), "utc"),
                              meeting.start
                            )
                          : toPointDate(v),
                      })
                    );
                  }}
                  placeholder="Pick a day"
                />
              </Field>
              <Field label="Time">
                <TimePicker
                  time={
                    meeting.start
                      ? toTime(fromPointDate(meeting.start))
                      : undefined
                  }
                  onChange={(time) =>
                    time &&
                    setMeeting({
                      start: useISODate(
                        meeting.start,
                        (s) => s && withTime(s, time)
                      ),
                    })
                  }
                >
                  <Button
                    className={styles.control}
                    subtle
                    size="small"
                    fit="container"
                  >
                    <Text subtle={!meeting.start}>
                      {when(
                        meeting.start,
                        composel(fromPointDate, formatTime)
                      ) || "Pick time"}
                    </Text>
                  </Button>
                </TimePicker>
              </Field>
              <Field label="Duration">
                <DurationPicker
                  mins={meeting.duration || 0}
                  onChange={(d) => d && setDuration(d)}
                >
                  <Button
                    className={styles.control}
                    subtle
                    size="small"
                    fit="container"
                  >
                    {meeting.duration} mins
                  </Button>
                </DurationPicker>
              </Field>
            </SpaceBetween>

            {showingTemplate && (
              <Field label="Using template" fit="container">
                <TemplateSelect
                  scope={meeting.location}
                  type="meeting"
                  allowed={["meeting"]}
                  allowNew={false}
                  value={fromTemplate}
                  onChange={setFromTemplate}
                  closeOnSelect={true}
                  placeholder="No template..."
                  className={{ trigger: styles.control }}
                />
              </Field>
            )}

            {!repeat && !showingTemplate && (
              <HStack gap={4}>
                <Button
                  size="small"
                  subtle
                  icon={ClockHistory}
                  onClick={() => setRepeat(true)}
                >
                  <Text subtle>Repeat</Text>
                </Button>
                <Button
                  size="small"
                  subtle
                  icon={PaintTool}
                  onClick={() => setShowingTemplate(true)}
                >
                  <Text subtle>Use Template</Text>
                </Button>
              </HStack>
            )}
          </VStack>

          {repeat && (
            <>
              <MeetingScheduleConfig
                meeting={meeting as Meeting}
                agenda={agenda}
                setAgenda={setAgenda}
                onSchedule={(id) => {
                  if (id) {
                    setScheduleId(id);
                    setMeeting({ template: "root" });
                    setAgenda({ template: "nested" });
                  } else {
                    setScheduleId(undefined);
                    setMeeting({ template: undefined });
                    setAgenda({ template: undefined });
                    rollbackSchedule?.();
                    setRepeat(false);
                  }
                }}
              />
            </>
          )}
        </Container>
      </FillSpace>
    </DialogSplit>
  );
};

const MeetingScheduleConfig = ({
  meeting,
  agenda,
  setAgenda,
  onSchedule,
}: {
  meeting: Meeting;
  agenda?: Partial<Agenda>;
  setAgenda?: Fn<Partial<Agenda>, void>;
  onSchedule: Fn<Maybe<ID>, void>;
}) => {
  const pageId = usePageId();
  const queue = useQueueUpdates(pageId);
  const scheduleDefaults = useMemo(
    () => ({
      from: meeting.start,
      location: meeting.location,
      entity: "meeting" as EntityType,
    }),
    [meeting.start, meeting.location, meeting.source.scope]
  );
  const schedule = useTempSchedule(scheduleDefaults, pageId);
  const handleChange = useCallback(
    (changes: OneOrMany<PropertyMutation<Schedule>>) =>
      schedule && queue(asTempUpdate(schedule, changes) as Update<Entity>),
    [schedule]
  );

  useEffect(() => {
    if (schedule?.id) {
      onSchedule(schedule?.id);
    }
  }, [!!schedule?.id]);

  if (!schedule) {
    return <></>;
  }

  return (
    <>
      <CollapsibleSection
        forceOpen={true}
        title="Repeat"
        labelSize="medium"
        actions={
          <Button size="small" subtle onClick={() => onSchedule(undefined)}>
            <Text subtle>Revert</Text>
          </Button>
        }
      >
        <ScheduleRepeatOptions
          mutate={handleChange}
          schedule={schedule}
          showStart={false}
        />
      </CollapsibleSection>

      <CollapsibleSection forceOpen={true} title="Agenda" labelSize="medium">
        <DocumentEditor
          key={schedule.id}
          newLineSpace="small"
          color="default"
          content={agenda?.body}
          placeholder="Set the agenda for recurring meeting..."
          onChanged={(body) => setAgenda?.({ body })}
        />
        <TextSmall subtle>This can be edited later from templates.</TextSmall>
      </CollapsibleSection>
    </>
  );
};
