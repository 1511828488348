import { ReactNode, useMemo, useRef } from "react";
import React from "react";

import { Entity, hasColor } from "@api";

import { cx } from "@utils/class-names";
import { toColorVar } from "@utils/color";
import { isValidDoubleClick, respectInputs } from "@utils/event";
import { isSelected, useSelectable } from "@utils/selectable";

import { DropHighlight } from "@ui/drop-highlight";
import { ListCardOpts, ListItemOpts } from "@ui/engine";
import { useItemDragDrop } from "@ui/entity-drag-drop";
import { ListCard } from "@ui/list-card";
import { ListItem } from "@ui/list-item";

import styles from "./selectable-items.module.css";

export const SelectableListItem = <T extends Entity>({
  item,
  className,
  selection,
  selectable = !!selection,
  group,
  indent,
  parents,
  onReorder,
  onOpen,
  onSelected,
  children,
}: ListItemOpts<T> & {
  children: ReactNode;
}) => {
  const ref = useRef<HTMLLIElement>(null);
  const selectableProps = useSelectable(item.id);
  const { opacity, dropping } = useItemDragDrop({
    item,
    selection,
    group,
    parents,
    onReorder: onReorder || (() => {}),
    ref,
  });
  const selected = useMemo(
    () => !!selection && isSelected(selection, item.id),
    [selection, item.id]
  );
  const css = useMemo(() => ({ opacity }), [opacity]);

  return (
    <ListItem
      key={item.id}
      ref={ref}
      selectable={selectable}
      {...(selectable ? selectableProps : {})}
      selected={selected}
      style={css}
      onClick={respectInputs(() =>
        selectable ? onSelected?.(item) : onOpen?.(item)
      )}
      onDoubleClick={respectInputs(
        (e) => e && isValidDoubleClick(e) && onOpen?.(item)
      )}
      className={className}
    >
      {dropping && <DropHighlight />}
      {children}
    </ListItem>
  );
};

export const SelectableListCard = <T extends Entity>({
  item,
  className,
  selection,
  group,
  parents,
  onReorder,
  onOpen,
  children,
  style,
}: ListCardOpts<T> & {
  children: ReactNode;
}) => {
  const ref = useRef<HTMLLIElement>(null);
  const selectableProps = useSelectable(item.id);
  const { opacity, dropping } = useItemDragDrop({
    item,
    selection,
    group,
    parents,
    onReorder: onReorder || (() => {}),
    ref,
  });
  const selected = useMemo(
    () => !!selection && isSelected(selection, item.id),
    [selection, item.id]
  );
  const css = useMemo(
    () => ({
      ...style,
      opacity,
      background: selected
        ? "var(--color-subtle-primary-active)"
        : item && hasColor(item) && item.color
        ? toColorVar(item.color)
        : "var(--color-background)",
    }),
    [item, selected, opacity]
  );

  return (
    <ListCard
      ref={ref}
      selectable={!!selection}
      {...selectableProps}
      selected={selected}
      style={css}
      onClick={
        onOpen ? respectInputs(() => !selection && onOpen?.(item)) : undefined
      }
      onDoubleClick={respectInputs(
        (e) => e && isValidDoubleClick(e) && onOpen?.(item)
      )}
      className={className}
    >
      {dropping && <DropHighlight offset={-5} />}
      {children}
    </ListCard>
  );
};

export const NoSelectable = ({ children }: { children: ReactNode }) => (
  <div data-selectable-ignore-drags="true">{children}</div>
);

export const SelectableTableRow = <T extends Entity>({
  item,
  className,
  selection,
  group,
  parents,
  style,
  onReorder,
  onOpen,
  children,
}: ListItemOpts<T> & {
  style?: React.CSSProperties;
  children: ReactNode;
}) => {
  const ref = useRef<HTMLTableRowElement>(null);
  const selectableProps = useSelectable(item.id);
  const { opacity, dropping } = useItemDragDrop({
    item,
    selection,
    group,
    parents,
    onReorder: onReorder || (() => {}),
    ref,
  });
  const selected = useMemo(
    () => !!selection && isSelected(selection, item.id),
    [selection, item.id]
  );
  const css = useMemo(() => ({ ...style, opacity }), [opacity, style]);

  const classes = useMemo(
    () => cx(className, selected && styles.selected, styles.row),
    [className, selected]
  );

  return (
    <tr
      ref={ref}
      {...(!!selection ? selectableProps : {})}
      className={classes}
      style={css}
      onClick={respectInputs(() => !selection && onOpen?.(item))}
      onDoubleClick={respectInputs(
        (e) => e && isValidDoubleClick(e) && onOpen?.(item)
      )}
    >
      {dropping && <DropHighlight />}
      {children}
    </tr>
  );
};
